/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  RouteProps as ReactDomRouteProps,
  Route as ReactDomRoute,
  Redirect,
} from 'react-router-dom';
import PageError from '../components/PageError';
import { useAuth } from '../hooks/auth';
// import Pay from '../pages/Pay';

// import { Container } from './styles';

interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  onlyPaid?: boolean;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  onlyPaid = false,
  component: Component,
  ...rest
}: RouteProps) => {
  const { user, info } = useAuth();

  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => {
        // if (user && user.status === 'pay') {
        //   return <Pay />;
        // }

        if (
          info &&
          info.ALLOW_PUBLIC_REGISTER &&
          onlyPaid &&
          user.status === 'pending'
        ) {
          return (
            <PageError
              message="Processando pagamento. Aguarde"
              refreshTime={10000}
            />
          );
        }

        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
