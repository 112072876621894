import React from 'react';

import { Container } from './styles';
import ResponsiveImage from '../../../../components/ResponsiveImage';

interface Props {
  data: any;
  onClick(): void;
}

const Contact: React.FC<Props> = ({ data, onClick }: Props) => {
  return (
    <Container onClick={onClick} unread={data.last_message_unread}>
      <div>
        <ResponsiveImage
          src={data.avatar}
          proportion="1:1"
          defaultType="user"
        />
      </div>
      <div>
        <h4>{data.name}</h4>
        <h5>{data?.company?.name}</h5>
        <h5>{data?.company_position || '-'}</h5>
        <p>{data?.last_message}</p>
      </div>
      <div>
        <span className={data.online ? 'online' : 'offline'} />
      </div>
    </Container>
  );
};

export default Contact;
