import styled from 'styled-components';

export const Container = styled.div``;

export const Attachments = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  h2 {
    color: #a19e9e;
    font-size: 14px;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
`;

export const Informations = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > div:first-child {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      font-family: 'sofia_proregular', serif;
      font-size: 16px;
      color: #a8a5a5;
    }

    & > div:last-child {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  a {
    color: #17e29a;
    font-size: 14px;
    font-family: 'sofia_proregular', serif;
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    max-width: 190px;
    border: 2px solid #17e29a;
    border-radius: 8px;
    text-decoration: none;
    svg {
      margin-right: 10px;
    }

    &:hover {
      color: #fff;
      background-color: #17e29a;
      svg {
        fill: #fff;
      }
    }
  }
`;

export const Title = styled.h1`
  display: block;
  font-size: 32px;
  text-align: center;
  color: #838180;
  text-transform: uppercase;
  font-family: sofia_proregular, serif;
  padding: 20px 20px 30px 20px;
`;

export const VideoContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const YouTubeBox = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoBox = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const LinkZoom = styled.a`
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #0964d1;
  color: #fff;
  display: block;
  /* max-width: 300px; */
  text-align: center;
  margin: 0 auto;
`;
