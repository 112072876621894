import React, { useEffect } from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';

interface Props {
  message: string;
  refreshTime?: number;
}

const PageError: React.FC<Props> = ({ message, refreshTime }: Props) => {
  useEffect(() => {
    if (!refreshTime) {
      return () => {};
    }
    console.log('start timeout');
    const t = setTimeout(() => {
      console.log('refreshing');
      document.location.reload();
    }, refreshTime);

    return () => {
      clearTimeout(t);
    };
  }, [refreshTime]);

  return (
    <Container>
      <FiAlertCircle color="#CCC" size={80} />
      <p>{message}</p>
    </Container>
  );
};

export default PageError;
