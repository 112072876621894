import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalView = styled.div`
  padding: 20px;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

export const ModalHeader = styled.div`
  padding: 10px 20px;
  background-color: #fff;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;

  & > div {
    flex: 1;
  }

  & > div.leftColumn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & > div.rightColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: 18px;
  }

  button {
    background: inherit;
    border: 0;
  }
`;

export const ModalBody = styled.div`
  padding: 20px;
  background-color: #fff;
  min-width: 700px;
  overflow-x: scroll;
  padding-top: 0;
`;
