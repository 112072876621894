import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;

  h1 {
    color: #838180;
    font-size: 40px;
    font-family: 'sofia_proregular', serif;
    border-bottom: 2px solid #18e29a;
    padding-bottom: 20px;
  }

  form > div {
    padding: 10px 0;
  }

  form {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 488px;
    }

    & > div > span {
      display: block;
      color: #b2afae;
      font-size: 16px;
      font-family: 'sofia_probold', serif;
    }

    & > div p.error-message {
      font-family: 'sofia_proregular', serif;
      color: #f05d5e;
      font-size: 14px;
    }

    & > div > input {
      display: block;
      padding: 10px;
      font-size: 16px;
      font-family: 'sofia_proregular', serif;
      color: #666;
      border: 1px solid #b2afae;
      border-radius: 6px;
      width: 100%;

      &:read-only {
        background-color: #e4e0df;
      }
    }

    & > div > select {
      display: block;
      padding: 10px;
      font-size: 16px;
      font-family: 'sofia_proregular', serif;
      color: #666;
      border: 1px solid #b2afae;
      border-radius: 6px;
      width: 100%;

      &:read-only {
        background-color: #e4e0df;
      }
    }

    & > div > textarea {
      display: block;
      padding: 10px;
      font-size: 16px;
      font-family: 'sofia_proregular', serif;
      color: #666;
      border: 1px solid #b2afae;
      border-radius: 6px;
      width: 100%;
      height: 100px;

      &:read-only {
        background-color: #e4e0df;
      }
    }
  }
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  color: #17e29a;
  background: #e1f7ef;
  border: 1px solid #e1f7ef;
  border-radius: 6px;

  font-family: 'sofia_proregular', serif;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
`;

export const ButtonFile = styled.input`
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  color: #17e29a;
  background: #e1f7ef;
  border: 1px solid #e1f7ef;
  border-radius: 6px;

  font-family: 'sofia_proregular', serif;
  transition: opacity 0.3s;

  &:after {
    content: 'Alterar Foto de Perfil';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e1f7ef;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #17e29a;
    font-family: 'sofia_proregular', serif;
  }

  &:hover {
    opacity: 0.7;
  }
`;
