import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { initialValues } from './form-data';

// import { Container } from './styles';
import {
  TextField,
  CurrencyField,
  SelectField,
  BooleanField,
  SubmitButton,
  SimpleButton,
  SubmitError,
} from '../../../../components/admin-elements/Form';
import api, { ApiError, apiError } from '../../../../services/api';
import PageLoading from '../../../../components/PageLoading';
import PageError from '../../../../components/PageError';

interface Props {
  id: string | undefined;
  resource: string;
  onCancel?: () => void;
  onUpdate?: (data: any) => void;
  onCreate?: (data: any) => void;
}

const PlanEdit: React.FC<Props> = ({
  id,
  resource,
  onCancel,
  onUpdate,
  onCreate,
}: Props) => {
  const item = useQuery<any, ApiError, any, any>(
    `${resource}/${id}`,
    async ({ queryKey }) => {
      const { data } = await api.get(`${queryKey}`);

      return data;
    },
    {
      enabled: id !== 'new',
      cacheTime: 0,
    },
  );

  const create = useMutation<any, any, any>(
    async (body: any) => {
      await new Promise(r => setTimeout(r, 1000));
      const { data } = await api.post(`${resource}`, body);
      return data;
    },
    {
      onSuccess: onCreate,
    },
  );

  const update = useMutation<any, any, any>(
    async (body: any) => {
      await new Promise(r => setTimeout(r, 1000));

      const { data } = await api.patch(`${resource}/${id}`, body);

      return data;
    },
    {
      onSuccess: onUpdate,
    },
  );

  const onSubmit = useMemo(() => {
    return id === 'new' ? create : update;
  }, [create, id, update]);

  if (item.isError) {
    return <PageError message={apiError(item.error)} />;
  }

  if (item.isLoading) {
    return <PageLoading />;
  }

  return (
    <div>
      <Formik
        initialValues={{
          ...initialValues,
          ...item?.data,
        }}
        onSubmit={(data, helpers) => {
          onSubmit.mutate(data, {
            onSettled: () => helpers.setSubmitting(false),
          });
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name="identifier"
              label="Identificador Único (ex: plano-mensal)"
              readOnly={id !== 'new'}
            />

            <TextField name="name" label="Nome:" />

            <TextField name="description" label="Descrição:" />

            <SelectField name="interval" label="Tipo de intervalo:">
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
            </SelectField>

            <SelectField name="interval_type" label="Tipo de intervalo:">
              <option value="months">Meses</option>
              <option value="weeks">Semanas</option>
            </SelectField>

            <CurrencyField name="value_cents" label="Preço (em centavos)" />

            <BooleanField name="enabled" label="Ativo:" />

            {create.isError && <SubmitError message={apiError(create.error)} />}

            {update.isError && <SubmitError message={apiError(update.error)} />}

            <SubmitButton label="Salvar" />

            <SimpleButton label="Cancelar" onClick={onCancel} />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PlanEdit;
