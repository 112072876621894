import React, { ReactNode } from 'react';

import { Container, Window, Body } from './styles';

interface Props {
  children: ReactNode;
}

const Modal: React.FC<Props> = ({ children }: Props) => {
  return (
    <Container>
      <Body>
        <Window>{children}</Window>
      </Body>
    </Container>
  );
};

export default Modal;
