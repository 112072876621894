import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { mergeArrayNoRepeat } from '../../../../utils/arrayFilters';
import DateString from '../../../../components/DateString';
import {
  InfiniteScroll,
  InfiniteScrollRef,
} from '../../../../components/InfiniteScroll';

import { Container, Activity } from './styles';

const Activities: React.FC = () => {
  const { id } = useParams() as any;
  const [result, setResult] = useState<any>();
  const isRef = useRef<InfiniteScrollRef>();

  const load = useCallback(
    async (page: number) => {
      const uri = `/activities?user_id=${id}&page=${page}`;
      const { data: dataResult } = await api.get(uri);
      // console.log('id', id, dataResult);

      page === 1
        ? setResult(dataResult)
        : setResult((state: any) => {
            return {
              ...state,
              page,
              docs: mergeArrayNoRepeat(state.docs, dataResult.docs),
            };
          });
    },
    [id],
  );

  // if (!result || !result.docs) {
  //   return <div>Carregando</div>;
  // }

  return (
    <Container>
      <InfiniteScroll
        myref={isRef}
        loadMore={load}
        hasMore={!result || result.page < result.pages}
      >
        <div>
          {result && result.docs.length === 0 && (
            <div>Nenhuma atividade registrada!</div>
          )}
          {result &&
            result.docs.map((item: any) => (
              <Activity key={item.id}>
                <h2>{item.user_name}</h2>
                <i>
                  <DateString>{item.created_at}</DateString>
                </i>
                <p>{item.text}</p>
              </Activity>
            ))}
        </div>
      </InfiniteScroll>
    </Container>
  );
};

export default Activities;
