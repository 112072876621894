import axios, { AxiosError } from 'axios';
import appConfig from '../configs/app';

export type ApiError = AxiosError;

export const apiError = (error: ApiError) => {
  return (
    error?.response?.data.message ||
    'Houve um erro na requisição! Tente novamente mais tarde.'
  );
};

export const api = axios.create({
  baseURL: appConfig.API_BASE_URL,
});

api.interceptors.request.use(request => {
  const hasToken = !!request.headers.Authorization; // Authorization

  console.log(
    `🌎${hasToken ? ' 🔐' : ''} [${request.method}] ${request.url}`,
    request.params || '',
  );

  return request;
});

/* api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);

    return token
      ? { ...config, headers: { Authorization: `Bearer ${token}` } }
      : config;
  },
  error => {
    return Promise.reject(error);
  },
); */

export default api;
