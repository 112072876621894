import styled, { css } from 'styled-components';

interface ContainerProps {
  editable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      display: none;
    }
  }

  ${props =>
    props.editable &&
    css`
      &:hover {
        background-color: #eee;
      }

      &:hover > div button {
        display: block;
      }
    `}
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  padding: 2px 10px;
  text-decoration: none;
  max-width: 300px;
  border-bottom: 1px solid #fff;

  p {
    color: #a8a5a5;
    padding-left: 10px;
    font-family: 'sofia_proregular', serif;
    font-size: 16px;
  }

  &:hover {
    border-bottom: 1px solid #a8a5a5;
  }
`;
