export function mergeArrayNoRepeat(
  items: any[],
  newItems: any[],
  notRepeatKey: string | number = 'id',
): any[] {
  return [
    ...items,
    ...newItems.filter((newItem: any) => {
      return (
        items.findIndex(
          item => item[notRepeatKey] === newItem[notRepeatKey],
        ) === -1
      );
    }),
  ];
}
