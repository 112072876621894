/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  InputHTMLAttributes,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import api from '../../../services/api';
import ResponsiveVideo from '../../ResponsiveVideo';

import { Container, Error, Label, ImgArea, Loading } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
}

const Video: React.FC<InputProps> = ({ name, icon: Icon, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [newImage, setNewImage] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  console.log('newImage', newImage);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlue = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setNewImage(String(defaultValue));
  }, [defaultValue]);

  const fileUpload = useCallback(async (fileString: string): Promise<
    string | null
  > => {
    const formData = new FormData();
    formData.append('file', fileString);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const result = await api.post('/files', formData, config);
    return result.data.filename;
  }, []);

  const handleOnChange = useCallback(
    async e => {
      const f: string = e.target.files[0];
      setLoading(true);
      try {
        const fileName = await fileUpload(f);
        // console.log('fileName', fileName);
        if (fileName) {
          setNewImage(fileName);
          if (inputRef && inputRef.current) {
            inputRef.current.value = fileName;
          }
        }
      } catch (error) {}

      setLoading(false);
      // setFile(e.target.files[0]);
    },
    [fileUpload],
  );

  return (
    <>
      {label && <Label>{label}</Label>}

      {!loading && newImage && newImage !== '' && newImage !== undefined && (
        <ImgArea>
          <ResponsiveVideo src={newImage} />
        </ImgArea>
      )}

      {loading && <Loading>Carregando, aguarde...</Loading>}

      <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
        {Icon && <Icon size={20} />}
        <div style={{ opacity: 0, width: 0, overflow: 'hidden' }}>
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlue}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
            type="text"
          />
        </div>

        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlue}
          onChange={handleOnChange}
          // ref={inputRef}
          {...rest}
          type="file"
          style={{ opacity: loading ? '0' : '1' }}
          accept="video/mp4"
        />
      </Container>
      {error && (
        <Error>
          <FiAlertCircle color="#c53030" size={20} />
          {error}
        </Error>
      )}
    </>
  );
};

export default Video;
