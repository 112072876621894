import React, { useState, useCallback } from 'react';
import {
  FiPlusCircle,
  FiCircle,
  FiCheckCircle,
  FiXCircle,
} from 'react-icons/fi';
import Modal from '../../../../components/Modal';
import FormAlternative from '../FormAlternative';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

import { Container, Alternative } from './styles';

const alternativeLetter = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
];

interface Props {
  defaultAlternatives: any[];
  class_id: string;
  question_id: string;
  editable?: boolean;
}

const Alternatives: React.FC<Props> = ({
  defaultAlternatives,
  class_id,
  question_id,
  editable,
}: Props) => {
  const { addToast } = useToast();
  const [alternatives, setAlternatives] = useState(defaultAlternatives || []);
  const [modaAlternativeId, setModalAlternativeId] = useState<any>();

  const handleAddNew = useCallback(() => {
    setModalAlternativeId('new');
  }, []);

  const onFormSuccessAdd = useCallback((newAlternative: any) => {
    setAlternatives(allAlternatives => {
      return [...allAlternatives, newAlternative];
    });
    setModalAlternativeId(null);
  }, []);

  const handleSetCorrect = useCallback(
    async alternativeToSetCorrect => {
      if (!editable) {
        return;
      }

      try {
        await api.put(
          `/avaliations/${alternativeToSetCorrect.class_id}/questions/${alternativeToSetCorrect.question_id}/alternatives/${alternativeToSetCorrect.id}/correct`,
          {},
        );
        setAlternatives(allAlternatives => {
          return allAlternatives.map((alt: any) =>
            alt.id === alternativeToSetCorrect.id
              ? { ...alt, correct: true }
              : { ...alt, correct: false },
          );
        });
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.messasge
        ) {
          addToast({
            title: 'Error',
            description:
              error.response.data.messasge ||
              'Não foi possível concluir a solicitação. Tente novameente mais tarde!',
            type: 'error',
          });
        }

        addToast({
          title: 'Error',
          description:
            'Não foi possível concluir a solicitação. Tente novameente mais tarde!',
          type: 'error',
        });
      }
    },
    [addToast, editable],
  );

  const handleDelete = useCallback(
    async alternativeToDelete => {
      try {
        await api.delete(
          `/avaliations/${alternativeToDelete.class_id}/questions/${alternativeToDelete.question_id}/alternatives/${alternativeToDelete.id}`,
          {},
        );
        setAlternatives(allAlternatives => {
          return allAlternatives.filter(
            (alt: any) => alt.id !== alternativeToDelete.id,
          );
        });
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.messasge
        ) {
          addToast({
            title: 'Error',
            description:
              error.response.data.messasge ||
              'Não foi possível concluir a solicitação. Tente novameente mais tarde!',
            type: 'error',
          });
        }

        addToast({
          title: 'Error',
          description:
            'Não foi possível concluir a solicitação. Tente novameente mais tarde!',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  return (
    <>
      {modaAlternativeId && (
        <Modal
          setModalId={setModalAlternativeId}
          title={
            modaAlternativeId === 'new'
              ? 'Nova Alternativa'
              : 'Editar Alternativa'
          }
        >
          <FormAlternative
            onFormSuccess={onFormSuccessAdd}
            questionId={question_id}
            classId={class_id}
            itemId={modaAlternativeId}
          />
        </Modal>
      )}

      <Container>
        {alternatives &&
          alternatives.map((alternative: any, altIndex: number) => (
            <Alternative key={alternative.id}>
              <button
                type="button"
                onClick={() => {
                  handleSetCorrect(alternative);
                }}
                className="button-check"
              >
                {alternative.correct ? (
                  <FiCheckCircle size={20} color="#17E29A" />
                ) : (
                  <FiCircle size={20} color="#a19e9e" />
                )}
              </button>
              <h3>{`${alternativeLetter[altIndex]} - ${alternative.title}`}</h3>
              {editable && (
                <div>
                  <button
                    type="button"
                    className="button-delete"
                    onClick={() => {
                      handleDelete(alternative);
                    }}
                  >
                    <FiXCircle size={20} color="#F00" />
                  </button>
                </div>
              )}
            </Alternative>
          ))}
        {editable && (
          <button
            type="button"
            onClick={handleAddNew}
            className="button-add-new-alternative"
          >
            <FiPlusCircle size={20} color="#000" />
            Nova Alternativa
          </button>
        )}
      </Container>
    </>
  );
};

export default Alternatives;
