import styled, { css } from 'styled-components';

export const Container = styled.div``;

interface InputProps {
  hasError?: boolean;
}

export const Input = styled.input<InputProps>`
  color: #666;
  background: #fefaf9;
  border-radius: 10px;
  border: 1px solid #9596fb;
  padding: 10px;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: #c53030;
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      background-color: #eee;
    `}
`;

export const Select = styled.select<InputProps>`
  color: #666;
  background: #fefaf9;
  border-radius: 10px;
  border: 1px solid #9596fb;
  padding: 10px;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: #c53030;
    `}
`;

export const Label = styled.label`
  display: block;
  padding-top: 20px;
  padding-bottom: 5px;
  color: #9596fb;
  text-align: left;
`;

export const ErrorMessage = styled.div`
  display: flex;
  height: 20px;
  margin: 10px;
  font-size: 12px;
  align-items: center;
  min-height: 20px;
  color: #c53030;
  svg {
    margin: 0 10px 0 0;
  }
`;
