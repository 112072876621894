import React, { useCallback, useState, useEffect } from 'react';

import { Container, BoxList, Box, Frag, ButtonOpen } from '../result.styles';
import api from '../../../../services/api';
import appConfig from '../../../../configs/app';

import PieChart from '../PIeChart';

const Classroom: React.FC = () => {
  const [res, setRes] = useState<any>();
  const [chartValues, setChartValues] = useState<any[]>([]);

  const load = useCallback(async () => {
    const { data } = await api.get('/reports/classroom/users');
    setChartValues(data.items);
    setRes(data);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleDetails = useCallback(
    async (item_id: string, opened: boolean) => {
      const { data } = opened
        ? { data: [] }
        : await api.get(`/reports/classroom/users/${item_id}`);

      setRes((r: any) => {
        return {
          ...r,
          items: r.items.map((item: any) => {
            if (item.id === item_id) {
              return { ...item, opened: !opened, data };
            }

            return item;
          }),
        };
      });
    },
    [],
  );

  const handleExport = useCallback((item_id: string) => {
    const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);
    const url = `${appConfig.API_BASE_URL}/reports/classroom/users/${item_id}?token=${token}&export=true`;
    window.open(url, 'blank');
  }, []);

  if (!res) {
    return <div>Carregando</div>;
  }

  return (
    <Container>
      <h2>Turmas</h2>

      <PieChart
        title="Usuários por Turma"
        values={chartValues}
        keys={{ value: 'users_count', title: 'name' }}
      />

      <BoxList>
        <Box>
          <h2>Turmas</h2>
          <p>{res.total_classrooms}</p>
        </Box>
        <Box>
          <h2>Usuários</h2>
          <p>{res.total_users}</p>
        </Box>
      </BoxList>

      <table>
        <thead>
          <tr>
            <th>.</th>
            <th>Nome</th>
            <th>Quant. de Usuários</th>
            <th>Exportar</th>
          </tr>
        </thead>
        <tbody>
          {res.items.map((item: any) => (
            <Frag key={item.id}>
              <tr>
                <td>
                  <ButtonOpen
                    opened={item.opened}
                    onClick={() => handleDetails(item.id, !!item.opened)}
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.users_count}</td>
                <td>
                  <button type="button" onClick={() => handleExport(item.id)}>
                    Exportar
                  </button>
                </td>
              </tr>
              {!!item.opened && (
                <tr>
                  <td colSpan={3}>
                    <table>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>E-mail</th>
                          <th>Empresa</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data.map((subitem: any) => (
                          <tr key={subitem.id}>
                            <td>{subitem.name}</td>
                            <td>{subitem.email}</td>
                            <td>{subitem.company_name}</td>
                            <td>{subitem.status_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </Frag>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default Classroom;
