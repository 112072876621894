import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiBell, FiMessageCircle } from 'react-icons/fi';
import { RiAdminLine, RiUserSharedLine, RiMenu2Line } from 'react-icons/ri';
import { useMenu } from '../../hooks/menu';
import { useAuth } from '../../hooks/auth';
import { useChat } from '../../hooks/chat';
import AppConfig from '../../configs/app';

import {
  Container,
  LeftColumn,
  MiddleColumn,
  RightColumn,
  ButtonMenu,
  Button,
} from './styles';

import LogoHeaderImg from '../../assets/logo-header.svg';

const { MODULE_CHAT_ENABLED, MODULE_LOCAL_NOTIFICATION_ENABLED } = AppConfig;

interface InputProps {
  bgColor?: string;
}

const Header: React.FC<InputProps> = ({ bgColor }: InputProps) => {
  const { toggle } = useMenu();
  const { user } = useAuth();
  const { toggle: toggleChat, contacts } = useChat();

  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  useEffect(() => {
    setUnreadMessages(
      contacts ? contacts.filter((c: any) => c.last_message_unread).length : 0,
    );
  }, [contacts]);

  return (
    <Container bgColor={bgColor}>
      <LeftColumn>
        <ButtonMenu onClick={toggle}>
          <RiMenu2Line color="#FFF" size={24} />
        </ButtonMenu>
      </LeftColumn>
      <MiddleColumn>
        <img src={LogoHeaderImg} alt="Logo" />
      </MiddleColumn>
      <RightColumn>
        {MODULE_LOCAL_NOTIFICATION_ENABLED && bgColor !== 'black' && (
          <Button onClick={() => {}}>
            <FiBell color="#FFF" size={24} />
          </Button>
        )}
        {MODULE_CHAT_ENABLED && user?.classroom?.full_access && (
          <Button onClick={() => toggleChat()}>
            {unreadMessages > 0 && <span>{unreadMessages}</span>}
            <FiMessageCircle color="#FFF" size={24} />
          </Button>
        )}

        {user && user.is_admin && bgColor && bgColor === 'black' && (
          <Link to="/dashboard">
            <RiUserSharedLine color="#FFF" size={24} />
          </Link>
        )}

        {user && user.is_admin && !bgColor && (
          <Link to="/admin">
            <RiAdminLine color="#FFF" size={24} />
          </Link>
        )}
      </RightColumn>
    </Container>
  );
};

export default Header;
