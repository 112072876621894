import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 60px;
`;

export const Content = styled.div`
  @media (min-width: 768px) {
    padding-left: 253px;
  }
`;
