import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Formik, Form } from 'formik';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import Input from '../../forms/FormA/Input';
import Submit from '../../forms/FormA/Submit';
import { initialValues, validationSchema } from './form-data';

import logoImg from '../../assets/logo.svg';
import PageError from '../../components/PageError';

import { Container, Content, AnimationContainer, Background } from './styles';
import { Alert } from '../../utils/alerts';
import TextModal from '../TextModal';
import { Terms } from '../../forms/FormA';
import { useRemoteConfigs } from '../../hooks/remote-configs';

const SignUpPublic: React.FC = () => {
  const { addToast } = useToast();

  const { ALLOW_PUBLIC_REGISTER } = useRemoteConfigs();

  const { setSignInData, info } = useAuth();
  const [textUri, setTextUri] = useState<string | undefined>();

  const handleSubmit = useCallback(
    async ({ terms, ...data }: any) => {
      if (data.password !== data.re_password) {
        Alert('A confirmação da senha deve ser igual a senha!');
        return;
      }

      if (!terms) {
        Alert(
          'Você deve aceitar os Termos de uso e Política de privacidade antes de continuar!',
        );
        return;
      }

      try {
        const {
          data: { user, token },
        } = await api.post('/api/auth/signup', data);

        setSignInData({
          user,
          token,
        });
      } catch (error) {
        const message =
          error.response?.data?.message ||
          'Houve um erro na requisição. Tente novamente mais tarde!';

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: message,
        });
      }
    },
    [addToast, setSignInData],
  );

  if (!ALLOW_PUBLIC_REGISTER) {
    return <PageError message="Cadastro não permitido!" />;
  }

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Neoxs" />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <h1>Complete o seu cadastro</h1>
              <Input name="name" label="Nome*" placeholder="Seu nome" />

              <Input
                name="email"
                type="email"
                label="E-mail*"
                placeholder="Seu e-mail"
              />

              <Input
                name="phone"
                type="text"
                label="Telefone*"
                placeholder="ex: 1199999999"
              />

              <Input
                name="company_name"
                type="text"
                label="Empresa (Opcional)"
              />

              <Input
                name="company_position"
                type="text"
                label="Cargo (Opcional)"
              />

              <Input name="password" type="password" label="Senha*" />

              <Input
                name="re_password"
                type="password"
                label="Confirmar Senha*"
              />

              <Terms
                onClickTerms={() => setTextUri('/api/texts/terms')}
                onClickPrivacy={() => setTextUri('/api/texts/privacy')}
              />

              <Submit label="Cadastrar" />
            </Form>
          </Formik>

          <Link to="/">
            <FiArrowLeft />
            Voltar para Logon
          </Link>
        </AnimationContainer>
      </Content>
      {textUri && (
        <TextModal
          uri={textUri}
          onClose={() => {
            setTextUri(undefined);
          }}
        />
      )}
    </Container>
  );
};

export default SignUpPublic;
