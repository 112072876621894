import React from 'react';
import { FiDownload, FiXCircle } from 'react-icons/fi';

import { Container, Button } from './styles';

interface Props {
  to: string;
  name: string;
  editable?: boolean;
  onDelete?: any;
}

const Attachment: React.FC<Props> = ({
  to,
  name,
  editable,
  onDelete,
}: Props) => {
  return (
    <Container editable={editable}>
      <Button href={to} target="blank">
        <div>
          <FiDownload size={20} color="#27D9A7" />
        </div>

        <p>{name}</p>
      </Button>
      {editable && (
        <div>
          <button type="button" onClick={onDelete}>
            <FiXCircle size={20} color="#F00" />
          </button>
        </div>
      )}
    </Container>
  );
};

export default Attachment;
