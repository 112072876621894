import styled from 'styled-components';

export const Container = styled.div`
  button.button-add-new-alternative {
    display: flex;
    align-items: center;
    background: inherit;
    border: 0;
    padding: 5px 10px 5px 20px;
    font-family: 'sofia_probold', serif;

    svg {
      margin-right: 8px;
    }
  }
`;

export const Alternative = styled.div`
  padding: 5px 10px;

  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }

  h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: #a19e9e;
    font-family: 'sofia_proregular', serif;
  }

  button.button-check {
    display: flex;
    align-items: center;
    background: inherit;
    border: 0;
    padding: 5px 10px;
    font-family: 'sofia_probold', serif;

    svg {
      margin-right: 8px;
    }
  }

  & > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }

  button.button-delete {
    display: none;
    align-items: center;
    background: inherit;
    border: 0;
    padding: 5px 10px;
    font-family: 'sofia_probold', serif;

    svg {
      margin-right: 8px;
    }
  }

  &:hover {
    background-color: #eee;

    button.button-delete {
      display: flex;
    }
  }
`;
