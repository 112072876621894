import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

interface Props {
  label: string;
  isSubmitting?: boolean;
}

const SimpleSubmit: React.FC<Props> = ({ label, isSubmitting }: Props) => {
  return (
    <Container type="submit" disabled={isSubmitting}>
      {isSubmitting ? (
        <ReactLoading type="spin" color="#FFF" height={20} width={20} />
      ) : (
        label
      )}
    </Container>
  );
};

SimpleSubmit.defaultProps = {
  isSubmitting: false,
};

export default SimpleSubmit;
