/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { Container } from './styles';

import appConfig from '../../configs/app';

interface Props {
  src: string;
  local?: boolean;
  proportion?: '16:9' | '9:16' | '1:1';
  defaultType?: 'user' | 'image';
}

const ResponsiveImage: React.FC<Props> = ({
  src,
  local = true,
  proportion = '16:9',
  defaultType = 'image',
}: Props) => {
  /* const getSrc = useCallback(
    currentSrc => {
      if (
        !local ||
        currentSrc.substr(0, 7) === 'http://' ||
        currentSrc.substr(0, 8) === 'https://'
      ) {
        return currentSrc;
      }

      const dImage = defaultType === 'image' ? defaultImage : defaultUserImage;

      return currentSrc ? `${appConfig.imageUrl}/${currentSrc}` : dImage;
    },
    [defaultType, local],
  ); */

  return (
    <Container proportion={proportion}>
      <div className="responsive-image-wrap">
        {/* <img src={getSrc(src)} alt={src} /> */}
        <video
          width="320"
          height="240"
          controls
          controlsList="nodownload noremoteplayback"
        >
          <source src={`${appConfig.imageUrl}/${src}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Container>
  );
};

export default ResponsiveImage;
