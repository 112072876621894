import React from 'react';

import { Container } from './styles';
import ResponsiveImage from '../../../components/ResponsiveImage';

const UserProfile: React.FC = () => {
  return (
    <Container>
      <header>
        <div>
          <ResponsiveImage src="" proportion="1:1" defaultType="user" />
        </div>
        <div>
          <h1>Marcus Matos</h1>
          <h2>Green Web - Diretor</h2>
        </div>
      </header>
    </Container>
  );
};

export default UserProfile;
