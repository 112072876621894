import React, { useState, useMemo, useEffect } from 'react';

import { FiPlus, FiXCircle } from 'react-icons/fi';

import {
  Container,
  AddButton,
  Columns,
  Column,
  SearchBar,
  SearchResults,
  SearchResult,
} from './styles';

interface SearchItem {
  id: string;
  label: string;
  type: 'string';
  value: any;
}

interface SearchOption {
  id: string;
  label: string;
  type: 'string';
}

interface Props {
  onClickAdd?: () => void;
  searchOptions?: SearchOption[];
  onSearch?: (value: any) => void;
}

const Header: React.FC<Props> = ({
  onClickAdd,
  searchOptions,
  onSearch,
}: Props) => {
  const [searches, setSearches] = useState<SearchItem[]>([]);
  const [selectedSearchId, setSelectedSearchId] = useState<string | undefined>(
    searchOptions ? searchOptions[0].id : undefined,
  );

  const [searchValue, setSearchValue] = useState<any>('');

  const selectedSearch = useMemo<SearchOption | undefined>(() => {
    if (!searchOptions || !selectedSearchId) return undefined;

    const findSearch = searchOptions.filter(i => i.id === selectedSearchId);

    return findSearch ? findSearch[0] : undefined;
  }, [searchOptions, selectedSearchId]);

  useEffect(() => {
    if (!onSearch) {
      return;
    }
    onSearch(
      searches.reduce((old, item) => {
        return {
          ...old,
          [item.id]: item.value,
        };
      }, {}),
    );
  }, [onSearch, searches]);

  return (
    <Container>
      <Columns>
        {onClickAdd && (
          <Column>
            <AddButton onClick={onClickAdd}>Adicionar</AddButton>
          </Column>
        )}

        <Column>
          {searchOptions && (
            <SearchBar>
              <select
                value={selectedSearchId}
                onChange={e => setSelectedSearchId(e.target.value)}
              >
                {searchOptions.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.label}
                  </option>
                ))}
              </select>
              {selectedSearch && selectedSearch.type === 'string' && (
                <input
                  type="text"
                  name={selectedSearch.id}
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  placeholder="O que deseja?"
                />
              )}
              <button
                type="button"
                onClick={() => {
                  if (!selectedSearch) return;

                  setSearches(state => {
                    const has = state.find(i => i.id === selectedSearch.id);
                    if (has) {
                      return state.map(i => {
                        if (i.id === selectedSearch.id) {
                          return {
                            ...selectedSearch,
                            value: searchValue,
                          };
                        }
                        return i;
                      });
                    }

                    return [
                      ...state,
                      {
                        ...selectedSearch,
                        value: searchValue,
                      },
                    ];
                  });

                  setSearchValue('');
                }}
              >
                <FiPlus size={20} color="#CCC" />
              </button>
            </SearchBar>
          )}
        </Column>
      </Columns>
      {searches.length > 0 && (
        <SearchResults>
          {searches.map(item => (
            <SearchResult key={item.id}>
              <span>
                {item.label}
                {': '}
                {item.value}
              </span>
              <button
                type="button"
                onClick={() => {
                  setSearches(state => {
                    return state.filter(i => i.id !== item.id);
                  });
                  // handleRemoveFilter(f_key);
                }}
              >
                <FiXCircle size={14} color="#000" />
              </button>
            </SearchResult>
          ))}
        </SearchResults>
      )}
    </Container>
  );
};

export default Header;
