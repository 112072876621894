import React, { useCallback, ReactNode } from 'react';

import { FiXCircle } from 'react-icons/fi';
import { Container, ModalView, ModalBody, ModalHeader } from './styles';

interface ModalProviderProps {
  setModalId: React.Dispatch<React.SetStateAction<null>>;
  children?: ReactNode;
  title: string;
}

const Modal: React.FC<ModalProviderProps> = ({
  setModalId,
  children,
  title,
}: ModalProviderProps) => {
  const handleClose = useCallback(() => {
    setModalId(null);
  }, [setModalId]);

  return (
    <Container>
      <ModalView>
        <ModalHeader>
          <div className="leftColumn">
            <h1>{title}</h1>
          </div>
          <div className="rightColumn">
            <button type="button" onClick={handleClose}>
              <FiXCircle size={26} color="#333" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalView>
    </Container>
  );
};

export default Modal;
