import { createGlobalStyle } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';
import 'react-credit-cards/es/styles-compiled.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    color: #666;
    background: #FEFAF9;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px 'sofia_proregular', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  /* #root {
    max-width: 960px;
    margin: 0 auto;
    padding: 40px 20px;
  } */

  button {
    cursor: pointer;
  }

`;
