import React, { useState, useCallback, useRef } from 'react';
import { FiXCircle } from 'react-icons/fi';
import FormQuiz from './FormQuiz';
import api from '../../../services/api';
import { Alert, Confirm } from '../../../utils/alerts';
import appConfig from '../../../configs/app';
import { mergeArrayNoRepeat } from '../../../utils/arrayFilters';
import barOptions from './barOptions';

import {
  InfiniteScroll,
  InfiniteScrollRef,
} from '../../../components/InfiniteScroll';

import {
  Title,
  Table,
  ActionButton,
} from '../../../components/AdminComponents';
import { useToast } from '../../../hooks/toast';

import { Container, ButtonInvite } from './styles';

import AdminBar from '../../../components/AdminBar';

import Modal from '../../../components/Modal';

import defaultUserImage from '../../../assets/default-user-image.png';

const Quizzes: React.FC = () => {
  const { addToast } = useToast();
  const [modalId, setModalId] = useState<any>('');
  const [searchQuery, setSearchQuery] = useState<any>(null);
  const [result, setResult] = useState<any>();
  const isRef = useRef<InfiniteScrollRef>();

  const loadData = useCallback(
    async (page: number = 1) => {
      const uri = searchQuery
        ? `/adm/quizzes?page=${page}&search_key=${searchQuery.key}&search_value=${searchQuery.value}&search_exact=${searchQuery.exact}`
        : `/adm/quizzes?page=${page}`;

      const { data: dataResult } = await api.get(uri);

      page === 1
        ? setResult(dataResult)
        : setResult((state: any) => {
            return {
              ...state,
              page,
              items: mergeArrayNoRepeat(state.items, dataResult.items),
            };
          });
    },
    [searchQuery],
  );

  const handleDeleteItem = useCallback(async id => {
    const ok = await Confirm('Tem certeza que deseja remover este item?');
    if (ok) {
      await api.delete(`/adm/quizzes/${id}`);
      setResult((state: any) => ({
        ...state,
        items: state.items.filter((dd: any) => dd.id !== id),
      }));
      Alert('Item deletado com sucesso!');
    }
  }, []);

  const onFormSuccess = useCallback(item => {
    setModalId(null);

    setResult((state: any) => ({
      ...state,
      items:
        state.items.findIndex((i: any) => i.id === item.id) === -1
          ? [item, ...state.items]
          : state.items.map((i: any) => (i.id === item.id ? item : i)),
    }));
  }, []);

  const handlePublish = useCallback(
    async (id: string) => {
      try {
        const {
          data: { message },
        } = await api.post(`/adm/quizzes/${id}/publish`, {});
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: message,
        });
      } catch ({ response }) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            response.data.message || 'Não foi possível realizar a requisição!',
        });
      }
    },
    [addToast],
  );

  const handleSearch = useCallback(values => {
    isRef?.current?.resetPages();
    setSearchQuery(values);
    setResult(null);
  }, []);

  return (
    <Container>
      <Title>Quiz</Title>

      <AdminBar
        handleAdd={() => setModalId('new')}
        handleSearch={handleSearch}
        options={barOptions}
      />

      {modalId && (
        <Modal
          setModalId={setModalId}
          title={modalId === 'new' ? 'Novo Quiz' : 'Editar Quiz'}
        >
          <FormQuiz onFormSuccess={onFormSuccess} itemId={modalId} />
        </Modal>
      )}

      <InfiniteScroll
        myref={isRef}
        loadMore={loadData}
        hasMore={!result || result.page < result.pages}
      >
        <Table>
          <table>
            <thead>
              <tr>
                <th className="images">Foto</th>
                <th>Título</th>
                <th>Questões</th>
                <th>Turma</th>
                <th>Publicado</th>
                <th className="actions">_</th>
              </tr>
            </thead>
            <tbody>
              {result &&
                result.items.map((item: any) => (
                  <tr key={item.id}>
                    <td className="images">
                      <img
                        src={
                          item.avatar
                            ? `${appConfig.imageUrl}/${item.avatar}`
                            : defaultUserImage
                        }
                        alt={item.avatar}
                      />
                    </td>
                    <td>
                      <p>{item.title}</p>
                    </td>
                    <td>
                      {item.questions.map((q: any) => (
                        <div key={q.id}>
                          {`${q.title} (${q.count_answers})`}
                        </div>
                      ))}
                    </td>
                    <td>{item.classroom?.name}</td>
                    <td>
                      {item.status === 'published' ? 'ativo' : ''}
                      {item.status === 'draft' && (
                        <ButtonInvite
                          onClick={() => {
                            handlePublish(item.id);
                          }}
                        >
                          Publicar
                        </ButtonInvite>
                      )}
                    </td>
                    <td className="actions">
                      {/* <ActionLink to={`/admin/users/${item.id}`}>
                        <FiUser color="#000" size={18} />
                      </ActionLink> */}
                      {/* <ActionButton
                        onClick={() => {
                          handleAddItem(item.id);
                        }}
                      >
                        <FiEdit color="#000" size={18} />
                      </ActionButton> */}

                      <ActionButton onClick={() => handleDeleteItem(item.id)}>
                        <FiXCircle color="#000" size={18} />
                      </ActionButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Table>
      </InfiniteScroll>
    </Container>
  );
};

export default Quizzes;
