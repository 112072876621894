import styled, { css } from 'styled-components';

interface ContainerProps {
  bgColor?: string;
  openned: boolean;
}

export const Select = styled.select`
  width: calc(100% - 40px);
  padding: 10px 0;
  margin: 4px 20px;
  color: #16b77e;
  border: 1px solid #16b77e;
`;

export const Container = styled.div<ContainerProps>`
  display: none;
  position: fixed;
  top: 60px;
  width: 253px;
  max-width: 253px;
  height: calc(100vh - 60px);

  background-color: #fefaf9;

  -webkit-box-shadow: 3px 2px 11px -4px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 3px 2px 11px -4px rgba(0, 0, 0, 0.35);
  box-shadow: 3px 2px 11px -4px rgba(0, 0, 0, 0.35);
  z-index: 100;
  flex-direction: column;

  ${props =>
    props.openned &&
    css`
      display: flex;
    `}

  @media (min-width: 768px) {
    display: flex;
  }

  header {
    background-color: #16b77e;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      display: block;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      border: 1px solid #fefaf9;
    }

    h1 {
      font-size: 16px;
      color: #fefaf9;
      font-family: 'sofia_proregular', serif;
      font-weight: 400;
      padding-top: 18px;
      line-height: 1em;
    }

    h2 {
      font-size: 12px;
      color: #fefaf9;
      font-family: 'sofia_proregular', serif;
      font-weight: 400;
      line-height: 1em;
    }

    ${props =>
      props.bgColor &&
      props.bgColor === 'black' &&
      css`
        background-color: #444;
      `}
  }

  ul {
    padding: 10px 0;
    flex: 1;
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom: 44px;
  }

  ul a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #16b77e;

    &.active {
      color: #fefaf9;
      background-color: #17e29a;
    }

    ${props =>
      props.bgColor &&
      props.bgColor === 'black' &&
      css`
        color: #666;

        &.active {
          color: #fefaf9;
          background-color: #666;
        }
      `}
  }

  ul li.button-subscribe a {
    color: #c53030;
  }

  footer {
    position: fixed;
    width: 253px;
    bottom: 0;
    padding: 0 20px;
  }

  footer a {
    display: block;
    text-decoration: none;
    color: #16b77e;
    padding: 10px 0;

    border-top: 2px solid #cbc8c7;
    ${props =>
      props.bgColor &&
      props.bgColor === 'black' &&
      css`
        color: #666;
      `}
  }
`;
