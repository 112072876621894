import React, { useState, useEffect, useCallback } from 'react';
import ReactLoading from 'react-loading';
import { FiSearch } from 'react-icons/fi';
import api from '../../services/api';
import { Alert } from '../../utils/alerts';

import { Container, Column, AddButton, SearchForm } from './styles';

interface Props {
  handleAdd?(): void;
  handleSearch?(result: any): void;
  options?: any;
}

const AdminBar: React.FC<Props> = ({
  handleAdd,
  handleSearch,
  options,
}: Props) => {
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [exact, setExact] = useState(false);
  const [filterType, setFilterType] = useState('string');
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      options &&
      options.search_filters &&
      options.search_filters.length > 0
    ) {
      setSearchKey(options.search_filters[0].key);
    }
  }, [options]);

  const handleChangeKey = useCallback(
    async event => {
      setSearchValue('');
      setSearchKey(event.target.value);
      setValues([]);
      setLoading(true);
      setExact(true);

      const selected_option_index = options.search_filters.findIndex(
        (op: any) => op.key === event.target.value,
      );

      if (selected_option_index !== -1) {
        const selected_option = options.search_filters[selected_option_index];
        setFilterType(selected_option.type);

        if (selected_option.type === 'string') {
          setLoading(false);
          setExact(false);
          return;
        }

        if (selected_option.type === 'array') {
          setValues(selected_option.values);
          setLoading(false);
          return;
        }

        if (selected_option.type === 'api') {
          try {
            const result = await api.get(selected_option.api_url);

            setValues(() =>
              result.data.map((item: any) => {
                return {
                  key: item[selected_option.api_result_key],
                  label: item[selected_option.api_result_label],
                };
              }),
            );
            setLoading(false);
          } catch (error) {
            setLoading(false);
            Alert(
              error.response.data.message || 'Não foi possível buscar os itens',
            );
          }
        }
      }
    },
    [options.search_filters],
  );

  const handleSearchClick = useCallback(() => {
    if (handleSearch) {
      if (searchKey && searchValue) {
        handleSearch({
          key: searchKey,
          value: searchValue,
          exact,
        });
      } else {
        handleSearch(null);
      }
    }
  }, [exact, handleSearch, searchKey, searchValue]);

  return (
    <Container>
      <Column>
        <AddButton onClick={handleAdd}>Adicionar</AddButton>
      </Column>
      <Column>
        <SearchForm>
          <select
            name="search_key"
            value={searchKey}
            onChange={handleChangeKey}
          >
            {options &&
              options.search_filters &&
              options.search_filters.map((op: any) => (
                <option key={op.key} value={op.key}>
                  {op.label}
                </option>
              ))}
          </select>

          {filterType === 'string' && (
            <input
              className="input_value"
              type="text"
              name="search_value"
              placeholder="O que deseja buscar?"
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
            />
          )}

          {loading && (
            <div className="loading-area">
              <ReactLoading
                type="spin"
                color="#16B77E"
                height={20}
                width={20}
              />
            </div>
          )}

          {!loading && (filterType === 'api' || filterType === 'array') && (
            <select
              name="search_value"
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
            >
              <option value="">Escolha uma opção</option>
              {values &&
                values.map((op: any) => (
                  <option key={op.key} value={op.key}>
                    {op.label}
                  </option>
                ))}
            </select>
          )}

          <button type="button" onClick={handleSearchClick}>
            <FiSearch size={20} color="#CCC" />
          </button>
        </SearchForm>
      </Column>
    </Container>
  );
};

export default AdminBar;
