import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import ReactLoading from 'react-loading';

export { default as Header } from './Header';
export { default as Modal } from './Modal';

export const Container = styled.div`
  padding: 20px;
`;

export const TableContainer = styled.div`
  overflow-x: scroll;

  table {
    width: 100%;
    min-width: 400px;
  }

  th {
    text-align: left;
    padding: 10px;
  }

  td {
    padding: 10px;
    font-family: 'sofia_proregular', serif;
    font-size: 1em;
  }

  th,
  td {
    border: 1px solid #ccc;
  }

  th.actions {
    width: 150px;
  }

  td.actions {
    width: 150px;

    svg {
      margin: 0 4px;
    }
  }

  th.images {
    width: 80px;
  }

  td.images {
    width: 80px;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }

    div.responsive-image-wrap img {
      width: 100%;
      height: 100%;
    }
  }

  tr:hover td {
    background-color: #999;
  }
`;

const ActionButton = styled.button`
  background-color: inherit;
  border: 0;
`;

interface TableProps {
  children: ReactNode;
}

export const Table: React.FC<TableProps> = ({ children }: TableProps) => {
  return (
    <TableContainer>
      <table>{children}</table>
    </TableContainer>
  );
};

interface ActionButtonsProps {
  onClickUpdate?: () => void;
  onClickDelete?: () => void;
  isDeleting?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  onClickUpdate,
  onClickDelete,
  isDeleting,
}: ActionButtonsProps) => {
  return (
    <div>
      {onClickUpdate && (
        <ActionButton>
          <FiEdit color="#000" size={18} onClick={onClickUpdate} />
        </ActionButton>
      )}
      {onClickDelete && (
        <ActionButton onClick={onClickDelete} disabled={isDeleting}>
          {isDeleting ? (
            <ReactLoading color="#000" width={22} height={22} type="spin" />
          ) : (
            <FiXCircle color="#000" size={18} />
          )}
        </ActionButton>
      )}
    </div>
  );
};
