import { useFormikContext } from 'formik';
import React from 'react';
import NumberFormat from 'react-number-format';

// import { Container } from './styles';
import { Container, Input, Label, ErrorMessage } from '../styles';

interface Props {
  name: string;
  label: string;
  readOnly?: boolean;
}

const CurrencyField: React.FC<Props> = ({ name, label, readOnly }: Props) => {
  const { errors, values, handleChange, setFieldValue } = useFormikContext<
    any
  >();

  return (
    <Container>
      <Label>{label}</Label>
      <NumberFormat
        type="text"
        value={Number(values[name] || 0) / 100}
        onValueChange={({ value }) => setFieldValue(name, Number(value) * 100)}
        readOnly={readOnly}
        customInput={Input}
        prefix="R$"
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        isNumericString
      />
      {errors[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Container>
  );
};

export default CurrencyField;
