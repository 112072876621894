import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RecoveryButton = styled(Link)`
  display: block;
  margin: 0 auto;
  font-family: 'sofia_proregular', serif;
  font-size: 0.9em;
  color: #999;
  background: none;
  border: 0;
  margin-top: 20px;
  text-align: center;
  text-decoration: none;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const FormArea = styled.div`
  width: 288px;

  & > img {
    display: block;
    margin: 0 auto;
  }
`;

export const Form = styled.form`
  padding-top: 30px;

  & > div > span {
    display: block;
    color: #9596fb;
  }

  & > div > input {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #9596fb;
    height: 40px;
    padding: 10px;
  }
`;
