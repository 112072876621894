/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
import React, { ReactNode, MutableRefObject } from 'react';
import ReactLoading from 'react-loading';
import OriginalInfiniteScroll from './OriginalInifiteScroll';
import {} from 'react-infinite-scroller';

export interface InfiniteScrollRef extends React.HTMLProps<HTMLDivElement> {
  resetPages(): void;
}

export interface InfiniteScrollProps {
  children: ReactNode;
  myref: MutableRefObject<InfiniteScrollRef | undefined>;
  element?: string;
  hasMore?: boolean;

  initialLoad?: boolean;

  isReverse?: boolean;

  loadMore(page: number): void;

  pageStart?: number;

  threshold?: number;

  useCapture?: boolean;

  useWindow?: boolean;

  loader?: React.ReactElement;

  getScrollParent?(): HTMLElement | null;
}

const InfinityLoading: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactLoading type="spin" color="#16B77E" height={50} width={50} />
    </div>
  );
};

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  ...rest
}: InfiniteScrollProps) => {
  return (
    <OriginalInfiniteScroll
      loader={<InfinityLoading key="loading" />}
      {...rest}
    >
      {children}
    </OriginalInfiniteScroll>
  );
};
