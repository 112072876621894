import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Window = styled.div`
  padding: 20px;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

export const Body = styled.div`
  padding: 20px;
  background-color: #fff;
  min-width: 700px;
  overflow-x: scroll;
  padding-top: 0;
`;
