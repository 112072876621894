import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import {
  Form,
  Input,
  Select,
  Button,
  FormRefInterface,
  getValidationErrors,
} from '../../../../components/Form';
import { useRemoteConfigs } from '../../../../hooks/remote-configs';

interface SignFormData {
  name: string;
  password: string;
}

interface FormProps {
  onFormSuccess?: any;
  itemId?: string | undefined | null;
}

const FormClassroom: React.FC<FormProps> = ({
  onFormSuccess,
  itemId,
}: FormProps) => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();

  const {
    ALLOW_PUBLIC_REGISTER_TRIAL,
    ALLOW_SUBSCRIPTION,
  } = useRemoteConfigs();

  const [initialData, setInitialData] = useState({});

  const loadData = useCallback(async () => {
    if (itemId === 'new') {
      return;
    }

    try {
      const { data } = await api.get(`/classrooms/${itemId}`);
      setInitialData(data);
    } catch (error) {
      console.log('error', error);
    }
  }, [itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: SignFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const result =
          itemId === 'new'
            ? await api.post(`/api/classrooms`, data)
            : await api.put(`/api/classrooms/${itemId}`, data);

        if (onFormSuccess) {
          onFormSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer o login, cheque as credenciais!',
        });
      }
    },
    [addToast, itemId, onFormSuccess],
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialData={initialData}
    >
      <Input type="text" name="name" label="Nome" />

      {ALLOW_SUBSCRIPTION && (
        <Input type="text" name="description" label="Descrição" />
      )}

      <Select
        name="full_access"
        label="Acesso Completo? (permitido social, chat e etc...)"
      >
        <option value="false">Não</option>
        <option value="true">Sim</option>
      </Select>

      {ALLOW_PUBLIC_REGISTER_TRIAL && (
        <Select name="allow_subscription" label="Permitir assinatura?">
          <option value="false">Não</option>
          <option value="true">Sim</option>
        </Select>
      )}

      {ALLOW_PUBLIC_REGISTER_TRIAL && (
        <Select name="is_trial" label="Padrão de Cadastro?">
          <option value="false">Não</option>
          <option value="true">Sim</option>
        </Select>
      )}

      <Button type="submit">Salvar</Button>
    </Form>
  );
};

export default FormClassroom;
