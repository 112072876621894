import React from 'react';

import { Container } from './styles';

const Restricted: React.FC = () => {
  return (
    <Container>
      <p>Esta funcionalidade não habilitada para sua turma.</p>
    </Container>
  );
};

export default Restricted;
