import React, { useRef, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import {
  Form,
  Input,
  Button,
  FormRefInterface,
  getValidationErrors,
} from '../../components/Form';

import { Container, FormArea } from './styles';

import LogoImg from '../../assets/logo.svg';

interface FormData {
  password: string;
  rePassword: string;
}

const RecoveryPassword: React.FC = () => {
  const formRef = useRef<FormRefInterface>(null);

  const { addToast } = useToast();
  const { token } = useParams();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        if (loading) {
          return;
        }

        const schema = Yup.object().shape({
          password: Yup.string().required('A senha é obrigatório!'),
          rePassword: Yup.string().required('Repetir senha é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.password !== data.rePassword) {
          formRef.current?.setErrors({
            rePassword: 'Repetir senha deve ser igual a senha!',
          });
          return;
        }

        setLoading(true);

        await api.put(`/users/recovery-password/${token}`, {
          password: data.password,
        });

        setLoading(false);
        formRef?.current?.setData({});

        addToast({
          title: 'Sucesso',
          description:
            'A sua senha foi alterada com sucesso! Agora você pode fazer o login.',
        });

        push('/');

        // await signIn({
        //   email: data.email,
        //   // password: data.password,
        // });
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description:
            error.response?.data?.message ||
            'Houve um erro na requisição. Tente novamente mais tarde!',
        });
      }
    },
    [addToast, loading, push, token],
  );

  return (
    <Container>
      <FormArea>
        <img src={LogoImg} alt="Logo" />

        <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
          <p className="description">Cadastre a sua nova senha!</p>
          <Input type="password" name="password" label="Nova Senha" />

          <Input type="password" name="rePassword" label="Repetir Senha" />

          <Button
            type="submit"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!loading && <span>Enviar</span>}
            {loading && (
              <ReactLoading type="spin" color="#FFF" height={20} width={20} />
            )}
          </Button>
        </Form>
      </FormArea>
    </Container>
  );
};

export default RecoveryPassword;
