import styled, { css } from 'styled-components';

interface ContainerProps {
  bgColor?: string;
}

export const Container = styled.header<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: #18ecbd;
  min-height: 60px;
  padding: 0 20px;

  align-items: center;

  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;

  ${props =>
    props.bgColor &&
    props.bgColor === 'black' &&
    css`
      background-color: #000000;
    `}
`;

export const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MiddleColumn = styled.div`
  flex: 1;
  display: flex;

  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin: 0 4px;
  }
`;

export const ButtonMenu = styled.button`
  background-color: inherit;
  border: 0;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Button = styled.button`
  position: relative;
  background-color: inherit;
  border: 0;

  span {
    position: absolute;
    top: -6px;
    right: 0;
    background-color: #f05d5e;
    color: #fefaf9;
    border-radius: 6px;
    font-size: 0.6em;
    padding: 1px 4px;
  }
`;
