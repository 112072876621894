import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import {
  Form,
  Input,
  Button,
  FormRefInterface,
  getValidationErrors,
} from '../../../../components/Form';

interface SignFormData {
  name: string;
}

interface FormProps {
  onFormSuccess?: any;
  itemId?: string | undefined | null;
}

const FormCompany: React.FC<FormProps> = ({
  onFormSuccess,
  itemId,
}: FormProps) => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();

  const [initialData, setInitialData] = useState({});

  const loadData = useCallback(async () => {
    if (itemId === 'new') {
      return;
    }
    try {
      const { data } = await api.get(`/companies/${itemId}`);
      setInitialData(data);
    } catch (error) {}
  }, [itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: SignFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const result =
          itemId === 'new'
            ? await api.post(`/companies`, data)
            : await api.put(`/companies/${itemId}`, data);

        if (onFormSuccess) {
          onFormSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro, tente novamente mais tarde!',
        });
      }
    },
    [addToast, itemId, onFormSuccess],
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialData={initialData}
    >
      <Input type="text" name="name" label="Nome" />

      <Input type="text" name="cnpj" label="CNPJ" />

      <Input type="text" name="contact_name" label="Contato (Nome)" />

      <Input type="text" name="contact_phone" label="Contato (Telefone)" />

      <Input type="text" name="contact_email" label="Contato (E-mail)" />

      <Button type="submit">Salvar</Button>
    </Form>
  );
};

export default FormCompany;
