import * as Yup from 'yup';

export const initialValues = {
  name: '',
  email: '',
  phone: '',
  password: '',
  re_password: '',
  company_name: '',
  company_position: '',
  terms: false,
};

// initialValues.name = 'Marcus';
// initialValues.email = 'marcusamatos@gmail.com';
// initialValues.password = 'Passwd123';
// initialValues.re_password = 'Passwd123';
// initialValues.phone = '1199999999';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Deve ter no mínimo 2 caracteres')
    .max(50, 'Deve ter no máximo 50 caracteres')
    .required('Obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Obrigatório'),
  phone: Yup.string()
    .min(10, 'Deve ter no mínimo 10 caracteres')
    .required('Obrigatório'),
  password: Yup.string()
    .min(6, 'Deve ter no mínimo 6 caracteres')
    .required('Obrigatório'),
  re_password: Yup.string()
    .min(6, 'Deve ter no mínimo 6 caracteres')
    .required('Obrigatório'),
  company_name: Yup.string().min(3, 'Deve ter no mínimo 3 caracteres'),
  company_position: Yup.string().min(3, 'Deve ter no mínimo 3 caracteres'),
});
