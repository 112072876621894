import React, { useCallback, useEffect, useState } from 'react';
import { FiBookOpen, FiCheckCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { Container } from './styles';
import ResponsiveImage from '../../../../components/ResponsiveImage';
import DateString from '../../../../components/DateString';
import api from '../../../../services/api';

interface Props {
  info: any;
}

const Classe: React.FC<Props> = ({ info }: Props) => {
  const [data, setData] = useState<any>();
  const loadInfo = useCallback(async () => {
    try {
      const res = await api.get(`/classes/${info.id}/info`);
      setData(res.data);
    } catch (error) {}
  }, [info]);

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  return (
    <Container>
      <Link to={`/dashboard/turma/aula/${info.id}`}>
        <div className="image-area">
          <div className="image">
            <ResponsiveImage src={info.thumbnail} />
            <div className="image-content">
              <div className="image-content-left">
                {info.has_avaliation && data && (
                  <FiBookOpen
                    size={20}
                    color={data.avaliation_done ? '#17E29A' : '#FFF'}
                  />
                )}
              </div>
              <div className="image-content-right">
                {data && (
                  <FiCheckCircle
                    size={20}
                    color={data.read ? '#17E29A' : '#FFF'}
                  />
                )}
                {!data && (
                  <ReactLoading
                    type="spin"
                    color="#16B77E"
                    height={20}
                    width={20}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="content-area">
          <h2>{info.name}</h2>
          <span>
            <DateString>{info.created_at}</DateString>
          </span>
        </div>
      </Link>
    </Container>
  );
};

export default Classe;
