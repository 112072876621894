import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiLock, FiArrowLeft } from 'react-icons/fi';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import {
  Form,
  Input,
  Button,
  FormRefInterface,
  getValidationErrors,
} from '../../components/Form';

import logoImg from '../../assets/logo.svg';
import PageLoading from '../../components/PageLoading';
import PageError from '../../components/PageError';

import { Container, Content, AnimationContainer, Background } from './styles';

interface SignUpFormData {
  password: string;
  rePassword: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { register_id } = useParams<any>();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialData, setInitialData] = useState<any>({});
  const { setSignInData } = useAuth();

  const load = useCallback(async () => {
    try {
      const res = await api.get(`/users/register/${register_id}`);

      setInitialData({
        name: res.data.name,
        email: res.data.email,
        classroom_name: res.data.classroom?.name,
        company_name: res.data.company?.name,
        company_position: res.data.company_position,
      });
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'Houve um error ao carregar o conteúdo!',
      );
      setLoading(false);
    }
  }, [register_id]);

  useEffect(() => {
    load();
  }, [load]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'A senha deve ter 6 dígitos!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.password !== data.rePassword) {
          formRef.current?.setErrors({
            rePassword: 'Confirmar senha deve ser igual a senha!',
          });
          return;
        }

        const {
          data: { user, token },
        } = await api.post(`/users/register/${register_id}`, data);

        setSignInData({
          user,
          token,
        });
        // history.push('/');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        const message = error.response?.data?.message;

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            message ||
            'Ocorreu um erro ao fazer o login, cheque as credenciais!',
        });
      }
    },
    [addToast, history, register_id],
  );

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Neoxs" />
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            initialData={initialData}
          >
            <h1>Complete o seu cadastro</h1>

            <Input
              type="text"
              name="name"
              placeholder="Nome"
              label="Nome"
              readOnly
            />

            <Input
              type="text"
              name="email"
              placeholder="E-mail"
              label="E-mail"
              readOnly
            />

            <Input
              type="text"
              name="classroom_name"
              placeholder="Turma"
              label="Turma"
              readOnly
            />

            <Input
              type="text"
              name="company_name"
              placeholder="Empresa"
              label="Empresa"
              readOnly
            />

            <Input
              type="text"
              name="company_position"
              placeholder="Cargo"
              label="Cargo"
              readOnly
            />

            <Input
              type="password"
              name="password"
              placeholder="Senha"
              icon={FiLock}
              label="Senha"
            />

            <Input
              type="password"
              name="rePassword"
              placeholder="Confirmar Senha"
              icon={FiLock}
              label="Confirmar Senha"
            />

            <Button type="submit">Cadastrar</Button>
          </Form>

          <Link to="/">
            <FiArrowLeft />
            Voltar para Logon
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
