import { useFormikContext } from 'formik';
import React, { ReactNode } from 'react';

// import { Container } from './styles';
import { Container, Select, Label, ErrorMessage } from '../styles';

interface Props {
  name: string;
  label: string;
  children: ReactNode;
}

const SelectField: React.FC<Props> = ({ name, label, children }: Props) => {
  const { errors, values, handleChange } = useFormikContext<any>();

  return (
    <Container>
      <Label>{label}</Label>
      <Select
        name={name}
        value={values[name] || ''}
        onChange={handleChange(name)}
      >
        {children}
      </Select>
      {errors[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Container>
  );
};

export default SelectField;
