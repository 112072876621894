import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import api from '../../../services/api';
import ResponsiveVideo from '../../../components/ResponsiveVideo';
import DateString from '../../../components/DateString';

import {
  Container,
  VideoContainer,
  YouTubeBox,
  LinkZoom,
  Title,
} from './styles';

const Live: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState<any>({});

  const loadData = useCallback(async () => {
    const res = await api.get(`/live-classes/${id}`);

    // console.log('info', res.data);

    setInfo(res.data);

    setLoading(false);
  }, [id]);

  const getYoutubeVideoId = useCallback(videoUrl => {
    let video_id = videoUrl.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }

    return video_id;
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Container>
      <Title>{info?.name}</Title>

      <VideoContainer>
        {!loading && info.video_type === '' && (
          <p>Ainda não disponível, aguarde.</p>
        )}

        {!loading && info.video_type === 'youtube' && (
          <YouTubeBox>
            <YouTube
              id="tubeplayer"
              videoId={getYoutubeVideoId(info.video_url)}
              opts={{
                playerVars: {
                  // controls: 0,
                  // iv_load_policy: 3,
                  // fs: 1,
                  modestbranding: 1,
                  rel: 0,
                  showinfo: 0,
                },
              }}
            />
          </YouTubeBox>
        )}

        {!loading && info.video_type === 'file' && (
          <ResponsiveVideo src={info.video_url} />
        )}

        {!loading && info.video_type === 'zoom' && (
          <LinkZoom href={info.video_url} target="blank">
            Abrir Zoom
          </LinkZoom>
        )}
        {info.date && <DateString>{info.date}</DateString>}
      </VideoContainer>
    </Container>
  );
};

export default Live;
