import * as Yup from 'yup';

// export const initialValues = {
//   number: '4111111111111111',
//   cvc: '131',
//   name: 'Marcus V A',
//   expiry: '02/22',
// };

export const initialValues = {
  number: '',
  cvc: '',
  name: '',
  expiry: '',
};

// initialValues.number = '4111111111111111';
// initialValues.cvc = '131';
// initialValues.name = 'Marcus V A Matos';
// initialValues.expiry = '02/22';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Deve ter no mínimo 2 caracteres')
    .max(50, 'Deve ter no máximo 50 caracteres')
    .required('Obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Obrigatório'),
  phone: Yup.string()
    .min(10, 'Deve ter no mínimo 10 caracteres')
    .required('Obrigatório'),
  password: Yup.string()
    .min(6, 'Deve ter no mínimo 6 caracteres')
    .required('Obrigatório'),
  re_password: Yup.string()
    .min(6, 'Deve ter no mínimo 6 caracteres')
    .required('Obrigatório'),
  company_name: Yup.string().min(3, 'Deve ter no mínimo 3 caracteres'),
  company_position: Yup.string().min(3, 'Deve ter no mínimo 3 caracteres'),
});
