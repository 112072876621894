import React, { useCallback, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import Restricted from '../../../components/Restricted';

import { Container } from './styles';

const Appointments: React.FC = () => {
  const [events, setEvents] = useState<any>([]);
  const { addToast } = useToast();
  const { selectedClassroom, user } = useAuth();

  const handleDates = useCallback(
    async selectInfo => {
      try {
        const res = await api.get(
          `/live-classes?start=${selectInfo.startStr}&end=${selectInfo.endStr}${
            selectedClassroom ? `&classroom_id=${selectedClassroom}` : ``
          }`,
        );
        const dataEvents = res.data.map((e: any) => {
          return { title: e.name, date: e.date };
        });
        // console.log('dataEvents', dataEvents);
        setEvents(dataEvents);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            error.response?.data?.message ||
            'Houve um erro ao carregar os evenrtos!',
        });
      }
    },
    [addToast, selectedClassroom],
  );

  const handleEventClick = useCallback(eventInfo => {
    // console.log('eventInfo', eventInfo);
  }, []);

  if (!user?.classroom?.full_access) {
    return <Restricted />;
  }

  return (
    <Container>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={ptBrLocale}
        // eventAdd={handleEventAdd}
        // eventContent={Events}
        events={events}
        eventClick={handleEventClick}
        datesSet={handleDates}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
      />
    </Container>
  );
};

export default Appointments;
