import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import {
  Form,
  Input,
  Button,
  Select,
  FormRefInterface,
  getValidationErrors,
} from '../../../../components/Form';

interface SignFormData {
  name: string;
  password: string;
}

interface FormProps {
  onFormSuccess?: any;
  itemId?: string | undefined | null;
}

const FormUser: React.FC<FormProps> = ({
  onFormSuccess,
  itemId,
}: FormProps) => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();

  const [initialData, setInitialData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [classrooms, setClassrooms] = useState([]);

  const loadData = useCallback(async () => {
    try {
      const companiesResult = await api.get(`/companies`);
      const classroomResult = await api.get(`/classrooms`);

      setCompanies(companiesResult.data);
      setClassrooms(classroomResult.data);

      if (itemId === 'new') {
        return;
      }

      const { data } = await api.get(`/users/${itemId}`);

      setInitialData(data);
    } catch (error) {}
  }, [itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: SignFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório!'),
          email: Yup.string()
            .email('Favor preencher com um e-mail válido!')
            .required('Nome é obrigatório!'),
          company_position: Yup.string().required('O cargo é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        console.log('sent data', data);

        const result =
          itemId === 'new'
            ? await api.post(`/users`, data)
            : await api.put(`/users/${itemId}`, data);

        if (onFormSuccess) {
          onFormSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro, tente novamente mais tarde!',
        });
      }
    },
    [addToast, itemId, onFormSuccess],
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialData={initialData}
    >
      {/* <Image type="text" name="avatar" label="Foto" /> */}

      <Input type="text" name="name" label="Nome" />

      <Select name="classroom_id" label="Turma">
        <option value="">----</option>
        {classrooms.map((classroom: any) => (
          <option key={classroom.id} value={classroom.id}>
            {classroom.name}
          </option>
        ))}
      </Select>

      <Select name="company_id" label="Empresa">
        <option value="">----</option>
        {companies.map((company: any) => (
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        ))}
      </Select>

      <Input type="text" name="company_position" label="Cargo" />

      <Input type="text" name="email" label="E-mail" />

      {/* <Input type="password" name="password" label="Senha" /> */}

      <Select name="is_admin" label="É Administrador">
        <option value="false">Não</option>
        <option value="true">Sim</option>
      </Select>

      <Button type="submit">Salvar</Button>
    </Form>
  );
};

export default FormUser;
