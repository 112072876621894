import { useFormikContext } from 'formik';
import React from 'react';

// import { Container } from './styles';
import { Container, Input, Label, ErrorMessage } from '../styles';

interface Props {
  name: string;
  label: string;
  readOnly?: boolean;
}

const TextField: React.FC<Props> = ({ name, label, readOnly }: Props) => {
  const { errors, values, handleChange } = useFormikContext<any>();

  return (
    <Container>
      <Label>{label}</Label>
      <Input
        name={name}
        value={values[name] || ''}
        onChange={handleChange(name)}
        readOnly={readOnly}
      />
      {errors[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Container>
  );
};

export default TextField;
