import React, { useState, useCallback } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { uuid } from 'uuidv4';

import { useChat } from '..';

import { Container, Messages, Message } from './styles';
import ResponsiveImage from '../../../components/ResponsiveImage';
import { Alert } from '../../../utils/alerts';

const initMessages: any[] = [];

// eslint-disable-next-line no-plusplus
for (let index = 0; index < 90; index++) {
  initMessages.push({
    id: uuid(),
    message: 'Uma mensagem qualquer de teste para tests',
    from_me: index % 2,
  });
  // const element = array[index];
}

interface Props {
  messages: any[];
  sendMessage(message: string): void;
}

const ChatUser: React.FC<Props> = ({ messages, sendMessage }: Props) => {
  const { closeContact, openedContact, messagesContainerRef } = useChat();
  const [newMessage, setNewMessage] = useState('');

  const handleAddMessage = useCallback(() => {
    if (!newMessage || newMessage.length < 2) {
      Alert('A mensagem deve ter no mínimo 2 caracteres!');
      return;
    }

    sendMessage(newMessage);
    setNewMessage('');
  }, [newMessage, sendMessage]);

  return (
    <Container>
      <header>
        <button type="button" onClick={closeContact}>
          <FiArrowLeft size={20} color="#18ECBD" />
        </button>
        <div>
          <div>
            <ResponsiveImage
              src={openedContact?.avatar}
              proportion="1:1"
              defaultType="user"
            />
          </div>
          <hgroup>
            <h3>{openedContact?.name}</h3>
            <h4>{`${openedContact?.company?.name} - ${openedContact?.company_position}`}</h4>
          </hgroup>
        </div>
      </header>
      <Messages ref={messagesContainerRef}>
        {messages.map(message => (
          <Message key={message.id} fromMe={message.from_me}>
            {message.text}
          </Message>
        ))}
      </Messages>
      <footer>
        <input
          type="text"
          placeholder="Sua Mensagem"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          onKeyPress={e => {
            if (e.which === 13) {
              handleAddMessage();
            }
          }}
        />
        <button type="button" onClick={handleAddMessage}>
          Enviar
        </button>
      </footer>
    </Container>
  );
};

export default ChatUser;
