import styled from 'styled-components';

export const ButtonEmail = styled.a`
  display: block;
  color: #17e29a;
  background-color: #e1f7ef;
  width: 100%;
  max-width: 288px;
  border: 0;
  padding: 10px;
  border-radius: 10px;
  font-family: 'sofia_proregular', serif;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;

  &:hover {
    opacity: 0.4;
  }
`;

export const ButtonChat = styled.button`
  display: block;
  color: #fefaf9;
  background-color: #17e29a;
  width: 100%;
  max-width: 288px;
  border: 0;
  padding: 10px;
  border-radius: 10px;
  font-family: 'sofia_proregular', serif;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    opacity: 0.4;
  }
`;

export const Container = styled.div`
  background-color: #f7f7f7;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);

  div.image {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    max-width: 100px;
    margin: 0 auto;

    .image-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    .image-content-left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      padding: 10px;
    }

    .image-content-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: 10px;
    }
  }

  div.content-area {
    flex: 1;
    h2 {
      color: #a19e9e;
      font-size: 14px;
      font-family: 'sofia_probold', serif;
      padding-top: 10px;
      text-transform: uppercase;
    }

    span {
      display: block;
      color: #a8a5a5;
      font-size: 14px;
      font-family: 'sofia_proregular', serif;
      padding-top: 4px;
    }
  }

  & > span {
    display: block;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    & > span {
      display: flex;
      align-items: center;
    }

    div.image-area {
      width: 120px;
      width: 120px;
      margin: 0;
    }

    div.content-area {
      padding: 10px 20px;
    }

    /* &:hover {
      background-color: #eee;
    } */
  }
`;
