import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  display: block;
  font-size: 32px;
  text-align: center;
  color: #838180;
  text-transform: uppercase;
  font-family: sofia_proregular, serif;
  padding: 20px 20px 30px 20px;
`;

export const VideoContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const YouTubeBox = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoBox = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const LinkZoom = styled.a`
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #0964d1;
  color: #fff;
  display: block;
  /* max-width: 300px; */
  text-align: center;
  margin: 0 auto;
`;
