/* eslint-disable no-alert */
export function Alert(message: string): void {
  window.alert(message);
}

export function Confirm(message: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const result = window.confirm(message);

    return resolve(result);
  });
}
