import React, { useCallback } from 'react';

import { Container, ButtonEmail, ButtonChat } from './styles';
import ResponsiveImage from '../../../../components/ResponsiveImage';

import { useChat } from '../../../../hooks/chat';

interface Props {
  info: any;
  showChat: boolean;
}

const Classe: React.FC<Props> = ({ info, showChat }: Props) => {
  const { openContact } = useChat();
  // const loadInfo = useCallback(async () => {
  //   try {
  //     const res = await api.get(`/classes/${info.id}/info`);
  //     setData(res.data);
  //   } catch (error) {}
  // }, [info]);

  // useEffect(() => {
  //   loadInfo();
  // }, [loadInfo]);

  const handleOpenContact = useCallback(() => {
    openContact(info);
  }, [info, openContact]);

  return (
    <Container>
      <span>
        <div className="image-area">
          <div className="image">
            <ResponsiveImage
              src={info.avatar}
              proportion="1:1"
              defaultType="user"
            />
          </div>
        </div>
        <div className="content-area">
          <h2>{info.name}</h2>
          <span>{`${info.company?.name} - ${info.company_position}`}</span>
          <ButtonEmail href={`mailto:${info.email}`} target="blank">
            {info.email}
          </ButtonEmail>
          {showChat && (
            <ButtonChat onClick={handleOpenContact}>Chat</ButtonChat>
          )}
        </div>
      </span>
    </Container>
  );
};

export default Classe;
