import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import { Container, PillMenu, PillMenuContent } from './styles';
import Link from './PillLink';

import Classes from '../Classes';
import Friends from '../Friends';
import Appointments from '../Appointments';
import Avaliations from '../Avaliations';
import Avaliation from '../Avaliation';
import Quizes from '../Quizes';
import Forum from '../Forum';
import { useAuth } from '../../../hooks/auth';
import PageError from '../../../components/PageError';
import DemoMessage from '../../../components/DemoMessage';

const MyClassroom: React.FC = () => {
  const { info, user } = useAuth();

  if (info && info.ALLOW_PUBLIC_REGISTER && user && user.status === 'pending') {
    return <PageError message="Processando pagamento. Aguarde..." />;
  }

  if (
    info &&
    info.ALLOW_PUBLIC_REGISTER &&
    user &&
    user.status === 'canceled'
  ) {
    return (
      <PageError message="Pagamento cancelado! Verifique as informações em Minha Conta" />
    );
  }

  if (
    info &&
    info.ALLOW_PUBLIC_REGISTER &&
    user &&
    user.status === 'payment_failed'
  ) {
    return (
      <PageError message="Falha no pagamento! Verifique as informações em Minha Conta" />
    );
  }

  return (
    <Container>
      <PillMenu>
        <PillMenuContent>
          <Link to="/dashboard/turma/" activeOnlyWhenExact>
            Minhas Aulas
          </Link>
          <Link to="/dashboard/turma/social">Social</Link>
          <Link to="/dashboard/turma/agenda">Agenda</Link>

          <Link to="/dashboard/turma/avaliacoes">Avaliações</Link>
          {/* <Link to="/dashboard/turma/pesquisas">Pesquisas</Link> */}
          <Link to="/dashboard/turma/forum">Forum</Link>
        </PillMenuContent>
      </PillMenu>
      <div>
        <Switch>
          <Route path="/dashboard/turma" exact component={Classes} />
          <Route path="/dashboard/turma/social" exact component={Friends} />
          <Route
            path="/dashboard/turma/agenda"
            exact
            component={Appointments}
          />
          <Route
            path="/dashboard/turma/avaliacoes/:id"
            component={Avaliation}
          />
          <Route
            path="/dashboard/turma/avaliacoes"
            exact
            component={Avaliations}
          />
          <Route path="/dashboard/turma/pesquisas" exact component={Quizes} />
          <Route path="/dashboard/turma/forum" exact component={Forum} />
        </Switch>
      </div>
    </Container>
  );
};

export default MyClassroom;
