import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  background: white;
  position: fixed;
  bottom: 0;
  right: 0;

  width: 100%;

  height: calc(100% - 60px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  /* display: none; */

  border: 1px solid #ccc;

  padding: 10px;

  /* outline: 5px solid red; */

  /* top: 60px;
  height: calc(100% -60px); */

  /* &::before {
    content: 'aaa';
    display: block;
    position: fixed;

    bottom: 0;

    background-color: red;
    left: 0;
    width: 100%;
    height: 100%;
  } */

  @media (min-width: 768px) {
    width: 280px;
  }

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      padding: 10px;
      font-size: 1em;
      color: #838180;
    }

    button {
      background: inherit;
      border: 0;
      padding: 10px;
    }
  }
`;

export const Contents = styled.div`
  background-color: #fff;
  flex: 1;
  overflow-y: scroll;
`;
