import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { FiXCircle } from 'react-icons/fi';

import api from '../../services/api';

import {
  Container,
  Modal,
  ModalContent,
  ModalLoading,
  ModalHeader,
} from './styles';

interface Props {
  uri: string | null;
  onClose(): void;
}

const TextModal: React.FC<Props> = ({ uri, onClose }: Props) => {
  const [htmlText, setHtmlText] = useState<string | undefined>();

  useEffect(() => {
    if (!uri) {
      return;
    }

    setHtmlText(undefined);

    const load = async () => {
      const { data } = await api.get(uri);
      setHtmlText(data);
    };

    load();
  }, [uri]);

  return (
    <Container>
      <Modal>
        <ModalHeader>
          <button type="button" onClick={onClose}>
            <FiXCircle color="#666" size={24} />
          </button>
        </ModalHeader>
        {!htmlText ? (
          <ModalLoading>
            <ReactLoading
              type="spin"
              color="#16B77E"
              height={100}
              width={100}
            />
          </ModalLoading>
        ) : (
          <ModalContent
            dangerouslySetInnerHTML={{
              __html: htmlText,
            }}
          />
        )}
      </Modal>
    </Container>
  );
};

export default TextModal;
