import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
} from 'react';
// import api from '../services/api';
// import storage from '../services/storage';

interface MenuContextData {
  openned: boolean;
  toggle(): void;
  close(): void;
}

export const MenuContext = createContext<MenuContextData>(
  {} as MenuContextData,
);

interface MenuProviderProps {
  children?: ReactNode;
}

export const MenuProvider: React.FC = ({ children }: MenuProviderProps) => {
  const [openned, setOpenned] = useState(false);

  const toggle = useCallback(async () => {
    setOpenned(!openned);
  }, [openned]);

  const close = useCallback(async () => {
    if (!openned) {
      return;
    }
    setOpenned(false);
  }, [openned]);

  return (
    <MenuContext.Provider value={{ openned, toggle, close }}>
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu(): MenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be user with in an MenuProvider');
  }

  return context;
}
