import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const Modal = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 900px;
`;

export const ModalContent = styled.div`
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
  flex: 1;

  ol {
    list-style: decimal;
    list-style-type: decimal;
    padding-left: 30px;
    margin-top: 12px;
  }

  ol li:last-child {
    margin-bottom: 12px;
  }

  ul {
    list-style: disc;
    list-style-type: disc;
    padding-left: 30px;
    margin-top: 12px;
  }

  ul li:last-child {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 12px;
  }
`;

export const ModalHeader = styled.div`
  display: block;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    background-color: inherit;
    border: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ModalLoading = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
