import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background-color: #c53030;
  border-radius: 8px;
  margin-bottom: 20px;

  p {
    color: #fff;
    font-size: 1.2em;
  }

  button,
  a {
    display: inline-block;
    background-color: inherit;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 8px 20px;
    color: #fff;
    margin-top: 10px;
    font-size: 1.2em;
    text-decoration: none;

    &:hover {
      background-color: #fff;
      color: #c53030;
    }
  }
`;
