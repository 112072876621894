import React from 'react';

import { Container, ErrorMessage } from '../styles';

interface Props {
  message: string;
}

const SubmitError: React.FC<Props> = ({ message }: Props) => {
  return (
    <Container>
      <ErrorMessage>{message}</ErrorMessage>
    </Container>
  );
};

export default SubmitError;
