import React from 'react';
import { useRemoteConfigs } from '../../hooks/remote-configs';
import FormSignIn from '../../forms/FormSignIn';

import { Container, FormArea, RecoveryButton } from './styles';

import LogoImg from '../../assets/logo.svg';

const SignIn: React.FC = () => {
  const { ALLOW_PUBLIC_REGISTER } = useRemoteConfigs();

  return (
    <Container>
      <FormArea>
        <img src={LogoImg} alt="Logo" />

        <FormSignIn />
        {ALLOW_PUBLIC_REGISTER && (
          <RecoveryButton to="signup">Cadastrar</RecoveryButton>
        )}

        <RecoveryButton to="recuperar-senha">Esqueci a senha</RecoveryButton>
      </FormArea>
    </Container>
  );
};

export default SignIn;
