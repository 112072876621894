import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Container, Error, Label } from '../styles';

// import { Container } from './styles';

interface InputProps {
  label?: string;
  name: string;
  type?: string;
  readOnly?: boolean;
  placeholder?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  type,
  readOnly,
  placeholder,
}: InputProps) => {
  const { values, handleChange, errors, touched } = useFormikContext<any>();

  const { error, hasError } = useMemo(() => {
    return {
      is_touched: !!touched[name],
      error: errors[name],
      hasError: !!errors[name] && !!touched[name],
    };
  }, [errors, name, touched]);

  return (
    <>
      {label && <Label isErrored={hasError}>{label}</Label>}
      <Container
        isFocused={false}
        isErrored={hasError}
        readOnly={readOnly}
        isFilled
      >
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          value={values[name] || ''}
          onChange={handleChange}
        />
      </Container>
      {hasError ? <Error>{error}</Error> : null}
    </>
  );
};

Input.defaultProps = {
  type: 'text',
  readOnly: false,
  placeholder: '',
};

export default Input;
