import styled, { css } from 'styled-components';

export const Container = styled.div`
  button.textButton {
    font-weight: bold;
    text-decoration: underline;
    background-color: inherit;
    border: 0;
    display: inline;
  }
`;

interface CheckProps {
  checked?: boolean;
}

export const Check = styled.button<CheckProps>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #17e29a;
  border-radius: 8;
  vertical-align: text-bottom;
  margin-right: 10px;
  background-color: #fff;

  ${({ checked }) =>
    checked &&
    css`
      background-color: #17e29a;
    `}
`;
