export interface IData {
  name: string;
  description: string;
  identifier: string;
  interval: number;
  interval_type: string;
  value_cents: number;
  enabled: true;
}

export const initialValues = {
  name: '',
  interval_type: 'months',
};
