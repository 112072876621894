import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;

  h1 {
    font-family: 'sofia_proregular', serif;
    font-size: 24px;
    color: #959595;
    padding: 0 0 20px 0;
  }
`;

export const Questions = styled.div``;

export const Question = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #a19e9e;
    padding-bottom: 10px;
  }
`;

export const Alternatives = styled.div``;

export const Alternative = styled.div`
  display: flex;
  align-items: center;
  background: inherit;
  border: 0;
  padding: 5px 10px;
  font-family: 'sofia_probold', serif;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }
  button {
    display: flex;
    align-items: center;
    background: inherit;
    border: 0;
  }

  span {
    font-family: 'sofia_proregular', serif;
    font-size: 14px;
    color: #838180;
  }
`;

export const ButtonSave = styled.button`
  display: block;
  color: #fefaf9;
  background-color: #17e29a;
  width: 100%;
  /* max-width: 288px; */
  border: 0;
  padding: 10px;
  border-radius: 10px;
  font-family: 'sofia_proregular', serif;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;

  max-width: 300px;

  &:hover {
    opacity: 0.4;
  }
`;
