/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  InputHTMLAttributes,
  // SelectHTMLAttributes,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import DatePicker from 'react-datepicker';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
}

const InputDate: React.FC<InputProps> = ({
  name,
  icon: Icon,
  label,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [currentDate, setCurrentDate] = useState(new Date());

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlue = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleDateChange = useCallback(date => {
    setCurrentDate(date);

    if (inputRef && inputRef.current) {
      inputRef.current.value = date;
    }
    // const { formData } = this.state;
    // this.setState({ formData: { ...formData, date } });
    // console.log('handleDateChange', date);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    // setValue(defaultValue);
    // setCurrentDate(new Date(defaultValue));

    // console.log('defaultValue', new Date(defaultValue));

    if (defaultValue) {
      setCurrentDate(new Date(defaultValue));
    }
  }, [defaultValue]);

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
        {Icon && <Icon size={20} />}
        <div style={{ opacity: 0, width: 0, overflow: 'hidden' }}>
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlue}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          />
        </div>

        <DatePicker
          showTimeSelect
          // dateFormat="P"
          selected={currentDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy HH'h'mm"
        />

        {/* <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlue}
          defaultValue={defaultValue}
          value={value}
          ref={inputRef}
          onChange={e => {
            setValue(e.target.value);
          }}
          {...rest}
        >
          {children}
        </select> */}
      </Container>
      {error && (
        <Error>
          <FiAlertCircle color="#c53030" size={20} />
          {error}
        </Error>
      )}
    </>
  );
};

export default InputDate;
