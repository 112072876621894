import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f7f7f7;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);

  div.image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .image-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    .image-content-left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      padding: 10px;
    }

    .image-content-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: 10px;
    }
  }

  div.content-area {
    h2 {
      color: #a19e9e;
      font-size: 14px;
      font-family: 'sofia_probold', serif;
      padding-top: 10px;
      text-transform: uppercase;
    }

    span {
      color: #a8a5a5;
      font-size: 14px;
      font-family: 'sofia_proregular', serif;
      padding-top: 10px;
    }
  }

  & > a {
    display: block;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    & > a {
      display: flex;
    }

    div.image-area {
      width: 240px;
    }

    div.content-area {
      padding: 10px 20px;
    }

    &:hover {
      background-color: #eee;
    }
  }
`;
