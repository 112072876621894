import React, { ReactNode, useCallback } from 'react';
import { pt } from 'date-fns/locale';
import { format, zonedTimeToUtc } from 'date-fns-tz';

// import { Container } from './styles';

interface Props {
  children: ReactNode;
  formatString?: string;
}

const DateString: React.FC<Props> = ({
  children,
  formatString = "dd'/'MM'/'yyyy 'ÀS' HH'H'mm",
}: Props) => {
  const convertDate = useCallback(
    date => {
      const nd =
        date instanceof Date ? date : zonedTimeToUtc(date, 'America/Sao_Paulo');

      return format(zonedTimeToUtc(nd, 'America/Sao_Paulo'), formatString, {
        locale: pt,
      });
    },
    [formatString],
  );

  return <>{convertDate(children)}</>;
};

export default DateString;
