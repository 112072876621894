import React, { useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import api from '../../../services/api';
import { Container } from './styles';
import PageError from '../../../components/PageError';
import InfinityLoading from '../../../components/InfinityLoading';
import Friend from './Friend';
import { useAuth } from '../../../hooks/auth';
import Restricted from '../../../components/Restricted';

const Friends: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { selectedClassroom, user } = useAuth();

  const [data, setData] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = useCallback(
    async (page: number) => {
      try {
        const response = await api.get(
          `/users/friends?page=${page}${
            selectedClassroom ? `&classroom_id=${selectedClassroom}` : ``
          }`,
        );
        // const { items, pages } = response.data;
        setHasMore(false);
        setData(response.data);
        // setHasMore(page < pages);
        // setData(d => [
        //   ...d,
        //   ...items.filter((item: any) => {
        //     const exists = d.findIndex(dd => dd.id === item.id);

        //     return exists === -1;
        //   }),
        // ]);
        // setLoading(false);
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message ||
            'Não foi posseivel carreegar o conteúdo!',
        );
      }
    },
    [selectedClassroom],
  );

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (!user?.classroom?.full_access) {
    return <Restricted />;
  }

  return (
    <Container>
      {user.classroom.full_access.toString()}
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<InfinityLoading key="loading" />}
        // endMessage={<b>Yay! You have seen it all</b>}
      >
        {data &&
          data.map((item: any) => (
            <Friend
              key={item.id}
              info={item}
              showChat={user.classroom_id === item.classroom_id}
            />
          ))}
      </InfiniteScroll>
    </Container>
  );
};

export default Friends;
