export default {
  search_filters: [
    {
      label: 'Título',
      key: 'title',
      type: 'string',
    },
    {
      label: 'Turma',
      key: 'classroom_id',
      type: 'api',
      api_url: '/classrooms',
      api_result_key: 'id',
      api_result_label: 'name',
    },
    {
      label: 'Status',
      key: 'status',
      type: 'array',
      values: [
        { key: 'published', label: 'Ativo' },
        { key: 'draft', label: 'Inativo' },
      ],
    },
  ],
};
