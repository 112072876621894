import React from 'react';

import { Container } from './styles';

interface Props {
  item: any;
  enabled?: boolean;
}

const Stamp: React.FC<Props> = ({ item, enabled }: Props) => {
  return (
    <Container enabled={enabled}>
      <img src={item.image} alt={item.name} />
    </Container>
  );
};

export default Stamp;
