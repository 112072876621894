import styled, { css } from 'styled-components';

interface ContainerPropos {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
  readOnly?: boolean;
}

export const Container = styled.div<ContainerPropos>`
  color: #CBC8C7;
  background: #FEFAF9;
  border-radius: 10px;
  border: 1px solid #9596FB;
  padding: 10px;
  width: 100%;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isFocused &&
    css`
      color: #9596fb;
      border-color: #9596fb;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #9596fb;
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

    ${props =>
      props.readOnly &&
      css`
        background-color: #ccc;
      `}

  input {
    color: #666;
    background: transparent;
    flex: 1;
    border: 0;

    &::placeholder {
      color: #666360;
    }

    &:read-only {
      background-color: #CCC;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

Container.defaultProps = {
  isFocused: false,
  isFilled: false,
  isErrored: false,
  readOnly: false,
};

export const Error = styled.div`
  display: flex;
  height: 20px;
  margin: 10px;
  font-size: 12px;
  align-items: center;
  min-height: 20px;
  svg {
    margin: 0 10px 0 0;
  }
`;

interface LabelProps {
  isErrored?: boolean;
}

export const Label = styled.span<LabelProps>`
  display: block;
  padding-top: 20px;
  padding-bottom: 5px;
  color: #9596fb;
  text-align: left;

  ${({ isErrored }) =>
    isErrored &&
    css`
      color: #c53030;
    `}
`;

Label.defaultProps = {
  isErrored: false,
};
