import styled, { css } from 'styled-components';

interface ContainerPropos {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const ImgArea = styled.div`
  display: block;
  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
`;

export const Loading = styled.div`
  display: block;
  font-size: 16px;
  color: '#333';
`;

export const Container = styled.div<ContainerPropos>`
  color: #CBC8C7;
  background: #FEFAF9;
  border-radius: 10px;
  border: 1px solid #9596FB;
  padding: 10px;
  width: 100%;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isFocused &&
    css`
      color: #9596fb;
      border-color: #9596fb;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #9596fb;
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  input {
    color: #666;
    background: transparent;
    flex: 1;
    border: 0;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled.div`
  display: flex;
  height: 20px;
  margin: 10px;
  font-size: 12px;
  align-items: center;
  min-height: 20px;
  svg {
    margin: 0 10px 0 0;
  }
`;

export const Label = styled.span`
  display: block;
  padding-top: 20px;
  padding-bottom: 5px;
  color: #9596fb;
`;
