import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import ReactGA, { EventArgs } from 'react-ga';
import AppConfig from '../configs/app';

const { analytics_id, is_production } = AppConfig;

interface AnalyticsContextData {
  pageview(path: string): void;
  event(args: EventArgs): void;
}

export const AnalyticsContext = createContext<AnalyticsContextData>(
  {} as AnalyticsContextData,
);

interface AnalyticsProviderProps {
  children?: ReactNode;
}

export const AnalyticsProvider: React.FC = ({
  children,
}: AnalyticsProviderProps) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!is_production) {
      return;
    }
    ReactGA.initialize(analytics_id);
    ReactGA.pageview('/');

    setInitialized(true);
  }, []);

  const pageview = useCallback(
    (path: string) => {
      if (!initialized) {
        return;
      }

      ReactGA.pageview(path);
    },
    [initialized],
  );

  const event = useCallback(
    (args: EventArgs) => {
      if (!initialized) {
        return;
      }
      ReactGA.event(args);
    },
    [initialized],
  );

  return (
    <AnalyticsContext.Provider value={{ pageview, event }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useAnalytics(): AnalyticsContextData {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be user with in an AnalyticsProvider');
  }

  return context;
}
