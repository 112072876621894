import React, { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import * as bodySL from 'body-scroll-lock';

import { Container, Contents } from './styles';
import Contact from './Contact';
import ChatUser from '../ChatUser';
import { useChat } from '..';

interface Props {
  contacts?: any[];
  closeChat(): void;
  messages: any[];
  sendMessage(message: string): void;
}

function getTimeStamp(date: string): any {
  const d = new Date(date);
  return d.getTime();
}

function sortByDate(contacts?: any[]): any {
  if (!contacts) {
    return [];
  }

  // const c = contacts.map(cc => {
  //   return { ...cc, sort_value: getTimeStamp(cc.last_message_date) };
  // });

  // console.log('c', contacts);

  return contacts.sort((a: any, b: any) => {
    return (
      getTimeStamp(b.last_message_date) - getTimeStamp(a.last_message_date)
    );
  });
}

const ChatBox: React.FC<Props> = ({
  contacts,
  closeChat,
  messages,
  sendMessage,
}: Props) => {
  const { openContact, openedContact } = useChat();

  // const nContacts = sortByDate(contacts);

  // const orderedContacts = sortByDate(contacts);
  const [orderedContacts, setOrderedContacts] = useState<any>([]);

  useEffect(() => {
    const windowWidth = window.innerWidth || 1024;
    // console.log('document.body', window.innerWidth);

    if (windowWidth < 768) {
      bodySL.disableBodyScroll(document.body);
      // lock
      // document.body.style.overflow = 'hidden';
    }

    return () => {
      if (windowWidth < 768) {
        bodySL.enableBodyScroll(document.body);
        // unlock
        // document.body.style.overflow = 'unset';
      }
    };
  }, []);

  useEffect(() => {
    if (contacts) {
      console.log('contacts', contacts);
      setOrderedContacts(sortByDate(contacts));
    }
  }, [contacts]);

  // const [ortedContacts] = useReducer(sortByDate, contacts);

  return (
    <Container>
      <header>
        <h3>Contatos</h3>
        <button type="button" onClick={closeChat}>
          <FiX size={20} color="#18ECBD" />
        </button>
      </header>
      <Contents>
        {orderedContacts &&
          orderedContacts.map((contact: any) => (
            <Contact
              key={contact.id}
              data={contact}
              onClick={() => {
                openContact(contact);
              }}
            />
          ))}

        {openedContact && (
          <ChatUser messages={messages} sendMessage={sendMessage} />
        )}
      </Contents>
    </Container>
  );
};

export default ChatBox;
