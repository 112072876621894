import React, { useState, useCallback } from 'react';
import Lightbox from 'react-image-lightbox';

import { Container, Content, ButtonAnswer, FormArea, Parent } from './styles';
import ResponsiveImage from '../../../../components/ResponsiveImage';
import DateString from '../../../../components/DateString';

import ForumForm from '../ForumForm';
import appConfig from '../../../../configs/app';

interface Props {
  item: any;
  onCreateNew?(comment: any): void;
}

const ForumComment: React.FC<Props> = ({ item, onCreateNew }: Props) => {
  const [showForm, setShowForm] = useState(false);

  const [images, setImages] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleAddNewComment = useCallback(
    comment => {
      if (onCreateNew) {
        onCreateNew(comment);
      }

      setShowForm(false);
    },
    [onCreateNew],
  );

  const handleOpenImages = useCallback((commentImages: any, index: number) => {
    const imgs = commentImages
      .split(',')
      .map((img: any) => `${appConfig.imageUrl}/${img}`);
    setPhotoIndex(index);
    setImages(imgs);
    setIsOpen(true);
  }, []);

  return (
    <Container>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          reactModalStyle={{ zIndex: 4000 }}
        />
      )}
      {item.parent && (
        <Parent>
          <div>
            <ResponsiveImage
              src={item.parent.user.avatar}
              proportion="1:1"
              defaultType="user"
            />
          </div>
          <div>
            <h2>{item.parent.user.name}</h2>
            <h3>{`${item.user.company?.name} - ${item.parent.user.company_position}`}</h3>
            <span>
              <DateString>{item.parent.created_at}</DateString>
            </span>
            <p>{item.parent.comment}</p>

            <div className="images">
              {!!item.parent.images &&
                item.parent.images
                  .split(',')
                  .map((image: string, index: number) => (
                    <button
                      type="button"
                      key={image}
                      onClick={() => {
                        handleOpenImages(item.parent.images, index);
                      }}
                    >
                      <img src={`${appConfig.imageUrl}/${image}`} alt="i" />
                    </button>
                  ))}
            </div>
          </div>
        </Parent>
      )}
      <Content>
        <div>
          <ResponsiveImage
            src={item.user.avatar}
            proportion="1:1"
            defaultType="user"
          />
        </div>
        <div>
          <h2>{item.user.name}</h2>
          <h3>{`${item.user.company?.name} - ${item.user.company_position}`}</h3>
          <span>
            <DateString>{item.created_at}</DateString>
          </span>
          <p>{item.comment}</p>

          <div className="images">
            {!!item.images &&
              item.images.split(',').map((image: string, index: number) => (
                <button
                  key={image}
                  type="button"
                  onClick={() => {
                    handleOpenImages(item.images, index);
                  }}
                >
                  <img src={`${appConfig.imageUrl}/${image}`} alt="i" />
                </button>
              ))}
          </div>

          {showForm && (
            <FormArea>
              <ForumForm
                onCreateNew={handleAddNewComment}
                answer
                parent_id={item.id}
              />
            </FormArea>
          )}
          {!showForm && (
            <ButtonAnswer onClick={() => setShowForm(true)}>
              Responder
            </ButtonAnswer>
          )}
        </div>
      </Content>
    </Container>
  );
};

export default ForumComment;
