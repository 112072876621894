import React, { createContext, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';
import ReactLoading from 'react-loading';

import { api, apiError, ApiError } from '../../services/api';
import PageLoading from '../../components/PageLoading';
import PageError from '../../components/PageError';

interface ProviderProps {
  children: ReactNode;
}

interface ContextProps {
  ALLOW_SUBSCRIPTION: boolean;
  ALLOW_PUBLIC_REGISTER: boolean;
  ALLOW_PUBLIC_REGISTER_TRIAL: boolean;
  ALLOW_NEW_FEATURES: boolean;
  IUGU_ACCOUNT_ID: string;
  ENV: string;
}

interface RemoteConfigs {
  ALLOW_SUBSCRIPTION: boolean;
  ALLOW_PUBLIC_REGISTER: boolean;
  ALLOW_PUBLIC_REGISTER_TRIAL: boolean;
  ALLOW_NEW_FEATURES: boolean;
  IUGU_ACCOUNT_ID: string;
  ENV: string;
}

const RemoteConfigsContext = createContext<ContextProps>({} as ContextProps);

export const RemoteConfigsProvider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const remote_configs = useQuery<any, ApiError, RemoteConfigs, any>(
    ['/api/configs'],
    async ({ queryKey }) => {
      const [url] = queryKey;
      const { data } = await api.get(url);

      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (remote_configs.isError) {
    return <PageError message={apiError(remote_configs.error)} />;
  }

  if (remote_configs.isLoading) {
    return <PageLoading />;
  }

  if (!remote_configs.data) {
    return null;
  }

  return (
    <RemoteConfigsContext.Provider
      value={{
        ...remote_configs.data,
      }}
    >
      {children}
    </RemoteConfigsContext.Provider>
  );
};

export const useRemoteConfigs = () => {
  const context = useContext(RemoteConfigsContext);

  if (!context) {
    throw new Error('useAuth must be user with in an RemoteConfigsContext');
  }

  return context;
};
