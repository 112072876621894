export default {
  search_filters: {
    name: {
      label: 'Nome',
      type: 'string',
    },
    email: {
      label: 'E-mail',
      type: 'string',
    },
    classroom_id: {
      label: 'Turma',
      type: 'api',
      api_url: '/classrooms',
      api_value: 'id',
      api_label: 'name',
      values: [],
    },
    company_id: {
      label: 'Empresa',
      type: 'api',
      api_url: '/companies',
      api_value: 'id',
      api_label: 'name',
      values: [],
    },
    is_admin: {
      label: 'Admin',
      type: 'array',
      values: [
        { value: 'true', label: 'Sim' },
        { value: 'false', label: 'Não' },
      ],
    },
    status: {
      label: 'Status',
      type: 'array',
      values: [
        { value: 'active', label: 'Ativo' },
        { value: 'draft', label: 'Inativo' },
      ],
    },
  },

  /* search_filters2: [
    {
      label: 'Nome',
      key: 'name',
      type: 'string',
    },
    {
      label: 'E-mail',
      key: 'email',
      type: 'string',
    },
    {
      label: 'Turma',
      key: 'classroom_id',
      type: 'api',
      api_url: '/classrooms',
      api_result_value: 'id',
      api_result_label: 'name',
      values: [],
    },
    {
      label: 'Empresa',
      key: 'company_id',
      type: 'api',
      api_url: '/companies',
      api_result_value: 'id',
      api_result_label: 'name',
      values: [],
    },
    {
      label: 'Admin',
      key: 'is_admin',
      type: 'array',
      values: [
        { value: 'true', label: 'Sim' },
        { value: 'false', label: 'Não' },
      ],
    },
    {
      label: 'Status',
      key: 'status',
      type: 'array',
      values: [
        { value: 'active', label: 'Ativo' },
        { value: 'draft', label: 'Inativo' },
      ],
    },
  ], */
};
