import styled, { css } from 'styled-components';

export const LoadingBox = styled.div`
  display: flex;
  flex: 1;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
`;

export const Attachments = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: inherit;
    padding: 2px 10px;
    p {
      padding-left: 8px;
    }
  }
`;

export const Informations = styled.div`
  tr,
  td,
  th,
  table {
    border: 1px solid #ccc;
  }

  table {
    width: 100%;
    max-width: 500px;
  }

  td,
  th {
    padding: 5px;
  }

  th {
    text-align: right;
  }
`;

export const Container = styled.div`
  padding: 20px;

  .button-add-question {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: inherit;
    border: 0;
    padding: 5px 10px;

    padding: 20px;
    background-color: #f7f7f7;
    margin-bottom: 10px;
    border-radius: 10px;
    font-family: 'sofia_probold', serif;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

export const Title = styled.h1`
  color: #838180;
  font-family: 'sofia_proregular', serif;
`;

interface QuestionProps {
  editable?: boolean;
}

export const Question = styled.div<QuestionProps>`
  padding: 20px;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 10px;

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #a19e9e;
    padding-bottom: 10px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      button {
        display: none;
        background: none;
        border: 0;
      }
    }

    ${props =>
      props.editable &&
      css`
        &:hover {
          background-color: #eee;
        }

        &:hover > div button {
          display: block;
        }
      `}
  }
`;
