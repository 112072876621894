import React, { useState, useCallback, useEffect } from 'react';
import ReactLoading from 'react-loading';
import api from '../../../services/api';
import { Container, Button } from './styles';
import PageLoading from '../../../components/PageLoading';
import PageError from '../../../components/PageError';
import { Alert } from '../../../utils/alerts';

const Contact: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [loadingForm, setLoadingForm] = useState(false);
  const [errorForm, setErrorForm] = useState('');

  const [data, setData] = useState<any>({
    name: '',
    email: '',
    company_name: '',
    company_position: '',
    classroom_name: '',
    message: '',
  });

  const load = useCallback(async () => {
    try {
      const res = await api.get('/users/self');
      setData({
        name: res.data.name,
        email: res.data.email,
        company_name: res.data.company?.name,
        company_position: res.data.company_position,
        classroom_name: res.data.classroom?.name,
        message: '',
      });
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'Houve um erro ao carregar o conteúdo!',
      );
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;
    setData((oldData: any) => {
      const newData: any = { ...oldData };
      newData[name] = value;
      return newData;
    });
  }, []);

  const handleForm = useCallback(
    async event => {
      if (!data.message || data.message.length < 6) {
        Alert('Favor preencher a mensagem corretamente!');
        return;
      }

      setLoadingForm(true);

      try {
        await api.post('/contact', {
          message: data.message,
        });

        setData((d: any) => {
          return { ...d, message: '' };
        });
        setLoadingForm(false);

        Alert('Sua mensagem foi enviada com sucesso!');
      } catch (error) {
        setErrorForm(
          error.response?.data?.message ||
            'Houve um erro ao carregar o conteúdo! Tente novamente mais tarde!',
        );
        setLoadingForm(false);
      }
    },
    [data],
  );

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <header>
        <h1>Contato</h1>
      </header>
      <div>
        <form action="">
          <div>
            <span>Nome</span>
            <input
              type="text"
              name="name"
              value={data.name}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>E-mail</span>
            <input
              type="email"
              name="e-mail"
              value={data.email}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Empresa</span>
            <input
              type="text"
              name="company_name"
              value={data.company_name}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Cargo</span>
            <input
              type="text"
              name="company_position"
              value={data.company_position}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Turma</span>
            <input
              type="text"
              name="classroom_name"
              value={data.classroom_name}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Mensagem</span>
            <textarea
              name="message"
              value={data.message}
              onChange={handleChange}
            />
          </div>

          <div>
            {loadingForm ? (
              <Button type="button">
                <ReactLoading
                  type="spin"
                  color="#16B77E"
                  height={25}
                  width={25}
                />
              </Button>
            ) : (
              <Button type="button" onClick={handleForm}>
                Enviar
              </Button>
            )}

            {errorForm && <p className="error-message">{errorForm}</p>}
          </div>
        </form>
      </div>
    </Container>
  );
};

export default Contact;
