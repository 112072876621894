import React, { useState, useCallback, useRef } from 'react';
import ReactLoading from 'react-loading';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import { uuid } from 'uuidv4';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import appConfig from '../../../../configs/app';

import {
  Container,
  ButtonComment,
  FormArea,
  ButtonSubmit,
  Images,
} from './styles';

interface Props {
  onCreateNew?(comment: any): void;
  answer?: boolean;
  parent_id?: string;
}

const initImages = [
  {
    id: uuid(),
    filename: '365b548c852d8997b335-img_avatar0.png',
  },
  // 'https://images.freeimages.com/images/large-previews/7a9/bank-1379227.jpg',
  // 'https://images.freeimages.com/images/large-previews/7a9/bank-1379227.jpg',
  // 'https://images.freeimages.com/images/large-previews/7a9/bank-1379227.jpg',
  // 'https://images.freeimages.com/images/large-previews/7a9/bank-1379227.jpg',
  // 'https://images.freeimages.com/images/large-previews/7a9/bank-1379227.jpg',
  // 'https://images.freeimages.com/images/large-previews/7a9/bank-1379227.jpg',
];

const ForumForm: React.FC<Props> = ({
  onCreateNew,
  answer = false,
  parent_id,
}: Props) => {
  const { selectedClassroom, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [enabled, setEnabled] = useState(answer);
  const [comment, setComment] = useState('');

  const fileInputRef = useRef<any>();

  const [images, setImages] = useState<any[]>([]);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  const handleSubmitComment = useCallback(async () => {
    try {
      const allImages = images.map(img => img.filename).join(',');

      const { data } = parent_id
        ? await api.post(`/forums`, {
            comment,
            parent_id,
            classroom_id: selectedClassroom,
            images: allImages,
          })
        : await api.post(`/forums`, {
            comment,
            classroom_id: selectedClassroom,
            images: allImages,
          });
      setLoading(false);

      setComment('');

      if (!answer) {
        setEnabled(false);
      }

      if (onCreateNew) {
        onCreateNew(data);
      }
    } catch ({ response }) {
      setLoading(false);
      setErrorMessage(
        response.data.message ||
          'Houve um erro no envio. Tente novamente mais tarde!',
      );
    }
  }, [answer, comment, images, onCreateNew, parent_id, selectedClassroom]);

  const handleAddImage = useCallback(() => {
    if (loadingImage) {
      return;
    }

    fileInputRef.current.click();
    // console.log('fileRef', fileInputRef);
  }, [loadingImage]);

  const handleRemoveImage = useCallback(imageId => {
    setImages((state: any) =>
      state.filter((image: any) => image.id !== imageId),
    );
  }, []);

  const handleChangeImage = useCallback(async (event: any) => {
    const [file] = event.target.files;
    const formData = new FormData();
    formData.append('file', file);

    // const reader = new FileReader();
    // reader.onload = (e: any) => {
    //   setImages((state: any) => [e.target.result, ...state]);
    // };

    // reader.readAsDataURL(event.target.files[0]);
    setLoadingImage(true);
    try {
      const result = await api.post('/files', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      const { filename } = result.data;
      setImages((state: any) => [
        {
          id: uuid(),
          filename,
        },
        ...state,
      ]);
      // console.log('filename', filename);
    } catch ({ response }) {
      setErrorMessage(
        response.data.message ||
          'Houve um erro no envio. Tente novamente mais tarde!',
      );
    }
    setLoadingImage(false);
  }, []);

  return (
    <Container>
      {!answer && !enabled && (
        <ButtonComment onClick={() => setEnabled(true)}>Comentar</ButtonComment>
      )}
      {enabled && (
        <FormArea>
          <h2>Escreva o seu comentário:</h2>

          <textarea
            name="comment"
            value={comment}
            onChange={event =>
              setComment(
                event.target.value.length <= 500
                  ? event.target.value
                  : event.target.value.substr(0, 500),
              )
            }
            readOnly={loading}
          />
          <div className={comment.length > 500 ? 'over' : 'ok'}>
            {`${comment.length}`}
            /500
          </div>

          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}

          <input
            ref={fileInputRef}
            type="file"
            name="fileInput"
            onChange={handleChangeImage}
            style={{ opacity: 0, width: 0, height: 0 }}
            accept="image/jpg,image/jpeg,image/png"
          />

          {user && user.is_admin && (
            <Images.List>
              <Images.Item>
                <button
                  type="button"
                  className="add-new"
                  onClick={handleAddImage}
                >
                  {loadingImage ? (
                    <ReactLoading
                      type="spin"
                      color="#16B77E"
                      height={50}
                      width={50}
                    />
                  ) : (
                    <FiPlus color="#000" size={20} />
                  )}
                </button>
              </Images.Item>
              {images.map(image => (
                <Images.Item key={image.id}>
                  <button
                    type="button"
                    className="image-button"
                    onClick={() => handleRemoveImage(image.id)}
                  >
                    <img
                      src={`${appConfig.imageUrl}/${image.filename}`}
                      alt="one"
                    />
                    <span className="icon-close">
                      <FiXCircle color="#F00" size={40} />
                    </span>
                  </button>
                </Images.Item>
              ))}
            </Images.List>
          )}

          <ButtonSubmit onClick={handleSubmitComment}>
            {loading ? (
              <ReactLoading type="spin" color="#FFF" height={20} width={20} />
            ) : (
              <span>Enviar</span>
            )}
          </ButtonSubmit>
        </FormArea>
      )}
    </Container>
  );
};

export default ForumForm;
