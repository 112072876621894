import { useFormikContext } from 'formik';
import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

interface Props {
  label: string;
}

const SubmitButton: React.FC<Props> = ({ label }: Props) => {
  const { isSubmitting, submitForm } = useFormikContext();

  return (
    <Container type="button" disabled={isSubmitting} onClick={submitForm}>
      {isSubmitting ? (
        <ReactLoading type="spin" color="#FFF" width={20} height={20} />
      ) : (
        label
      )}
    </Container>
  );
};

export default SubmitButton;
