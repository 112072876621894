import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import api from '../services/api';
import storage from '../services/storage';
import appConfig from '../configs/app';

interface SignInCredentials {
  email: string;
  password: string;
}

interface SetSignInData {
  user: any;
  token: string;
}

interface AuthContextData {
  user: any;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateAvatar(avatar: string): void;
  selectedClassroom: string | undefined;
  selectClassroom(clasroomId: string): void;
  setSignInData(data: SetSignInData): void;
  loaded: boolean;
  info: any;
  updateUserData(user: any): void;
}

interface AuthResponseData {
  name: string;
  token: string;
}

interface AuthState {
  token: string;
  user: any;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

interface AuthProviderProps {
  children?: ReactNode;
}

declare let window: any;

declare let OneSignal: any;

export const AuthProvider: React.FC = ({ children }: AuthProviderProps) => {
  const [selectedClassroom, setSelectedClassroom] = useState<
    string | undefined
  >(() => {
    return storage.getItem('selected_classroom');
  });

  const [loaded, setLoaded] = useState<boolean>(false);

  const [data, setData] = useState<AuthState>({} as AuthState);

  const [info, setInfo] = useState<any>();

  /* const [data, setData] = useState<AuthState>(
    (): AuthState => {
      const token = storage.getItem('token');
      const user = storage.getItem('user');

      if (token && user) {
        return { token, user: JSON.parse(user) };
      }

      return {} as AuthState;
    },
  ); */

  // Load Initial Data
  useEffect(() => {
    const loadInit = async () => {
      try {
        const { data: data_info } = await api.get('/api/configs');
        setInfo(data_info);
      } catch (error) {
        return;
      }

      const token = storage.getItem('token');
      const user = storage.getItem('user');

      if (token && user) {
        // return { token, user: JSON.parse(user) };

        api.defaults.headers.Authorization = `Baerer ${token}`;

        try {
          const { data: user_updated } = await api.get('/users/self');

          setData({
            token,
            user: user_updated || JSON.parse(user),
          });
        } catch (error) {
          setData({
            token,
            user: JSON.parse(user),
          });
        }
      }
      setLoaded(true);
    };

    loadInit();
  }, []);

  useEffect(() => {
    if (!data.user || !appConfig.is_production) {
      return;
    }

    if (!appConfig.ONESIGNAL_ENABLED) {
      return;
    }

    OneSignal.push(() => {
      OneSignal.init({
        appId: appConfig.ONESIGNAL_APPID,
        safari_web_id: appConfig.ONESIGNAL_SAFARI_WEB_ID,
        // notifyButton: {
        //   enable: true,
        // },
      });
    });

    OneSignal.push(() => {
      OneSignal.sendTags({
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        classroom_id: data.user.classroom_id,
        company_id: data.user.company_id,
      });
    });
  }, [data]);

  const setSignInData = useCallback(({ user, token }: SetSignInData) => {
    storage.setItem('token', token);
    storage.setItem('user', user);

    api.defaults.headers.Authorization = `Baerer ${token}`;

    setData({
      user,
      token,
    });
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Baerer ${token}`;

    storage.setItem('token', token);
    storage.setItem('user', user);

    setData({ token, user });
  }, []);

  const updateUserData = useCallback((dt: any) => {
    setData((d: any) => {
      const nuser = {
        ...d.user,
        ...dt,
      };

      storage.setItem('user', nuser);

      return {
        ...d,
        user: nuser,
      };
    });
  }, []);

  const signOut = useCallback(() => {
    storage.removeItem('token');
    storage.removeItem('user');

    api.defaults.headers.Authorization = null;

    setData({} as AuthState);
  }, []);

  const updateAvatar = useCallback(async (avatar: string): Promise<void> => {
    if (avatar) {
      const user = storage.getItem('user');
      const updatedUser = { ...JSON.parse(user), avatar };
      storage.setItem('user', JSON.stringify(updatedUser));
      setData((allData: any) => {
        return { ...allData, user: { ...allData.user, avatar } };
      });
    }
  }, []);

  const selectClassroom = useCallback((classroomId: string) => {
    storage.setItem('selected_classroom', classroomId);
    setSelectedClassroom(classroomId);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        updateAvatar,
        selectedClassroom,
        selectClassroom,
        setSignInData,
        loaded,
        info,
        updateUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be user with in an AuthProvider');
  }

  return context;
}
