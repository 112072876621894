import React from 'react';
import { FiBookOpen, FiCheckCircle } from 'react-icons/fi';

import { Container, Image } from './styles';
import ResponsiveImage from '../../../../components/ResponsiveImage';
import DateString from '../../../../components/DateString';

interface LiveClassProps {
  data: any;
  type: 'live' | 'class';
  onPress?: any;
}

const LiveClass: React.FC<LiveClassProps> = ({
  data,
  type,
  onPress,
}: LiveClassProps) => {
  return (
    <Container onClick={onPress}>
      <Image>
        <ResponsiveImage src={data.thumbnail} />
        <div className="image-bar">
          <div>
            {type === 'live' && data.status === 'live' && (
              <span className={data.status}>AO VIVO</span>
            )}
            {type === 'live' && data.status === 'scheduled' && (
              <span className={data.status}>Agendado</span>
            )}

            {type === 'class' && data.has_avaliation && (
              <span className="icon">
                <FiBookOpen
                  size={20}
                  color={data.avaliation_done ? '#17E29A' : '#FFF'}
                />
              </span>
            )}
          </div>
          <div>
            {type === 'class' && (
              <span className="icon">
                <FiCheckCircle
                  size={20}
                  color={data.read ? '#17E29A' : '#FFF'}
                />
              </span>
            )}
          </div>
        </div>
      </Image>

      <h2>{data.name}</h2>
      <span>
        <DateString>{data.date}</DateString>
      </span>
    </Container>
  );
};

export default LiveClass;
