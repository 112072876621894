import React, { useState, useCallback } from 'react';
import { FiEdit, FiXCircle, FiInfo, FiBarChart } from 'react-icons/fi';
// import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScroll from 'react-infinite-scroller';
import FormClass from './FormClass';
import api from '../../../services/api';
import { Alert, Confirm } from '../../../utils/alerts';

import ResponsiveImage from '../../../components/ResponsiveImage';
import getClassStatuses from '../../../utils/getClassStatuses';
import DateString from '../../../components/DateString';

import {
  Title,
  Table,
  ActionButton,
  ActionLink,
  AddButton,
  AButton,
} from '../../../components/AdminComponents';

import { Container } from './styles';
import Modal from '../../../components/Modal';

const statuses: any = getClassStatuses();

const Classrooms: React.FC = () => {
  const [modalId, setModalId] = useState(null);

  const [data, setData] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const loadData = useCallback(async (page: number = 1) => {
    try {
      const response = await api.get(`/classes?page=${page}`);
      const { items, pages } = response.data;
      setHasMore(page < pages);
      setData(d => [
        ...d,
        ...items.filter((item: any) => {
          const exists = d.findIndex(dd => dd.id === item.id);

          return exists === -1;
        }),
      ]);
    } catch (error) {
      console.log('error', error.response);
    }
  }, []);

  const handleAddItem = useCallback(async id => {
    setModalId(id);
  }, []);

  const handleDeleteItem = useCallback(async id => {
    const ok = await Confirm('Tem certeza que deseja remover este item?');
    if (ok) {
      await api.delete(`/classes/${id}`);
      setData(d => d.filter((dd: any) => dd.id !== id));
      Alert('Item deletado com sucesso!');
    }
  }, []);

  const onFormSuccess = useCallback(item => {
    setModalId(null);

    setData(d => {
      const index = d.findIndex(i => i.id === item.id);

      if (index !== -1) {
        return d.map(i => (i.id === item.id ? item : i));
      }

      return [item, ...d];
    });
  }, []);

  return (
    <Container>
      <Title>Aulas</Title>
      <AddButton
        onClick={() => {
          handleAddItem('new');
        }}
      >
        Adicionar
      </AddButton>

      {modalId && (
        <Modal
          setModalId={setModalId}
          title={modalId === 'new' ? 'Nova Aula' : 'Editar Aula'}
        >
          <FormClass onFormSuccess={onFormSuccess} itemId={modalId} />
        </Modal>
      )}

      <InfiniteScroll
        pageStart={0}
        loadMore={loadData}
        hasMore={hasMore}
        loader={<h4 key="loading">Carregando...</h4>}
        // endMessage={<b>Yay! You have seen it all</b>}
      >
        <Table>
          <table>
            <thead>
              <tr>
                <th>Capa</th>
                <th>Data</th>
                <th>Nome</th>
                <th>Status</th>
                <th>_</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id}>
                  <td className="images">
                    <ResponsiveImage src={item.thumbnail} proportion="16:9" />
                  </td>
                  <td>
                    <DateString>{item.created_at}</DateString>
                  </td>
                  <td>{`${item.name} (${item.classroom?.name})`}</td>

                  <td>{statuses[item.status]}</td>

                  <td className="actions">
                    {item.has_avaliation && (
                      <ActionLink
                        to={`/admin/relatorios/avaliacoes/${item.id}#${item.id}`}
                      >
                        <FiBarChart color="#17e29a" size={18} />
                      </ActionLink>
                    )}
                    <ActionLink to={`/admin/classes/${item.id}`}>
                      <FiInfo color="#000" size={18} />
                    </ActionLink>
                    <ActionButton
                      onClick={() => {
                        handleAddItem(item.id);
                      }}
                    >
                      <FiEdit color="#000" size={18} />
                    </ActionButton>

                    <ActionButton onClick={() => handleDeleteItem(item.id)}>
                      <FiXCircle color="#000" size={18} />
                    </ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      </InfiniteScroll>
    </Container>
  );
};

export default Classrooms;
