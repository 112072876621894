import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: red;
  background-color: #fff;
  z-index: 1002;

  /* border: 1px solid #ccc; */

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;

    /* outline: 1px solid red; */

    /**
        h5 {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
    }

    h4 {
      font-size: 1em;
      font-family: 'sofia_proregular';
      color: #838180;
    }

     */

    & > div {
      display: flex;
      align-items: center;
      flex: 1;

      & > div {
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
      }
      hgroup {
        flex: 1;
        padding: 10px;

        color: #838180;
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 1em;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          text-align: left;
          font-family: 'sofia_proregular';
          color: #838180;
        }
        h4 {
          font-size: 0.8em;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          text-align: left;
          font-family: 'sofia_proregular';
          color: #838180;
        }
      }
    }

    button {
      background: inherit;
      border: 0;
      padding: 10px;
    }
  }

  & > footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px;

    background-color: #ededed;

    input {
      flex: 1;
      padding: 6px 4px;
      border: 0;
      font-size: 0.9em;
      color: #838180;
      font-family: 'sofia_proregular';
    }

    button {
      display: block;
      border: 0;
      background-color: #16b77e;
      font-family: 'sofia_proregular';
      font-size: 0.9em;
      padding: 6px 8px;
      color: #fefaf9;
    }
  }
`;

export const Messages = styled.div`
  background-color: #fff;
  flex: 1;
  overflow-y: scroll;
`;

interface MessageProps {
  fromMe: boolean;
}

export const Message = styled.div<MessageProps>`
  background-color: #e4e0df;
  color: #838180;
  padding: 10px;
  margin: 20px;
  font-family: 'sofia_proregular', serif;
  font-size: 0.8em;

  border-radius: 0 10px 10px 10px;

  margin-right: 60px;

  ${props =>
    props.fromMe &&
    css`
      border-radius: 10px 0 10px 10px;
      background-color: #8ff0d1;
      margin-right: 20px;
      margin-left: 60px;
    `}
`;
