import React, { ReactNode } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMenu } from '../../../hooks/menu';

// import { Container } from './styles';

interface Props {
  to: string;
  activeOnlyWhenExact?: boolean;
  children: ReactNode;
}

const MenuLink: React.FC<Props> = ({
  to,
  activeOnlyWhenExact,
  children,
}: Props) => {
  const { close } = useMenu();

  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <Link to={to} className={match ? 'active' : ''} onClick={close}>
      {children}
    </Link>
  );
};

export default MenuLink;
