import React, { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { MenuProvider } from './menu';
import { ChatProvider } from './chat';
import { AnalyticsProvider } from './analytics';
import { RemoteConfigsProvider } from './remote-configs';
// import { ModalProvider } from './modal';

interface AppProviderProps {
  children?: ReactNode;
}

export const AppProvider: React.FC = ({ children }: AppProviderProps) => {
  // const handleTouchMove = useCallback((event: any) => {
  //   const myEvent = event.originalEvent || event;
  //   if (myEvent.scale !== 1) {
  //     myEvent.preventDefault();
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener('touchmove', handleTouchMove, { passive: false });

  //   return () => {
  //     document.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, [handleTouchMove]);

  return (
    <RemoteConfigsProvider>
      <AnalyticsProvider>
        <ToastProvider>
          <AuthProvider>
            <ChatProvider>
              <MenuProvider>{children}</MenuProvider>
            </ChatProvider>
          </AuthProvider>
        </ToastProvider>
      </AnalyticsProvider>
    </RemoteConfigsProvider>
  );
};
