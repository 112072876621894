import styled from 'styled-components';

export const Container = styled.div``;

export const PillMenu = styled.div`
  overflow-x: scroll;
  width: 100%;
`;

export const PillMenuContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  padding-right: 40px;
  a {
    color: #fefaf9;
    background-color: #cbc8c7;
    font-family: 'sofia_proregular', serif;
    font-size: 14px;
    padding: 8px 20px;
    margin: 10px;
    border-radius: 8px;
    text-decoration: none;
    min-width: 132px;
    display: inline-block;
    text-align: center;
  }

  a.active {
    background-color: #18e29a;
  }
`;
