import React, { useState, useEffect, useCallback } from 'react';
import ReactLoading from 'react-loading';
import { FiXCircle, FiPlus } from 'react-icons/fi';
import api from '../../services/api';

import {
  Container,
  Column,
  AddButton,
  SearchForm,
  ResultButton,
  AButton,
} from './styles';

interface ValueData {
  value: string;
  label: string;
}

interface SearchFilterData {
  label: string;
  key: string;
  type: string;
  api_url?: string;
  api_result_value?: string;
  api_result_label?: string;
  values?: ValueData[];
}

interface SearchFilters {
  [id: string]: SearchFilterData;
}

interface OptionsData {
  search_filters: any;
}

interface Props {
  handleAdd?(): void;
  handleSearch?(result: any): void;
  options: OptionsData;
  linkButtonTitle?: string;
  linkButtonLink?: string;
}

const AdminFilterBar: React.FC<Props> = ({
  handleAdd,
  handleSearch,
  options: { search_filters: filters },
  linkButtonTitle,
  linkButtonLink,
}: Props) => {
  const [loading, setLoading] = useState(false);

  // const [filters] = useState<any>(search_filters);

  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [filterValues, setFilterValues] = useState<any>({});
  const [selectedFilter, setSelectedFilter] = useState<
    SearchFilterData | undefined
  >();

  useEffect(() => {
    if (firstTime) {
      return;
    }
    const keys = Object.keys(filterValues);

    const uri_params = keys.reduce(
      (resultValue: string, currentKey: string) => {
        const v = `${currentKey}=${encodeURI(filterValues[currentKey].value)}`;
        return resultValue ? `${resultValue}&${v}` : `${v}`;
      },
      '',
    );

    if (handleSearch) {
      handleSearch(uri_params);
    }
  }, [filterValues, firstTime, handleSearch]);

  const handleChangeKey = useCallback(async (f_key: string, filter: any) => {
    // const currentFilter = filters.find(f => f.key === _key);

    // if (currentFilter) {
    //   if (
    //     currentFilter.type === 'api' &&
    //     currentFilter.api_url &&
    //     currentFilter.values?.length === 0
    //   ) {
    //     setLoading(true);
    //     api.get(currentFilter.api_url).then(({ data }) => {
    //       setSelectedFilter({
    //         ...currentFilter,
    //         values: data.map((d: any) => ({
    //           value: d[currentFilter?.api_result_value || ''],
    //           label: d[currentFilter?.api_result_label || ''],
    //         })),
    //       });
    //       setLoading(false);
    //     });
    //   } else {
    //     setSelectedFilter(currentFilter);
    //   }
    // }

    // const filter = filters[s_key];

    // setFilters((f: any) => {
    //   return { ...f };
    // });

    if (filter.type === 'api') {
      setLoading(true);
      api.get(filter.api_url).then(({ data }) => {
        setSelectedFilter({
          ...filter,
          values: data.map((d: any) => ({
            value: d[filter.api_value],
            label: d[filter.api_label],
          })),
        });
        setLoading(false);
      });
    } else {
      setSelectedFilter(filter);
    }

    setKey(f_key);
    setValue('');
  }, []);

  // const [allFilters, setAllFilters] = useState<any[]>(options.filters);

  // const loadValues = useCallback((filters: any[]) => {
  //   filters.forEach(async (f: any) => {
  //     if (f.type === 'api') {
  //       api.get(f.api_url).then(vl => {
  //         setAllFilters((state: any) => {
  //           return state.map((s: any) => {
  //             if (s.label === f.label) {
  //               return {
  //                 ...s,
  //                 values: vl.data.map((v: any) => ({
  //                   key: v[f.api_result_key],
  //                   label: v[f.api_result_label],
  //                 })),
  //               };
  //             }
  //             return s;
  //           });
  //         });
  //         console.log('vl', vl.data);
  //       });
  //     }

  //     console.log('ffff', f.label);
  //   });
  // }, []);

  // useEffect(() => {
  //   loadValues(options.filters);
  // }, [loadValues, options.filters]);

  useEffect(() => {
    const firstKey = Object.keys(filters)[0];
    setSelectedFilter(filters[firstKey]);
    setKey(firstKey);
  }, [filters]);

  /* const handleChangeKey = useCallback(
    async event => {
      if (!options?.search_filters) {
        return;
      }

      setSearchValue('');
      setSearchKey(event.target.value);
      setValues([]);
      setLoading(true);
      setExact(true);

      const selected_option_index = options.search_filters.findIndex(
        (op: any) => op.key === event.target.value,
      );

      if (selected_option_index !== -1) {
        const selected_option = options.search_filters[selected_option_index];
        setFilterType(selected_option.type);

        if (selected_option.type === 'string') {
          setLoading(false);
          setExact(false);
          return;
        }

        if (selected_option.type === 'array') {
          setValues(selected_option.values);
          setLoading(false);
          return;
        }

        if (selected_option.type === 'api') {
          try {
            const result = await api.get(selected_option.api_url);

            setValues(() =>
              result.data.map((item: any) => {
                return {
                  key: item[selected_option.api_result_key],
                  label: item[selected_option.api_result_label],
                };
              }),
            );
            setLoading(false);
          } catch (error) {
            setLoading(false);
            Alert(
              error.response.data.message || 'Não foi possível buscar os itens',
            );
          }
        }
      }
    },
    [options],
  ); */

  const handleAddFilter = useCallback(
    (filter: SearchFilterData | undefined, f_key, f_value: string) => {
      if (f_value) {
        const currentValue2 =
          filter?.values && filter.type !== 'string'
            ? filter.values.find(v => v.value === f_value)
            : { label: f_value };

        setFilterValues((state: any) => {
          return {
            ...state,
            [f_key]: {
              label: filter?.label,
              value: f_value,
              value_label: currentValue2?.label || 'not defined',
            },
          };
        });
        setValue('');
        setFirstTime(false);
      } else {
        setFilterValues((state: any) => {
          const nState = { ...state };

          if (nState[f_key]) {
            delete nState[f_key];
          }

          return {
            ...nState,
          };
        });
      }
    },
    [],
  );

  const handleRemoveFilter = useCallback(f_key => {
    setFilterValues((state: any) => {
      const nState = { ...state };

      if (nState[f_key]) {
        delete nState[f_key];
      }

      return {
        ...nState,
      };
    });
  }, []);

  return (
    <>
      <Container>
        <Column>
          <AddButton onClick={handleAdd}>Adicionar</AddButton>
        </Column>
        <Column>
          <SearchForm>
            {filters && (
              <select
                name="search_key"
                value={key}
                onChange={e =>
                  // eslint-disable-next-line
                  handleChangeKey(e.target.value, filters[e.target.value])}
              >
                {Object.keys(filters).map((k: string) => (
                  <option key={k} value={k}>
                    {filters[k].label}
                  </option>
                ))}
              </select>
            )}

            {selectedFilter && selectedFilter.type === 'string' && (
              <input
                className="input_value"
                type="text"
                name="search_value"
                placeholder="O que deseja buscar?"
                value={value}
                onChange={e => setValue(e.target.value)}
              />
            )}

            {loading && (
              <div className="loading-area">
                <ReactLoading
                  type="spin"
                  color="#16B77E"
                  height={20}
                  width={20}
                />
              </div>
            )}

            {!loading &&
              selectedFilter &&
              (selectedFilter.type === 'api' ||
                selectedFilter.type === 'array') && (
                <select
                  name="search_value"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                >
                  <option value="">Escolha uma opção</option>
                  {selectedFilter.values &&
                    selectedFilter.values.map(op => (
                      <option key={op.value} value={op.value}>
                        {op.label}
                      </option>
                    ))}
                </select>
              )}

            {/* {allFilters.map((f: any) => (
            <div key={f.label}>
              <p>{f.label}</p>
              <select>
                {' '}
                <option value="">Escolha uma opção</option>
                {f.values &&
                  f.values.map((op: any) => (
                    <option key={op.key} value={op.key}>
                      {op.label}
                    </option>
                  ))}
              </select>
            </div>
          ))} */}

            <button
              type="button"
              onClick={() => {
                handleAddFilter(selectedFilter, key, value);
              }}
            >
              <FiPlus size={20} color="#CCC" />
            </button>
          </SearchForm>
        </Column>
        {linkButtonLink && linkButtonTitle && (
          <Column>
            <AButton href={linkButtonLink}>{linkButtonTitle}</AButton>
          </Column>
        )}
      </Container>

      {Object.keys(filterValues).length > 0 && (
        <Container>
          {Object.keys(filterValues).map((f_key: string) => (
            <ResultButton key={f_key}>
              {`${filterValues[f_key].label}: ${filterValues[f_key].value_label}`}
              <button
                type="button"
                onClick={() => {
                  handleRemoveFilter(f_key);
                }}
              >
                <FiXCircle size={20} color="#000" />
              </button>
            </ResultButton>
          ))}
        </Container>
      )}
    </>
  );
};

export default AdminFilterBar;
