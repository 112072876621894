import React, { useState, useCallback, useEffect } from 'react';

import { isAfter, parseISO, addMinutes } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { Container, Row, Title, Classes } from './styles';
import LiveClass from './LiveClass';
import Classe from './Classe';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import PageError from '../../../components/PageError';
import DemoMessage from '../../../components/DemoMessage';

const Home: React.FC = () => {
  const { selectedClassroom, user, info } = useAuth();
  const hitory = useHistory();
  const [liveClasses, setLiveClasses] = useState<any[]>([]);
  const [classes, setClasses] = useState<any[]>([]);

  const loadData = useCallback(async () => {
    const lc = await api.get(
      `/live-classes/nexts${
        selectedClassroom ? `?classroom_id=${selectedClassroom}` : ``
      }`,
    );
    const llc = lc.data.map((item: any) => {
      return {
        ...item,
        status: 'scheduled',
      };
    });

    const responseClasses = await api.get(
      `/classes/latest${
        selectedClassroom ? `?classroom_id=${selectedClassroom}` : ``
      }`,
    );
    setClasses(responseClasses.data);

    setLiveClasses(llc);
  }, [selectedClassroom]);

  const refreshItems = useCallback(() => {
    const now = addMinutes(new Date(), 5);

    const hasToChangeStatus = liveClasses.find(
      (item, index) =>
        item.status === 'scheduled' && isAfter(now, parseISO(item.date)),
    );

    if (hasToChangeStatus) {
      setLiveClasses(items => {
        return items.map(item => {
          return {
            ...item,
            status: isAfter(now, parseISO(item.date)) ? 'live' : 'scheduled',
          };
        });
      });
    }
  }, [liveClasses]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    refreshItems();
    const interval = setInterval(() => {
      refreshItems();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshItems]);

  const openLive = useCallback(
    (live: any) => {
      hitory.push(`/dashboard/lives/${live.id}`);
    },
    [hitory],
  );

  const openClass = useCallback(
    (live: any) => {
      hitory.push(`/dashboard/turma/aula/${live.id}`);
    },
    [hitory],
  );

  if (info && info.ALLOW_PUBLIC_REGISTER && user && user.status === 'pending') {
    return (
      <PageError
        message="Processando pagamento. Aguarde..."
        refreshTime={10000}
      />
    );
  }

  if (
    info &&
    info.ALLOW_PUBLIC_REGISTER &&
    user &&
    user.status === 'canceled'
  ) {
    return (
      <PageError message="Pagamento cancelado! Verifique as informações em Minha Conta" />
    );
  }

  if (
    info &&
    info.ALLOW_PUBLIC_REGISTER &&
    user &&
    user.status === 'payment_failed'
  ) {
    return (
      <PageError message="Falha no pagamento! Verifique as informações em Minha Conta" />
    );
  }

  return (
    <Container>
      <DemoMessage />
      {user?.classroom?.full_access && (
        <Row>
          <Title>Próximas Aulas</Title>
          <Classes>
            {liveClasses.map(item => (
              <LiveClass
                type="live"
                key={item.id}
                data={item}
                onPress={() => {
                  openLive(item);
                }}
              />
            ))}
          </Classes>
        </Row>
      )}

      {classes && classes.length > 0 && (
        <Row>
          <Title>Últimas Aulas</Title>
          <Classes>
            {classes.map(item => (
              <Classe
                type="class"
                key={item.id}
                data={item}
                onPress={() => {
                  openClass(item);
                }}
              />
            ))}
          </Classes>
        </Row>
      )}
    </Container>
  );
};

export default Home;
