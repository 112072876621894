import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;

  header {
    display: flex;
    align-items: center;

    div:first-child {
      flex: 1;
      max-width: 100px;
      background-color: red;

      border-radius: 50%;
      overflow: hidden;
    }

    div:last-child {
      flex: 1;
    }
  }
`;
