import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Row = styled.section``;

export const Title = styled.h2`
  font-size: 42px;
  font-family: 'sofia_proregular', serif;
  color: #838180;
  margin: 0 20px;
`;
export const Classes = styled.div`
  display: block;
  /* justify-content: space-around; */
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    /* max-width: 100%; */
  }
`;

export const LiveClass = styled.div``;
