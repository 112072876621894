import React from 'react';
import styled from 'styled-components';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

export const Container = styled.div`
  padding: 20px;
  h2 {
  }

  table {
    /* border: 1px solid #ccc; */
    width: 100%;
    th {
      border: 1px solid #ccc;
      text-align: left;
      padding: 4px;
    }
    td {
      border: 1px solid #ccc;
      font-weight: 400;
      font-family: 'sofia_proregular', serif !important;
      padding: 4px;

      button {
        background-color: inherit;
        border: 0;
      }
    }

    table {
      background-color: #eee;
    }
  }
`;

export const BoxList = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

export const Box = styled.div`
  border: 1px solid #ccc;
  flex: 1;
  padding: 10px;
  margin: 10px;

  h2 {
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  p {
    text-align: center;
    font-size: 4em;
    font-family: 'sofia_proregular', serif !important;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Frag: React.FC = ({ children }: any) => {
  return <>{children}</>;
};

interface ButtonOpenProps {
  opened: boolean;
  onClick(): void;
}

export const ButtonOpen: React.FC<ButtonOpenProps> = ({
  opened,
  onClick,
}: ButtonOpenProps) => {
  return (
    <button type="button" onClick={onClick}>
      {opened ? (
        <FiMinusCircle size={20} color="17E29A" />
      ) : (
        <FiPlusCircle size={20} color="17E29A" />
      )}
    </button>
  );
};
