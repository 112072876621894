import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from './styles/global';
import { AppProvider } from './hooks';
import Routes from './routes';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>

      <GlobalStyles />
    </QueryClientProvider>
  );
};

export default App;
