import React, { useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  Form,
  Input,
  Button,
  FormRefInterface,
  getValidationErrors,
} from '../../components/Form';

interface SignFormData {
  email: string;
  password: string;
}

const FormSignIn: React.FC = () => {
  const formRef = useRef<FormRefInterface>(null);

  const { signIn } = useAuth();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório!')
            .email('Digite um e-mail válido!'),
          password: Yup.string().required('A senha é obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer o login, cheque as credenciais!',
        });
      }
    },
    [addToast, signIn],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
      <Input type="text" name="email" label="E-mail" />

      <Input type="password" name="password" label="Senha" />

      <Button type="submit">Entrar</Button>
    </Form>
  );
};

export default FormSignIn;
