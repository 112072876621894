import React, { useCallback, useEffect, useState } from 'react';
import { FiTrendingUp } from 'react-icons/fi';
import { Alert } from '../../../../utils/alerts';

import {
  Container,
  ButtonJoin,
  ButtonJoinAgain,
  ButtonDisabledJoin,
  ButtonResult,
} from './styles';
import DateString from '../../../../components/DateString';
import api from '../../../../services/api';

interface Props {
  info: any;
}

const Classe: React.FC<Props> = ({ info }: Props) => {
  const [data, setData] = useState<any>();
  const loadInfo = useCallback(async () => {
    try {
      const res = await api.get(`/avaliations/${info.id}/info`);
      console.log('res', res.data);
      setData(res.data);
    } catch (error) {}
  }, [info]);

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  const handleDisabledClick = useCallback(() => {
    Alert('Você possui avaliações anteriores pendentes!');
  }, []);

  return (
    <Container>
      <span>
        <div className="content-area">
          <h2>{info.name}</h2>
          <span>
            <DateString>{info.created_at}</DateString>
          </span>
        </div>

        <div className="buttons-area">
          {data && data.can_join && data.result && (
            <ButtonJoinAgain to={`/dashboard/turma/avaliacoes/${info.id}`}>
              Responder Novamente
            </ButtonJoinAgain>
          )}

          {data && data.can_join && !data.result && (
            <ButtonJoin to={`/dashboard/turma/avaliacoes/${info.id}`}>
              Responder
            </ButtonJoin>
          )}

          {data && data.result && (
            <ButtonResult>
              <FiTrendingUp size={20} color="#17E29A" />
              {`${data.result} de Aproveitamento`}
            </ButtonResult>
          )}

          {data && !data.can_join && (
            <ButtonDisabledJoin onClick={handleDisabledClick}>
              Responder
            </ButtonDisabledJoin>
          )}
        </div>
      </span>
    </Container>
  );
};

export default Classe;
