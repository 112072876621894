import styled from 'styled-components';

export const Container = styled.div``;

export const Line = styled.div`
  justify-content: space-around;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Block = styled.div`
  flex: 1;
  border: 2px solid #666;
  border-radius: 10px;
  margin: 20px;
  overflow: hidden;

  h1 {
    text-align: center;
    text-transform: uppercase;
    background-color: #ccc;
    font-size: 16px;
    padding: 20px;
  }

  h2 {
    text-align: center;
    font-size: 60px;
    font-weight: 100;
    font-family: 'sofia_prolight', serif;
  }
`;
