declare const Iugu: any;

export const createPaymentToken = async (formEvent: any) => {
  const res = await new Promise((resolve, reject) => {
    Iugu.createPaymentToken(formEvent.target, (response: any) => {
      if (response.errors) {
        console.error(response.errors);
        reject(new Error('Dados do cartão inválidos!'));
      } else {
        resolve(response);
      }
    });
  });

  return res as { id: string };
};
