import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiPlusCircle, FiXCircle } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import Modal from '../../../components/Modal';
import FormQuestion from './FormQuestion';
import FormAttachment from './FormAttachment';
import AppConfig from '../../../configs/app';

import {
  Container,
  Title,
  Question,
  LoadingBox,
  Informations,
  Attachments,
} from './styles';
import Attachment from './Attachment';
import Alternatives from './Alternatives';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import getClassStatuses from '../../../utils/getClassStatuses';

const statuses = getClassStatuses();

const ClassDetails: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams();
  const [info, setInfo] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);

  const [modalQuestionId, setModalQuestionId] = useState<any>();
  const [modalAttachmentId, setModalAttachmentId] = useState<any>();

  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    try {
      const response = await api.get(`/classes/${id}`);

      console.log('foi');

      setInfo(response.data);

      const responseQuestions = await api.get(`/avaliations/${id}/questions`);

      setQuestions(responseQuestions.data);

      const resAttachments = await api.get(`/classes/${id}/attachments`);

      setAttachments(resAttachments.data);

      setLoading(false);
    } catch (error) {
      if (error && error.response?.data?.message) {
        addToast({
          title: 'Erro',
          description: error.response?.data?.message,
          type: 'error',
        });
        return;
      }
      addToast({
        title: 'Erro',
        description:
          'Não foi possível carregar o conteúdo. Tente novameente mais tarde!',
        type: 'error',
      });
    }
  }, [addToast, id]);

  useEffect(() => {
    load();
  }, [load]);

  const onAddQuestionSuccess = useCallback(item => {
    setModalQuestionId(null);
    setQuestions((allQuestions: any) => [...allQuestions, item]);
  }, []);

  const onAddAttachmentSuccess = useCallback(item => {
    setModalAttachmentId(null);
    setAttachments((allAttachments: any[]) => [...allAttachments, item]);
  }, []);

  const handlePublish = useCallback(async () => {
    try {
      await api.put(`/classes/${id}/publish`);

      setInfo((i: any) => {
        return { ...i, status: 'published' };
      });
    } catch (error) {
      if (error && error.response?.data?.message) {
        addToast({
          title: 'Erro',
          description: error.response?.data?.message,
          type: 'error',
        });
        return;
      }
      addToast({
        title: 'Erro',
        description:
          'Não foi possível carregar o conteúdo. Tente novameente mais tarde!',
        type: 'error',
      });
    }
  }, [addToast, id]);

  const handleDeleteAttachment = useCallback(
    async (attachment_id: string) => {
      try {
        await api.delete(`/classes/${id}/attachments/${attachment_id}`);

        setAttachments((allAttachments: any) =>
          allAttachments.filter((at: any) => at.id !== attachment_id),
        );
      } catch (error) {
        if (error && error.response?.data?.message) {
          addToast({
            title: 'Erro',
            description: error.response?.data?.message,
            type: 'error',
          });
          return;
        }
        addToast({
          title: 'Erro',
          description:
            'Não foi possível carregar o conteúdo. Tente novameente mais tarde!',
          type: 'error',
        });
      }
    },
    [addToast, id],
  );

  const handleDeleteQuestion = useCallback(
    async (question_id: string) => {
      try {
        await api.delete(`/avaliations/${id}/questions/${question_id}`);

        setQuestions((allQuestions: any) =>
          allQuestions.filter((q: any) => q.id !== question_id),
        );
      } catch (error) {
        if (error && error.response?.data?.message) {
          addToast({
            title: 'Erro',
            description: error.response?.data?.message,
            type: 'error',
          });
          return;
        }
        addToast({
          title: 'Erro',
          description:
            'Não foi possível carregar o conteúdo. Tente novameente mais tarde!',
          type: 'error',
        });
      }
    },
    [addToast, id],
  );

  if (loading) {
    return (
      <LoadingBox>
        <ReactLoading type="spin" color="#666" height={100} width={100} />
      </LoadingBox>
    );
  }

  return (
    <Container>
      {modalQuestionId && (
        <Modal
          setModalId={setModalQuestionId}
          title={modalQuestionId === 'new' ? 'Nova Questão' : 'Editar Questão'}
        >
          <FormQuestion
            onFormSuccess={onAddQuestionSuccess}
            itemId={modalQuestionId}
            classId={id}
          />
        </Modal>
      )}

      {modalAttachmentId && (
        <Modal
          setModalId={setModalAttachmentId}
          title={modalQuestionId === 'new' ? 'Novo Anexo' : 'Editar Anexo'}
        >
          <FormAttachment
            onFormSuccess={onAddAttachmentSuccess}
            itemId={modalAttachmentId}
            classId={id}
          />
        </Modal>
      )}

      <Title>Informações</Title>
      <Informations>
        <table>
          <tbody>
            <tr>
              <th>Nome:</th>
              <td>{info?.name}</td>
            </tr>
            <tr>
              <th>Turma:</th>
              <td>{info?.classroom?.name}</td>
            </tr>
            <tr>
              <th>Tipo de vídeo:</th>
              <td>{info?.video_type}</td>
            </tr>
            <tr>
              <th>Status:</th>
              <td>
                {statuses[info?.status]}
                {info?.status === 'draft' && (
                  <button type="button" onClick={handlePublish}>
                    PUBLICAR
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Informations>

      <Title>Anexos</Title>
      <Attachments>
        {attachments.map((attachment: any) => (
          <Attachment
            key={attachment.id}
            name={`${attachment.name}.${attachment.file.substr(-3)}`}
            to={`${AppConfig.imageUrl}/${attachment.file}`}
            editable
            onDelete={() => {
              handleDeleteAttachment(attachment.id);
            }}
          />
        ))}

        <button
          type="button"
          onClick={() => {
            setModalAttachmentId('new');
          }}
        >
          <FiPlusCircle size={20} color="#000" />
          <p>Adicionar Anexo</p>
        </button>
      </Attachments>

      {info && info.has_avaliation && (
        <div>
          <Title>Avaliação</Title>

          {questions.map((item: any, itemIndex: number) => (
            <Question key={item.id} editable={info?.status === 'draft'}>
              <header>
                <h2>{`${itemIndex + 1} - ${item.title}`}</h2>
                <div>
                  <button
                    type="button"
                    onClick={() => handleDeleteQuestion(item.id)}
                  >
                    <FiXCircle size={20} color="#F00" />
                  </button>
                </div>
              </header>
              <Alternatives
                class_id={item.class_id}
                question_id={item.id}
                defaultAlternatives={item.alternatives}
                editable={info.status === 'draft'}
              />
            </Question>
          ))}
          {info?.status === 'draft' && (
            <button
              type="button"
              className="button-add-question"
              onClick={() => {
                setModalQuestionId('new');
              }}
            >
              <span>
                <FiPlusCircle size={20} color="#000" />
                Nova Questão
              </span>
            </button>
          )}
        </div>
      )}
    </Container>
  );
};

export default ClassDetails;
