import React, { useState, useCallback, useEffect } from 'react';
import ReactLoading from 'react-loading';
import api from '../../../services/api';
import { Container, Button, ButtonFile } from './styles';
import PageLoading from '../../../components/PageLoading';
import PageError from '../../../components/PageError';
import { Alert } from '../../../utils/alerts';
import { useAuth } from '../../../hooks/auth';

const MyAccount: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [loadingImage, setLoadingImage] = useState(false);
  const [imageError, setImageError] = useState('');

  const [enableChangePassword, setEnableChangePassword] = useState(false);

  const { updateAvatar } = useAuth();

  const [data, setData] = useState<any>({
    name: 'Marcus Matos',
    email: '',
    company_name: '',
    company_position: '',
    classroom_name: '',
  });

  const [passwordData, setPasswordData] = useState({
    password: '',
    rePassword: '',
  });

  const load = useCallback(async () => {
    try {
      const res = await api.get('/users/self');
      setData({
        name: res.data.name,
        email: res.data.email,
        company_name: res.data.company?.name,
        company_position: res.data.company_position,
        classroom_name: res.data.classroom?.name,
      });
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'Houve um erro ao carregar o conteúdo!',
      );
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;
    setData((oldData: any) => {
      const newData: any = { ...oldData };
      newData[name] = value;
      return newData;
    });
  }, []);

  const handleInputChangePassword = useCallback((event: any) => {
    const { value, name } = event.target;
    setPasswordData((oldData: any) => {
      const newData: any = { ...oldData };
      newData[name] = value;
      return newData;
    });
  }, []);

  const handleChangeImage = useCallback(
    async event => {
      const [file] = event.target.files;
      const formData = new FormData();
      formData.append('file', file);

      setLoadingImage(true);

      try {
        const result = await api.post('/files', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        const { filename } = result.data;

        await api.put('/users/self', {
          avatar: filename,
        });

        updateAvatar(filename);

        setLoadingImage(false);
      } catch (error) {
        setImageError(
          error.response?.data?.message ||
            'Houve um erro ao carregar o conteúdo! Tente novamente mais tarde!',
        );
        setLoadingImage(false);
      }
    },
    [updateAvatar],
  );

  const handleUpdatePassword = useCallback(async () => {
    console.log('passwordData', passwordData);
    try {
      if (passwordData.password.length < 6) {
        Alert('A senha deve ter no mínimo 6 digitos!');
        return;
      }

      if (passwordData.rePassword !== passwordData.password) {
        Alert('O campo de "repetir nova senha" deve ser igual a senha!');
        return;
      }

      await api.put('/users/self', {
        password: passwordData.password,
      });

      setPasswordData({
        password: '',
        rePassword: '',
      });
      setEnableChangePassword(false);

      Alert('Senha alterada com sucesso!');
    } catch (error) {
      Alert(
        error.response?.data?.message ||
          'Houve um erro ao carregar o conteúdo! Tente novamente mais tarde!',
      );
      setEnableChangePassword(false);
    }
  }, [passwordData]);

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <header>
        <h1>Minha Conta</h1>
      </header>
      <div>
        <form action="">
          <div>
            <span>Nome</span>
            <input
              type="text"
              name="name"
              value={data.name}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>E-mail</span>
            <input
              type="email"
              name="e-mail"
              value={data.email}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Empresa</span>
            <input
              type="text"
              name="company_name"
              value={data.company_name}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Cargo</span>
            <input
              type="text"
              name="company_position"
              value={data.company_position}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            <span>Turma</span>
            <input
              type="text"
              name="classroom_name"
              value={data.classroom_name}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div>
            {loadingImage ? (
              <Button type="button">
                <ReactLoading
                  type="spin"
                  color="#16B77E"
                  height={25}
                  width={25}
                />
              </Button>
            ) : (
              <ButtonFile
                type="file"
                placeholder="Alterar Foto de Perfil"
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}
              />
            )}

            {imageError && <p className="error-message">{imageError}</p>}
          </div>

          <div>
            {!enableChangePassword && (
              <Button
                type="button"
                onClick={() => {
                  setEnableChangePassword(true);
                }}
              >
                Alterar Senha
              </Button>
            )}
          </div>

          {enableChangePassword && (
            <div>
              <span>Nova Senha</span>
              <input
                type="password"
                name="password"
                value={passwordData.password}
                onChange={handleInputChangePassword}
              />

              <span>Repetir Nova Senha</span>
              <input
                type="password"
                name="rePassword"
                value={passwordData.rePassword}
                onChange={handleInputChangePassword}
              />

              <Button type="button" onClick={handleUpdatePassword}>
                Confirmar
              </Button>
            </div>
          )}
        </form>
      </div>
    </Container>
  );
};

export default MyAccount;
