import React, { useState, useCallback, useRef } from 'react';
import { FiEdit, FiXCircle, FiActivity } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import FormUser from './FormUser';
import api from '../../../services/api';
import { Alert, Confirm } from '../../../utils/alerts';
import { mergeArrayNoRepeat } from '../../../utils/arrayFilters';
import barOptions from './barOptions';
import appConfig from '../../../configs/app';

import {
  InfiniteScroll,
  InfiniteScrollRef,
} from '../../../components/InfiniteScroll';

import {
  Title,
  Table,
  ActionButton,
  ActionLink,
} from '../../../components/AdminComponents';
import { useToast } from '../../../hooks/toast';

import { Container, ButtonInvite } from './styles';

import AdminFilterBar from '../../../components/AdminFilterBar';

import Modal from '../../../components/Modal';

import ResponsiveImage from '../../../components/ResponsiveImage';

const getExportLink = (): string => {
  console.log('getExportLink');
  const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);
  return `${appConfig.API_BASE_URL}/users/export?token=${token}`;
};

const Users: React.FC = () => {
  const { addToast } = useToast();
  const [modalId, setModalId] = useState<any>('');
  const [searchParams, setSearchParams] = useState<string>('');
  const [result, setResult] = useState<any>();
  const isRef = useRef<InfiniteScrollRef>();

  const [sendingInvitation, setSendingInvigation] = useState<string>('');

  const loadData = useCallback(
    async (page: number) => {
      const uri = searchParams
        ? `/adm/users?page=${page}&${searchParams}`
        : `/adm/users?page=${page}`;

      // console.log('uri', uri);

      const { data: dataResult } = await api.get(uri);

      page === 1
        ? setResult(dataResult)
        : setResult((state: any) => {
            return {
              ...state,
              page,
              items: mergeArrayNoRepeat(state.items, dataResult.items),
            };
          });
    },
    [searchParams],
  );

  const handleAddItem = useCallback(async id => {
    setModalId(id);
  }, []);

  const handleDeleteItem = useCallback(async id => {
    const ok = await Confirm('Tem certeza que deseja remover este item?');
    if (ok) {
      await api.delete(`/users/${id}`);
      setResult((state: any) => ({
        ...state,
        items: state.items.filter((dd: any) => dd.id !== id),
      }));
      Alert('Item deletado com sucesso!');
    }
  }, []);

  const onFormSuccess = useCallback(item => {
    setModalId(null);

    setResult((state: any) => ({
      ...state,
      items:
        state.items.findIndex((i: any) => i.id === item.id) === -1
          ? [item, ...state.items]
          : state.items.map((i: any) => (i.id === item.id ? item : i)),
    }));
  }, []);

  const handleResendInvitation = useCallback(
    async (id: string) => {
      setSendingInvigation(id);
      try {
        const {
          data: { message },
        } = await api.post(`/adm/users/${id}/resend-invite`, {});
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: message,
        });
      } catch ({ response }) {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            response.data.message || 'Não foi possível realizar a requisição!',
        });
      }

      setSendingInvigation('');
    },
    [addToast],
  );

  const handleSearch = useCallback(values => {
    isRef?.current?.resetPages();
    setResult(null);
    setSearchParams(values);
    // setSearchQuery(values);
  }, []);

  return (
    <Container>
      <Title>Usuários</Title>

      <AdminFilterBar
        handleAdd={() => setModalId('new')}
        handleSearch={handleSearch}
        options={barOptions}
        linkButtonTitle="Exportar tudo"
        linkButtonLink={getExportLink()}
      />

      {modalId && (
        <Modal
          setModalId={setModalId}
          title={modalId === 'new' ? 'Novo Usuário' : 'Editar Usuário'}
        >
          <FormUser onFormSuccess={onFormSuccess} itemId={modalId} />
        </Modal>
      )}

      <InfiniteScroll
        myref={isRef}
        loadMore={loadData}
        hasMore={!result || result.page < result.pages}
      >
        <Table>
          <table>
            <thead>
              <tr>
                <th className="images">Foto</th>
                <th>Nome</th>
                <th>Empresa</th>
                <th>Turma</th>
                <th>Admin</th>
                <th>Status</th>
                <th className="actions">_</th>
              </tr>
            </thead>
            <tbody>
              {result &&
                result.items.map((item: any) => (
                  <tr key={item.id}>
                    <td className="images">
                      <ResponsiveImage
                        src={item.avatar}
                        proportion="1:1"
                        defaultType="user"
                      />
                    </td>
                    <td>
                      <p>{item.name}</p>
                      <p>{item.email}</p>
                    </td>
                    <td>{item.company?.name}</td>
                    <td>{item.classroom?.name}</td>
                    <td>{item.is_admin ? 'Sim' : ''}</td>
                    <td>
                      {item.status !== 'drft' ? item.status : ''}

                      {item.status === 'draft' && (
                        <ButtonInvite
                          onClick={() => {
                            handleResendInvitation(item.id);
                          }}
                          disabled={sendingInvitation === item.id}
                        >
                          {sendingInvitation === item.id ? (
                            <ReactLoading
                              type="spin"
                              color="#16B77E"
                              height={20}
                              width={20}
                            />
                          ) : (
                            'Reenviar convite'
                          )}
                        </ButtonInvite>
                      )}
                    </td>
                    <td className="actions">
                      <ActionLink to={`/admin/users/${item.id}/activities`}>
                        <FiActivity color="#000" size={18} />
                      </ActionLink>

                      <ActionButton
                        onClick={() => {
                          handleAddItem(item.id);
                        }}
                      >
                        <FiEdit color="#000" size={18} />
                      </ActionButton>

                      <ActionButton onClick={() => handleDeleteItem(item.id)}>
                        <FiXCircle color="#000" size={18} />
                      </ActionButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Table>
      </InfiniteScroll>
    </Container>
  );
};

export default Users;
