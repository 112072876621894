import appConfig from '../configs/app';

class Storage {
  prefix: string;

  constructor() {
    this.prefix = `${appConfig.storagePrefix}:`;
  }

  setItem(keyName: string, val: any): void {
    const keyNameWithPrefix = this.prefix + keyName;

    const dataVal = typeof val === 'string' ? val : JSON.stringify(val);

    localStorage.setItem(keyNameWithPrefix, dataVal);
  }

  getItem(keyName: string): any {
    const keyNameWithPrefix = this.prefix + keyName;
    const response = localStorage.getItem(keyNameWithPrefix);

    if (!response) {
      return null;
    }

    const firstKey = response?.substr(0, 1);
    const lastKey = response?.substr(response.length - 1, 1);

    if (
      firstKey === lastKey &&
      (firstKey === '{' || firstKey === '[') &&
      (lastKey === '}' || lastKey === ']')
    ) {
      return JSON.parse(response);
    }
    return response;
  }

  removeItem(keyName: string): void {
    const keyNameWithPrefix = this.prefix + keyName;

    localStorage.removeItem(keyNameWithPrefix);
  }
}

export default new Storage();
