import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { Container } from './styles';
import Subscribe from '../../pages/Dashboard/Subscribe';

const DemoMessage: React.FC = () => {
  const { user } = useAuth();
  // waiting-subscription
  return (
    <>
      {user && user.status === 'demo' && (
        <Container>
          <p>
            Você está participando da turma gratuita. Assine agora para ter
            acesso completo a todas as aulas e funcionalidades!
          </p>
          <Link to="/dashboard/subscribe">Assinar agora!</Link>
        </Container>
      )}

      {user && user.status === 'waiting-subscription' && <Subscribe />}
    </>
  );
};

export default DemoMessage;
