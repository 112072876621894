import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  font-size: 40px;
  font-family: 'sofia_proregular', serif;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ActionButton = styled.button`
  background-color: inherit;
  border: 0;
`;

export const ActionLink = styled(Link)`
  background-color: inherit;
  border: 0;
`;

export const AddButton = styled.button`
  background-color: inherit;
  border: 0;

  padding: 8px 20px;
  background-color: #000;
  margin: 4px;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
`;

export const AButton = styled.a`
  background-color: inherit;
  border: 0;

  padding: 8px 20px;
  background-color: #000;
  margin: 4px;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  text-decoration: none;
`;

export const Table = styled.div`
  overflow-x: scroll;

  table {
    width: 100%;
    min-width: 400px;
  }

  th {
    text-align: left;
    padding: 10px;
  }

  td {
    padding: 10px;
    font-family: 'sofia_proregular', serif;
    font-size: 1em;
  }

  th,
  td {
    border: 1px solid #ccc;
  }

  th.actions {
    width: 150px;
  }

  td.actions {
    width: 150px;

    svg {
      margin: 0 4px;
    }
  }

  th.images {
    width: 80px;
  }

  td.images {
    width: 80px;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }

    div.responsive-image-wrap img {
      width: 100%;
      height: 100%;
    }
  }

  tr:hover td {
    background-color: #999;
  }
`;
