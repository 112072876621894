import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import {
  Form,
  Input,
  Button,
  FormRefInterface,
  getValidationErrors,
} from '../../components/Form';

import { Container, FormArea } from './styles';

import LogoImg from '../../assets/logo.svg';

interface FormData {
  email: string;
}

const RecoveryPasswordRequest: React.FC = () => {
  const formRef = useRef<FormRefInterface>(null);

  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        if (loading) {
          return;
        }
        setLoading(true);

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório!')
            .email('Digite um e-mail válido!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users/recovery-password', { email: data.email });

        setLoading(false);
        formRef?.current?.setData({ email: '' });

        addToast({
          title: 'Verificar E-mail',
          description: 'Enviamos o link de alteração de senha no seu e-mail!',
          type: 'info',
        });

        // await signIn({
        //   email: data.email,
        //   // password: data.password,
        // });
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description:
            error.response?.data?.message ||
            'Houve um erro na requisição. Tente novamente mais tarde!',
        });
      }
    },
    [addToast, loading],
  );

  return (
    <Container>
      <FormArea>
        <img src={LogoImg} alt="Logo" />

        <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
          <p className="description">
            Digite o seu e-mail de caadastro para recuperar a senha!
          </p>
          <Input type="text" name="email" label="E-mail" />

          <Button
            type="submit"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!loading && <span>Enviar</span>}
            {loading && (
              <ReactLoading type="spin" color="#FFF" height={20} width={20} />
            )}
          </Button>
        </Form>
      </FormArea>
    </Container>
  );
};

export default RecoveryPasswordRequest;
