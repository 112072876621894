import styled from 'styled-components';

export const Container = styled.button`
  display: block;
  flex: 1;
  padding: 20px;

  background: inherit;
  border: 0;
  margin: 0;
  text-align: left;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 33%;
  }

  h2 {
    display: block;
    font-size: 16px;
    line-height: 1.2em;
    text-transform: uppercase;
    color: #a19e9e;
    padding: 20px 2px 4px 2px;
    font-family: 'sofia_probold', serif;
  }

  & > span {
    display: block;
    font-size: 16px;
    color: #a8a5a5;
    font-family: 'sofia_proregular', serif;
    /* outline: 1px solid red; */
  }
`;

export const Image = styled.div`
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  /* padding-bottom: 56.2%; */

  /*img {
    display: block;
    object-fit: cover;

    position: absolute;
    width: 100%;
    height: 100%;
  }*/

  & > div.image-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 39px;
    padding: 0 4px;
    z-index: 3;
  }

  & > div.image-bar > div {
    display: flex;
    flex: 1;

    span {
      padding: 4px 10px;

      font-size: 10px;
      border-radius: 10px;

      margin: 0 4px;
      font-family: 'sofia_proregular', serif;
    }

    span.icon {
      padding: 4px;
    }

    span.live {
      color: #fefaf9;
      background-color: #f05d5e;
    }

    span.scheduled {
      color: #fefaf9;
      background-color: #9596fb;
    }
  }

  & > div.image-bar > div:last-child {
    justify-content: flex-end;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
    z-index: 2;
  }

  &:hover:before {
    background-color: rgba(0, 0, 0, 0);
  }
`;
