import styled from 'styled-components';

export const Container = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-family: 'sofia_proregular', serif;
    font-size: 18px;
  }
`;
