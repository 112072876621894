import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FiCircle, FiCheckCircle } from 'react-icons/fi';

import api from '../../../services/api';
import PageLoading from '../../../components/PageLoading';
import PageError from '../../../components/PageError';
import { Alert } from '../../../utils/alerts';
import getAlternativesLetters from '../../../utils/getAlternativesLetters';

import { useAnalytics } from '../../../hooks/analytics';

import {
  Container,
  Questions,
  Question,
  Alternatives,
  Alternative,
  ButtonSave,
} from './styles';

const alternativeLetters = getAlternativesLetters();

const Avaliation: React.FC = () => {
  const { event } = useAnalytics();

  const { id } = useParams();
  const { push } = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [questions, setQuestions] = useState<any[]>([]);

  const load = useCallback(async () => {
    try {
      const res = await api.get(`/avaliations/${id}/questions`);

      setQuestions(res.data);
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || 'Houve um erro na requisição!',
      );
    }
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  const handleCheck = useCallback(
    (question_id: string, alternative_id: string) => {
      setQuestions(allQuestions => {
        return allQuestions.map(q => {
          return q.id !== question_id
            ? q
            : {
                ...q,
                alternatives: q.alternatives.map((a: any) => {
                  return a.id !== alternative_id
                    ? { ...a, checked: false }
                    : { ...a, checked: true };
                }),
              };
        });
      });
    },
    [],
  );

  const handleSave = useCallback(async () => {
    const questionsToSend = questions.reduce((newArray, currentValue) => {
      const selectedAlternative = currentValue.alternatives.find(
        (v: any) => v.checked,
      );

      return [
        ...newArray,
        {
          question_id: currentValue.id,
          alternative_id: selectedAlternative ? selectedAlternative.id : null,
        },
      ];
    }, []);

    const hasError = questionsToSend.filter((element: any) => {
      return !element.alternative_id;
    });

    if (hasError.length > 0) {
      Alert('Você deve preencher todas as questões!');
      return;
    }

    // console.log('questionsToSend', questionsToSend);

    try {
      setLoading(true);
      await api.post(`/avaliations/${id}/save`, questionsToSend);

      event({
        category: 'Usuário',
        action: 'Respondeu avaliação',
        value: id,
      });

      push('/dashboard/turma/avaliacoes');
      // console.log('res', res.data);
      // setLoading(false);
    } catch (error) {
      // console.log('error', error.response.data);
      setErrorMessage(
        error.response.data || 'Não foi possível enviar a resposta!',
      );
      setLoading(false);
    }
  }, [event, id, push, questions]);

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <h1>Avaliação!</h1>
      <Questions>
        {questions.map((question, indexQuestion: number) => (
          <Question key={question.id}>
            <h2>{`${indexQuestion + 1} - ${question.title}`}</h2>
            <Alternatives>
              {question.alternatives.map(
                (alternative: any, indexAlternative: number) => (
                  <Alternative key={alternative.id}>
                    {!alternative.checked && (
                      <button
                        type="button"
                        onClick={() => {
                          handleCheck(question.id, alternative.id);
                        }}
                      >
                        <FiCircle size={20} color="#CCC" />
                      </button>
                    )}

                    {alternative.checked && (
                      <button type="button">
                        <FiCheckCircle size={20} color="#17E29A" />
                      </button>
                    )}
                    <span>{`${alternativeLetters[indexAlternative]} - ${alternative.title}`}</span>
                  </Alternative>
                ),
              )}
            </Alternatives>
          </Question>
        ))}
      </Questions>

      <ButtonSave onClick={handleSave}>Response avaliação</ButtonSave>
    </Container>
  );
};

export default Avaliation;
