import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from '../../components/Header';

import { Container, Content } from './styles';
import Menu from '../../components/Menu';

import Home from './Home';
import Live from './Live';
import MyAccount from './MyAccount';
import MyClasroom from './MyClassroom';
import Class from './Class';
import Informations from './Informations';
import Contact from './Contact';
import Subscribe from './Subscribe';
import Stamps from './Stamps';

const Dashboard: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Menu />
        <Content>
          <Switch>
            <Route path="/dashboard" exact component={Home} />
            <Route path="/dashboard/lives/:id" component={Live} />
            <Route path="/dashboard/conta" exact component={MyAccount} />

            <Route path="/dashboard/turma/aula/:id" component={Class} />
            <Route path="/dashboard/turma" component={MyClasroom} />
            <Route path="/dashboard/informacoes" component={Informations} />
            <Route path="/dashboard/contato" component={Contact} />
            <Route path="/dashboard/subscribe" component={Subscribe} />
            <Route path="/dashboard/stamps" component={Stamps} />
          </Switch>
        </Content>
      </Container>
    </>
  );
};

export default Dashboard;
