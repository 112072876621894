import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMenu } from '../../hooks/menu';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import AppConfig from '../../configs/app';
import Link from './MenuLink';

import { Container, Select } from './styles';
import defaultUserImage from '../../assets/default-user-image.png';
import { useRemoteConfigs } from '../../hooks/remote-configs';

interface InputProps {
  bgColor?: string;
}

const Menu: React.FC<InputProps> = ({ bgColor }: InputProps) => {
  const { user, signOut, selectedClassroom, selectClassroom } = useAuth();
  const { openned } = useMenu();

  const { ALLOW_NEW_FEATURES } = useRemoteConfigs();

  const [classrooms, setClassrooms] = useState<unknown[]>([]);
  const { replace } = useHistory();

  const loadClassrooms = useCallback(async () => {
    const { data } = await api.get('/classrooms');
    setClassrooms(data);
    console.log('data', data);
  }, []);

  useEffect(() => {
    loadClassrooms();
  }, [loadClassrooms]);

  const handleChangeClassroom = useCallback(
    (classroomId: string) => {
      selectClassroom(classroomId);
      replace('/dashboard');
    },
    [selectClassroom, replace],
  );

  return (
    <Container bgColor={bgColor} openned={openned}>
      {user && (
        <header>
          <img
            src={
              user.avatar
                ? `${AppConfig.imageUrl}/${user.avatar}`
                : defaultUserImage
            }
            alt=""
          />
          <h1>{user?.name}</h1>
          <h2>{user?.company_position}</h2>
        </header>
      )}
      {bgColor === 'black' ? (
        <ul>
          <li>
            <Link to="/admin" activeOnlyWhenExact>
              Home
            </Link>
          </li>
          <li>
            <Link to="/admin/companies">Empresas</Link>
          </li>
          <li>
            <Link to="/admin/classrooms">Turmas</Link>
          </li>
          <li>
            <Link to="/admin/users">Usuários</Link>
          </li>
          <li>
            <Link to="/admin/classes">Aulas</Link>
          </li>
          <li>
            <Link to="/admin/lives">Lives</Link>
          </li>
          <li>
            <Link to="/admin/quiz">Pesquisas</Link>
          </li>
          {ALLOW_NEW_FEATURES && (
            <li>
              <Link to="/admin/plans">Planos</Link>
            </li>
          )}

          <li>
            <Link to="/admin/notificacoes">Notificações</Link>
          </li>
          <li>
            <Link to="/admin/relatorios">Relatórios</Link>
          </li>
          {/* <li>
            <Link to="/admin/not-found">Pesquisas</Link>
          </li>
          <li>
            <Link to="/admin/not-found">Forum</Link>
          </li> */}
        </ul>
      ) : (
        <ul>
          <li>
            {user!! && user.is_admin && (
              <Select
                onChange={event => handleChangeClassroom(event.target.value)}
                value={selectedClassroom}
              >
                {!selectedClassroom && (
                  <option value="">Escolha uma turma</option>
                )}
                {classrooms &&
                  classrooms.map((classroom: any) => (
                    <option value={classroom.id} key={classroom.id}>
                      {classroom.name}
                    </option>
                  ))}
              </Select>
            )}
          </li>
          <li>
            <Link to="/dashboard" activeOnlyWhenExact>
              Home
            </Link>
          </li>

          <li>
            <Link to="/dashboard/conta">Minha Conta</Link>
          </li>

          <li>
            <Link to="/dashboard/informacoes">Informações</Link>
          </li>

          {user.classroom_id && (
            <>
              <li>
                <Link to="/dashboard/turma">Minha Turma</Link>
              </li>
              {ALLOW_NEW_FEATURES && (
                <li>
                  <Link to="/dashboard/stamps">Selos e Certificados</Link>
                </li>
              )}
            </>
          )}

          <li>
            <Link to="/dashboard/contato">Contato</Link>
          </li>
          {user && user.status === 'demo' && (
            <li className="button-subscribe">
              <Link to="/dashboard/subscribe">Assinar Agora</Link>
            </li>
          )}
        </ul>
      )}
      <footer>
        <a href="/" onClick={signOut}>
          Sair
        </a>
      </footer>
    </Container>
  );
};

export default Menu;
