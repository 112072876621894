import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-family: 'sofia_proregular', serif;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ActionButton = styled.button`
  background-color: inherit;
  border: 0;
`;

export const ActionLink = styled(Link)`
  background-color: inherit;
  border: 0;
`;

export const Table = styled.div`
  table {
    width: 100%;
  }

  th {
    text-align: left;
    padding: 10px;
  }

  td {
    padding: 10px;
  }

  th,
  td {
    border: 1px solid #ccc;
  }

  td.actions {
    width: 150px;

    svg {
      margin: 0 4px;
    }
  }

  tr:hover td {
    background-color: #999;
  }
`;
