import React, { useCallback, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import YouTube from 'react-youtube';
import { FiBookOpen } from 'react-icons/fi';
import Vimeo from '@u-wave/react-vimeo';
import api from '../../../services/api';
import appConfig from '../../../configs/app';
import ResponsiveVideo from '../../../components/ResponsiveVideo';
import DateString from '../../../components/DateString';
import Attachment from '../../Admin/ClassDetails/Attachment';
import PageError from '../../../components/PageError';
import PageLoading from '../../../components/PageLoading';

import {
  Container,
  VideoContainer,
  YouTubeBox,
  LinkZoom,
  Title,
  Attachments,
  Informations,
} from './styles';

const Live: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState<any>({});

  const [attachments, setAttachments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const loadData = useCallback(async () => {
    try {
      const res = await api.get(`/classes/${id}`);

      const resAttachments = await api.get(`/classes/${id}/attachments`);

      setInfo(res.data);

      setAttachments(resAttachments.data);

      // Mark as Read
      await api.put(`/classes/${id}/read`, {});
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || 'Houve um erro na requisição!',
      );
    }
  }, [id]);

  const getYoutubeVideoId = useCallback(videoUrl => {
    let video_id = videoUrl.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }

    return video_id;
  }, []);

  const getVimeoVideoId = useCallback(videoUrl => {
    const [, , , video_id] = videoUrl.split('/');

    return video_id;
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <Title>{info?.name}</Title>

      {attachments && attachments.length > 0 && (
        <Attachments>
          <h2>Arquivos Disponíveis</h2>
          {attachments.map((item: any) => (
            <Attachment
              key={item.id}
              to={`${appConfig.imageUrl}/${item.file}`}
              name={`${item.name}.${item.file.substr(-3)}`}
            />
          ))}
        </Attachments>
      )}

      <VideoContainer>
        {!loading && info.video_type === 'youtube' && (
          <YouTubeBox>
            <YouTube
              id="tubeplayer"
              videoId={getYoutubeVideoId(info.video_url)}
              opts={{
                playerVars: {
                  // controls: 0,
                  // iv_load_policy: 3,
                  // fs: 1,
                  modestbranding: 1,
                  rel: 0,
                  showinfo: 0,
                },
              }}
            />
          </YouTubeBox>
        )}

        {!loading && info.video_type === 'vimeo' && (
          <YouTubeBox>
            <Vimeo video={getVimeoVideoId(info.video_url)} />
          </YouTubeBox>
        )}

        {/* <iframe title="vimeo-player" src="https://player.vimeo.com/video/115783408" width="640" height="360" frameborder="0" allowfullscreen></iframe> */}

        {!loading && info.video_type === 'file' && (
          <ResponsiveVideo src={info.video_url} />
        )}

        {!loading && info.video_type === 'zoom' && (
          <LinkZoom href={info.video_url} target="blank">
            Abrir Zoom
          </LinkZoom>
        )}
      </VideoContainer>

      <Informations>
        <div>
          {info.created_at && <DateString>{info.created_at}</DateString>}
        </div>
        <div>
          {info.has_avaliation && (
            <Link to={`/dashboard/turma/avaliacoes/${info.id}`}>
              <FiBookOpen size={20} color="17E29A" />
              Fazer Avaliação
            </Link>
          )}
        </div>
      </Informations>
    </Container>
  );
};

export default Live;
