import React from 'react';
import ReactLoading from 'react-loading';
import { useFormikContext } from 'formik';

import { Container } from './styles';

interface Props {
  label: string;
}

const Submit: React.FC<Props> = ({ label }: Props) => {
  const { isSubmitting } = useFormikContext<any>();

  return (
    <Container type="submit" disabled={isSubmitting}>
      {isSubmitting ? (
        <ReactLoading type="spin" color="#FFF" height={20} width={20} />
      ) : (
        label
      )}
    </Container>
  );
};

export default Submit;
