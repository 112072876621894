import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
// import { Formik, Form } from 'formik';
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
// import Input from '../../../forms/FormA/Input';
// import SimpleInput from '../../../forms/FormA/SimpleInput';
import Button from '../../../forms/FormA/SimpleSubmit';
import { Container as InputContainer } from '../../../forms/FormA/styles';
import { initialValues, validationSchema } from './form-data';

import logoImg from '../../../assets/logo.svg';
import PageError from '../../../components/PageError';
import * as iuguService from '../../../services/iugu.service';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  PlanList,
  Plan,
  BackButton,
} from './styles';
import { Alert } from '../../../utils/alerts';
import PageLoading from '../../../components/PageLoading';
import { useRemoteConfigs } from '../../../hooks/remote-configs';

interface PreInfo {
  plans: any[];
  classrooms: any[];
  selectedPlan?: any;
  selectedClassroom?: any;
  step: number;
}

declare let Iugu: any;

const Subscribe: React.FC = () => {
  const { IUGU_ACCOUNT_ID, ENV } = useRemoteConfigs();

  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [preInfo, setPreInfo] = useState<PreInfo>({
    plans: [],
    classrooms: [],
    step: 1,
  });

  const { info, updateUserData } = useAuth();

  const [cardData, setCardData] = useState<any>({
    ...initialValues,
    focus: '',
  });

  const history = useHistory();

  useEffect(() => {
    // Setupe IUGU
    Iugu.setAccountID(IUGU_ACCOUNT_ID);
    if (ENV === 'development') {
      Iugu.setTestMode(true);
    }
    Iugu.setup();

    const load = async () => {
      try {
        const { data: plans } = await api.get('/api/plans', {
          params: { enabled: true },
        });
        const { data: classrooms } = await api.get(
          '/api/classrooms?is_trial=false&allow_subscription=true',
        );

        // console.log('sub classrooms', classrooms);

        setPreInfo({
          plans,
          classrooms,
          step: 1,
        });

        setLoading(false);
      } catch (error) {
        // console.log('error', error);
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: error.message || 'Houve um erro na requisição.',
        });
      }
    };

    load();
  }, [ENV, IUGU_ACCOUNT_ID, addToast, info]);

  const handleDoPayment = useCallback(
    async (card_token: string) => {
      if (!preInfo.selectedPlan || !preInfo.selectedClassroom) {
        return;
      }

      try {
        const { data } = await api.post('/api/subscriptions/with-card', {
          card_token,
          plan_id: preInfo.selectedPlan.id,
          classroom_id: preInfo.selectedClassroom.id,
        });

        updateUserData({
          status: data.user.status,
        });
        history.replace('/dashboard');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: error.message || 'Houve um erro na requisição.',
        });
        setLoading(false);
      }
    },
    [
      addToast,
      history,
      preInfo.selectedClassroom,
      preInfo.selectedPlan,
      updateUserData,
    ],
  );

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      setSubmitting(true);

      try {
        const { id } = await iuguService.createPaymentToken(e);

        console.log('id', id);

        await handleDoPayment(id);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: error.message || 'Houve um erro na requisição.',
        });
        setLoading(false);
        setSubmitting(false);
      }
    },
    [addToast, handleDoPayment],
  );

  const handleSelectedPlan = useCallback((plan: any) => {
    setPreInfo(state => ({
      ...state,
      selectedPlan: plan,
      step: 2,
    }));
  }, []);

  const handleSelecteClassroom = useCallback((classroom: any) => {
    setPreInfo(state => ({
      ...state,
      selectedClassroom: classroom,
      step: 3,
    }));
  }, []);

  if (!info || !info.ALLOW_PUBLIC_REGISTER) {
    return <PageError message="Cadastro não permitido!" />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Neoxs" />
          <h1>
            {preInfo.step === 1 && 'Escolha o plano!'}
            {preInfo.step === 2 && 'Escolha a turma de sua preferência'}
            {preInfo.step === 3 && 'Preencha os dados de pagamento'}
          </h1>

          <br />

          {preInfo.step === 1 && (
            <PlanList>
              {preInfo.plans.map(plan => (
                <Plan
                  key={plan.id}
                  type="button"
                  onClick={() => handleSelectedPlan(plan)}
                >
                  <h2>{plan.name}</h2>
                  <p>{plan.description}</p>
                </Plan>
              ))}
            </PlanList>
          )}

          {preInfo.step === 2 && (
            <PlanList>
              {preInfo.classrooms.map(item => (
                <Plan
                  key={item.id}
                  type="button"
                  onClick={() => handleSelecteClassroom(item)}
                >
                  <h2>{item.name}</h2>
                  <p>{item.description}</p>
                </Plan>
              ))}
            </PlanList>
          )}

          {preInfo.step === 3 && (
            <>
              <Cards
                placeholders={{
                  name: 'SEU NOME',
                }}
                locale={{
                  valid: 'Validade',
                }}
                cvc={cardData.cvc}
                expiry={cardData.expiry}
                name={cardData.name}
                number={cardData.number}
                focused={cardData.focus}
              />

              <br />
              <form onSubmit={handleSubmit}>
                <InputContainer>
                  <InputMask
                    name="number"
                    type="text"
                    data-iugu="number"
                    placeholder="xxxx xxxx xxxx xxxx"
                    mask="9999 9999 9999 9999"
                    maskChar={null}
                    value={cardData.number}
                    onChange={(e: any) => {
                      setCardData({ ...cardData, number: e.target.value });
                    }}
                    onFocus={(e: any) => {
                      setCardData({ ...cardData, focus: e.target.name });
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <input
                    name="name"
                    type="text"
                    data-iugu="full_name"
                    placeholder="NOME"
                    value={cardData.name}
                    onChange={(e: any) => {
                      setCardData({ ...cardData, name: e.target.value });
                    }}
                    onFocus={(e: any) => {
                      setCardData({ ...cardData, focus: e.target.name });
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputMask
                    name="expiry"
                    type="text"
                    data-iugu="expiration"
                    placeholder="Validade"
                    mask="99/99"
                    maskChar={null}
                    value={cardData.expiry}
                    onChange={(e: any) => {
                      setCardData({ ...cardData, expiry: e.target.value });
                    }}
                    onFocus={(e: any) => {
                      setCardData({ ...cardData, focus: e.target.name });
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputMask
                    name="cvc"
                    type="text"
                    data-iugu="verification_value"
                    placeholder="CVC"
                    mask="9999"
                    maskChar={null}
                    value={cardData.cvc}
                    onChange={(e: any) => {
                      setCardData({ ...cardData, cvc: e.target.value });
                    }}
                    onFocus={(e: any) => {
                      setCardData({ ...cardData, focus: e.target.name });
                    }}
                  />
                </InputContainer>

                <Button label="Confirmar" isSubmitting={submitting} />
              </form>
            </>
          )}

          {preInfo.step > 1 && (
            <BackButton
              type="button"
              onClick={() => {
                setPreInfo(state => ({
                  ...state,
                  step: state.step - 1,
                }));
              }}
            >
              {preInfo.step === 2 && '<- Escolhe outro plano'}

              {preInfo.step === 3 && '<- Escolhe outra turma'}
            </BackButton>
          )}

          {/* <Link to="/">
            <FiArrowLeft />
            Voltar para Logon
          </Link> */}
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Subscribe;
