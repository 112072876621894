import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

export interface FormRefInterface extends FormHandles {}

export { Form } from '@unform/web';

export { default as Input } from './Input';
export { default as InputDate } from './InputDate';

export { default as Button } from './Button';
export { default as Image } from './Image';
export { default as Video } from './Video';
export { default as Doc } from './Doc';

export { default as Select } from './Select';

interface Errors {
  [key: string]: string;
}

export function getValidationErrors(error: ValidationError): Errors {
  const validationErrors: Errors = {};

  error.inner.forEach(e => {
    validationErrors[e.path] = e.message;
  });

  return validationErrors;
}

// export interface  FormHandlesInterface;
