/* eslint-disable import/extensions */
import React, { useState, useEffect, useCallback } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { Container } from './styles';

import colorArray from './colors';

interface PieChartProps {
  title: string;
  values: any[];
  keys: {
    title: string;
    value: string;
  };
}

const PieChartNew: React.FC<PieChartProps> = ({
  title,
  values,
  keys,
}: PieChartProps) => {
  const [data, setData] = useState<any[]>([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const total = values.reduce((oldValue, newValue) => {
      return oldValue + newValue[keys.value];
    }, 0);

    setData(
      values.map((value: any, index: number) => ({
        value: value[keys.value],
        title: value[keys.title],
        color: colorArray[index],
      })),
    );
  }, [values, keys]);

  const handleMouseHover = useCallback(
    (e, segmentIndex) => {
      const item = data.find((_, index) => index === segmentIndex);

      if (item) {
        setDescription(`${item.title} (${item.value})`);
      }
    },
    [data],
  );

  if (!data) {
    return <></>;
  }

  return (
    <Container style={{ textAlign: 'center' }}>
      <PieChart
        data={data}
        label={({ dataEntry }) => dataEntry.value}
        style={{ maxWidth: 300 }}
        labelStyle={{ fontSize: 5 }}
        labelPosition={90}
        onMouseOver={handleMouseHover}
        onFocus={(e, segIndex) => console.log('onFocus', e, segIndex)}
      />
      <h3>{description}</h3>
    </Container>
  );
};

export default PieChartNew;
