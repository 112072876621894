import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Container, BoxList, Box, Frag, ButtonOpen } from '../result.styles';
import api from '../../../../services/api';
import appConfig from '../../../../configs/app';

import PieChart from '../PIeChart';

const scrollToId = (id: string): void => {
  document.getElementById(id)?.scrollIntoView({
    behavior: 'smooth',
  });
};

const ClassCompany: React.FC = () => {
  const [res, setRes] = useState<any>();
  const [chartValues, setChartValues] = useState<any[]>([]);
  const [scrolled, setScrolled] = useState<any>(false);

  const { id } = useParams() as any;

  const load = useCallback(async () => {
    const { data } = await api.get('/reports/class/companies');
    setChartValues(data.items);
    setRes(data);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleDetails = useCallback(
    async (item_id: string, opened: boolean) => {
      const { data } = opened
        ? { data: [] }
        : await api.get(`/reports/class/users/${item_id}`);

      setRes((r: any) => {
        return {
          ...r,
          items: r.items.map((item: any) => {
            if (item.id === item_id) {
              return { ...item, opened: !opened, data };
            }

            return item;
          }),
        };
      });
    },
    [],
  );

  useEffect(() => {
    if (id && res && !scrolled) {
      handleDetails(id, false);
      setScrolled(true);
      setTimeout(() => {
        scrollToId(id);
      }, 500);
    }
  }, [id, handleDetails, res, scrolled]);

  const handleExport = useCallback((item_id: string) => {
    const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);
    const url = `${appConfig.API_BASE_URL}/reports/class/companies/${item_id}/export?token=${token}&export=true`;
    window.open(url, 'blank');
  }, []);

  if (!res) {
    return <div>Carregando</div>;
  }

  return (
    <Container>
      <h2>Avaliações / Empresas</h2>

      <PieChart
        title="Usuários por Empresa"
        values={chartValues}
        keys={{ value: 'result', title: 'name' }}
      />

      <BoxList>
        <Box>
          <h2>Empresas</h2>
          <p>{res.total_companies}</p>
        </Box>
        {/* <Box>
          <h2>Respostas</h2>
          <p>{res.total_avaliations}</p>
        </Box> */}
      </BoxList>

      <table>
        <thead>
          <tr>
            {/* <th>.</th> */}
            <th>Nome</th>
            <th>Aproveitamento</th>
            <th>Exportar</th>
          </tr>
        </thead>
        <tbody>
          {res.items.map((item: any) => (
            <Frag key={item.id}>
              <tr id={item.id}>
                {/* <td>
                  <ButtonOpen
                    opened={item.opened}
                    onClick={() => handleDetails(item.id, !!item.opened)}
                  />
                </td> */}
                <td>{item.name}</td>
                <td>{`${item.result}%`}</td>
                <td>
                  <button type="button" onClick={() => handleExport(item.id)}>
                    Exportar
                  </button>
                </td>
              </tr>
              {/* {!!item.opened && (
                <tr>
                  <td colSpan={5}>
                    <table>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Empresa</th>
                          <th>Resultado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data.map((subitem: any) => (
                          <tr key={subitem.id}>
                            <td>{subitem.name}</td>
                            <td>{subitem.company_name}</td>
                            <td>{subitem.result}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )} */}
            </Frag>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default ClassCompany;
