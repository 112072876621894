import React, { ReactNode } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

// import { Container } from './styles';

interface Props {
  to: string;
  activeOnlyWhenExact?: boolean;
  children: ReactNode;
}

const PillLink: React.FC<Props> = ({
  to,
  activeOnlyWhenExact,
  children,
}: Props) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <Link to={to} className={match ? 'active' : ''}>
      {children}
    </Link>
  );
};

export default PillLink;
