import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Link from './PillLink';
import { Container, PillMenu, PillMenuContent } from './styles';

import Company from './Company';
import Classroom from './Classroom';
import User from './User';
import Class from './Class';
import ClassCompany from './ClassComapany';

const Reports: React.FC = () => {
  return (
    <Container>
      <PillMenu>
        <PillMenuContent>
          <Link to="/admin/relatorios/usuarios" activeOnlyWhenExact>
            Usuários
          </Link>
          <Link to="/admin/relatorios/empresas" activeOnlyWhenExact>
            Empresas
          </Link>
          <Link to="/admin/relatorios/turmas" activeOnlyWhenExact>
            Turmas
          </Link>
          <Link to="/admin/relatorios/avaliacoes">Avaliações</Link>
          <Link to="/admin/relatorios/avaliacoes-empresas">
            Avaliações/Empresas
          </Link>
        </PillMenuContent>
      </PillMenu>

      <div>
        <Switch>
          <Route path="/admin/relatorios/usuarios" exact component={User} />
          <Route path="/admin/relatorios/empresas" exact component={Company} />
          <Route path="/admin/relatorios/turmas" exact component={Classroom} />
          <Route path="/admin/relatorios/avaliacoes" exact component={Class} />
          <Route
            path="/admin/relatorios/avaliacoes-empresas"
            component={ClassCompany}
          />
          <Route path="/admin/relatorios/avaliacoes/:id" component={Class} />
        </Switch>
      </div>
    </Container>
  );
};

export default Reports;
