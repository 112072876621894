import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { MdStar, MdStarBorder } from 'react-icons/md';
import FormClassroom from './FormClassroom';
import api from '../../../services/api';
import { Alert, Confirm } from '../../../utils/alerts';
import appConfig from '../../../configs/app';

import {
  Title,
  Table,
  ActionButton,
  AddButton,
  AButton,
} from '../../../components/AdminComponents';

import { Container } from './styles';
import Modal from '../../../components/Modal';
import { useRemoteConfigs } from '../../../hooks/remote-configs';

const initData: any[] = [];

const getExportLink = (): string => {
  const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);
  return `${appConfig.API_BASE_URL}/classrooms/export?token=${token}`;
};

const Classrooms: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [data, setData] = useState(initData);

  const { ALLOW_NEW_FEATURES } = useRemoteConfigs();

  const loadData = useCallback(async () => {
    const result = await api.get('/classrooms');

    setData(d => [...d, ...result.data]);
    setLoaded(true);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAddItem = useCallback(async id => {
    setModalId(id);
  }, []);

  const handleDeleteItem = useCallback(async id => {
    const ok = await Confirm('Tem certeza que deseja remover este item?');
    if (ok) {
      await api.delete(`/classrooms/${id}`);
      setData(d => d.filter((dd: any) => dd.id !== id));
      Alert('Item deletado com sucesso!');
    }
  }, []);

  // const handleSetDefault = useCallback(async (id: string) => {
  //   const can = await Confirm('Realmente deseja marcar a turma como padrão?');

  //   if (!can) {
  //     return;
  //   }

  //   try {
  //     await api.put(`/api/classrooms/${id}/mark-as-default`);

  //     setData((oldState: any) =>
  //       oldState.map((i: any) => ({
  //         ...i,
  //         is_default: i.id === id,
  //       })),
  //     );
  //   } catch (error) {
  //     const message =
  //       error.response.data.message || 'Houve um erro na requisição!';
  //     Alert(message);
  //   }
  // }, []);

  const onFormSuccess = useCallback(item => {
    setModalId(null);

    setData(d => {
      const index = d.findIndex(i => i.id === item.id);

      if (index !== -1) {
        return d.map(i => (i.id === item.id ? item : i));
      }

      return [item, ...d];
    });
  }, []);

  return (
    <Container>
      <Title>Turmas</Title>
      <AddButton
        onClick={() => {
          handleAddItem('new');
        }}
      >
        Adicionar
      </AddButton>

      <AButton href={getExportLink()} target="blank">
        Exportar Tudo
      </AButton>

      {modalId && (
        <Modal
          setModalId={setModalId}
          title={modalId === 'new' ? 'Nova Turma' : 'Editar Turma'}
        >
          <FormClassroom onFormSuccess={onFormSuccess} itemId={modalId} />
        </Modal>
      )}

      {!loaded && <div>Carregando!</div>}

      {loaded && (
        <Table>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                {ALLOW_NEW_FEATURES && <th>Permitir Assinatura</th>}

                <th>_</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id}>
                  <td>
                    {`${item.name} ${!item.full_access ? '(*Limitado)' : ''}`}
                  </td>

                  {ALLOW_NEW_FEATURES && (
                    <td>
                      {item.allow_subscription ? 'Sim' : ''}
                      {item.allow_subscription && item.is_trial
                        ? ' (padrão de cadastro)'
                        : ''}
                    </td>
                  )}

                  <td className="actions">
                    {/* <ActionButton
                      onClick={() => {
                        handleSetDefault(item.id);
                      }}
                    >
                      {item.is_default ? (
                        <MdStar color="#ee3" size={20} />
                      ) : (
                        <MdStarBorder color="#000" size={20} />
                      )}
                    </ActionButton> */}
                    <ActionButton
                      onClick={() => {
                        handleAddItem(item.id);
                      }}
                    >
                      <FiEdit color="#000" size={18} />
                    </ActionButton>

                    <ActionButton onClick={() => handleDeleteItem(item.id)}>
                      <FiXCircle color="#000" size={18} />
                    </ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      )}
    </Container>
  );
};

export default Classrooms;
