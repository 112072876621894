import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  /* height: 100vh; */

  .fc-header-toolbar.fc-toolbar {
    display: block;
  }

  @media (min-width: 768px) {
    .fc-header-toolbar.fc-toolbar {
      display: flex;
    }
  }

  .fc-toolbar-title {
    font-family: 'sofia_proregular', serif;
    font-size: 24px;
    color: #959595;
  }

  .fc-toolbar-chunk {
    button {
      color: #18e29a;
      background-color: #e1f7ef;
      border-color: #e1f7ef;

      &:hover {
        background-color: #16b77e;
        border-color: #16b77e;
      }
    }

    button.fc-button-active {
      background-color: #16b77e !important;
      border-color: #16b77e !important;
    }
  }

  th.fc-col-header-cell.fc-day {
    background-color: #e1f7ef;
    color: #18e29a;
  }

  .fc-daygrid-day-number {
    color: #16b77e;
  }

  .fc-daygrid-day.fc-day.fc-day-today .fc-daygrid-day-number {
    color: #e1f7ef;
    background-color: #16b77e;
  }
`;
