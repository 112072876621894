import styled from 'styled-components';

export const Parent = styled.div`
  padding: 10px;
  background: #efefef;
  border-radius: 8px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;

  /* display: block;
  outline: 1px solid red; */

  & > div:first-child {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    margin: 15px 6px;

    background-color: #fff;
  }

  & > div:last-child {
    flex: 1;
    padding: 0;
    width: 100%;

    /* outline: 1px solid red; */

    & > h2 {
      color: #aeabab;
      font-family: 'sofia_probold', serif;
      font-size: 0.9em;
    }

    & > h3 {
      color: #aeabab;
      font-family: 'sofia_proregular', serif;
      font-size: 0.65em;
    }

    & > span {
      color: #c6c3c2;
      font-family: 'sofia_proregular', serif;
      font-size: 0.52em;
    }

    & > p {
      color: #a19e9e;
      font-family: 'sofia_proregular', serif;
      font-size: 0.77em;
      padding-top: 5px;
      white-space: pre-line;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    width: inherit;

    & > div:last-child {
      flex: 1;
      padding: 0 20px;
    }
  }
`;

export const Container = styled.div`
  padding: 20px;
  background: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 20px;

  -webkit-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.16);

  .images {
    margin-top: 20px;
    img {
      /* display: block; */
      /* width: 100%; */
      height: 100px;
      /* width: 300px; */
      margin: 0 !important;
      padding: 0 !important;
      object-fit: cover;
    }
    & > button {
      position: relative;
      border: 0;
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin: 0 !important;
      padding: 0 !important;
      /* outline: 1px solid red; */
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;

  /* display: block;
  outline: 1px solid red; */

  & > div:first-child {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    overflow: hidden;

    margin: 30px 14px;

    background-color: #fff;
  }

  & > div:last-child {
    flex: 1;
    padding: 0;
    width: 100%;

    /* outline: 1px solid red; */

    & > h2 {
      color: #aeabab;
      font-family: 'sofia_probold', serif;
      font-size: 1em;
    }

    & > h3 {
      color: #aeabab;
      font-family: 'sofia_proregular', serif;
      font-size: 0.75em;
    }

    & > span {
      color: #c6c3c2;
      font-family: 'sofia_proregular', serif;
      font-size: 0.62em;
    }

    & > p {
      color: #a19e9e;
      font-family: 'sofia_proregular', serif;
      font-size: 0.87em;
      padding-top: 20px;
      white-space: pre-line;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    width: inherit;

    & > div:last-child {
      flex: 1;
      padding: 0 20px;
    }
  }
`;

export const ButtonAnswer = styled.button`
  display: block;
  width: 100%;

  padding: 8px;
  color: #fefaf9;
  background-color: #666;
  font-family: 'sofia_proregular', serif;
  font-size: 0.8em;
  border: 0;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (min-width: 768px) {
    max-width: 180px;
  }
`;

export const FormArea = styled.div`
  padding: 20px;
  background-color: #eee;
  margin-top: 10px;
`;
