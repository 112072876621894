const is_production = process.env.NODE_ENV !== 'development';

// const is_production = true;

declare let window: Window;

const DEFAULT_PROTOCOL = is_production ? 'https' : 'http';
const DEFAULT_HOST = is_production ? 'app.neoxs.com.br' : '192.168.15.192';

const BASE_PROTOCOL =
  window.location && window.location.protocol
    ? window.location.protocol.split(':')[0]
    : DEFAULT_PROTOCOL;

const BASE_HOST =
  window.location && window.location.host
    ? window.location.host.split(':')[0]
    : DEFAULT_HOST;

// const BASE_PROTOCOL = is_production ? 'https' : 'http';
// const BASE_HOST = is_production ? 'app.neoxs.com.br' : 'localhost';

export default {
  is_production,
  MODULE_CHAT_ENABLED: true,
  MODULE_LOCAL_NOTIFICATION_ENABLED: false,

  BASE_PROTOCOL,
  BASE_HOST,

  analytics_id: 'UA-174190726-1',
  storagePrefix: '@neoxs',
  // eslint-disable-next-line no-console
  ONESIGNAL_ENABLED: BASE_HOST === 'app.neoxs.com.br',
  ONESIGNAL_APPID: 'f2aa04e8-d270-4ac8-a83b-722a1b98af34',
  ONESIGNAL_SAFARI_WEB_ID:
    'web.onesignal.auto.4787ada6-f101-40da-894e-0a68fad84e0f',

  API_BASE_URL: `${BASE_PROTOCOL}://${BASE_HOST}:3333`,
  IO_BASE_URL: `${BASE_PROTOCOL}://${BASE_HOST}:3334`,
  imageUrl: `${BASE_PROTOCOL}://${BASE_HOST}:3333/files`,

  // IO_BASE_URL: is_production
  //   ? `https://app.neoxs.com.br:3334`
  //   : 'http://192.168.15.192:3334',

  // API_BASE_URL: is_production
  //   ? 'https://app.neoxs.com.br:3333'
  //   : 'http://192.168.15.192:3333',

  // imageUrl: is_production
  //   ? 'https://app.neoxs.com.br:3333/files'
  //   : 'http://192.168.15.192:3333/files',
};
