import styled from 'styled-components';

export const Container = styled.div`
  padding: 4px;

  border: 1px solid #ccc;
  margin: 2px 2px 10px 2px;
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  padding: 4px;
`;

export const SearchForm = styled.div`
  display: flex;
  flex: 1;

  input.input_value {
    display: flex;
    flex: 1;
    border: 0;
    border: 1px solid #ccc;
    padding: 4px 8px;
    font-family: 'sofia_proregular', serif;
    font-size: 0.8em;
    border-right: 0;
  }

  .loading-area {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  select {
    /* display: flex; */
    /* flex: 1; */
    border: 0;
    border: 1px solid #ccc;
    padding: 4px 8px;
    font-family: 'sofia_proregular', serif;
    font-size: 0.8em;
    border-right: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 4px 8px;
    border-left: 0;
  }
`;

export const SearchSubmitButton = styled.button``;

export const AddButton = styled.button`
  background-color: inherit;
  border: 0;
  padding: 0 20px;

  /* padding: 8px 20px; */
  background-color: #666;
  /* margin: 4px; */
  color: #fff;
  /* font-size: 1em; */
  /* border-radius: 8px; */
  /* display: flex;
  flex: 1; */
  font-family: 'sofia_proregular', serif;
  font-size: 0.8em;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
