import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import {
  Form,
  Input,
  Button,
  Select,
  Image,
  Video,
  InputDate,
  FormRefInterface,
  getValidationErrors,
} from '../../../../components/Form';

interface SignFormData {
  name: string;
  password: string;
}

interface FormProps {
  onFormSuccess?: any;
  itemId?: string | undefined | null;
}

const FormLive: React.FC<FormProps> = ({
  onFormSuccess,
  itemId,
}: FormProps) => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();

  const [initialData, setInitialData] = useState({});
  const [classrooms, setClassrooms] = useState([]);

  const [type, setType] = useState('');

  const loadData = useCallback(async () => {
    try {
      const classroomResult = await api.get(`/classrooms`);
      setClassrooms(classroomResult.data);

      if (itemId !== 'new') {
        const { data } = await api.get(`/live-classes/${itemId}`);
        setType(data.video_type);
        setInitialData(data);
      } else {
        setInitialData({
          date: new Date(),
        });
      }
    } catch (error) {}
  }, [itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: SignFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          classroom_id: Yup.string().required('A turma é obrigatória!'),
          name: Yup.string().required('Nome é obrigatório!'),
          date: Yup.date().required('A data é obrigatória!'),
          video_type: Yup.string().notRequired(),
          video_url: Yup.string().notRequired(),
          thumbnail: Yup.string()
            .matches(/^.*\.(jpg|JPG|gif|GIF|png|PNG|jpeg|JPEG)$/, {
              message: 'O Arquivo deve ser do tipo imagem!',
              excludeEmptyString: true,
            })
            .notRequired(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const result =
          itemId === 'new'
            ? await api.post(`/live-classes`, data)
            : await api.put(`/live-classes/${itemId}`, data);

        if (onFormSuccess) {
          onFormSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer o login, cheque as credenciais!',
        });
      }
    },
    [addToast, itemId, onFormSuccess],
  );

  const typeChange = useCallback(event => {
    setType(event.target.value);
    // console.log('event', event.target.value);
  }, []);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialData={initialData}
    >
      <Image type="text" name="thumbnail" label="Imagem de capa" />

      <Select name="classroom_id" label="Turma">
        <option value="">----</option>
        {classrooms.map((classroom: any) => (
          <option key={classroom.id} value={classroom.id}>
            {classroom.name}
          </option>
        ))}
      </Select>

      <Input type="text" name="name" label="Nome" />

      <InputDate name="date" label="Data" />

      <Select name="video_type" label="Tipo" onChange={typeChange} value={type}>
        <option value="">----</option>
        <option value="youtube">YouTube</option>
        <option value="zoom">Zoom</option>
        <option value="file">Arquivo</option>
      </Select>

      {(type === 'youtube' || type === 'zoom') && (
        <Input type="text" name="video_url" label="Link" />
      )}

      {type === 'file' && <Video type="text" name="video_url" label="Video" />}

      <Button type="submit">Salvar</Button>
    </Form>
  );
};

export default FormLive;
