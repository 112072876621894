/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  SelectHTMLAttributes,
  useEffect,
  useState,
  useCallback,
  useRef,
  ReactNode,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
  children: ReactNode;
}

const Select: React.FC<InputProps> = ({
  name,
  icon: Icon,
  label,
  children,
  ...rest
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [value, setValue] = useState('');

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlue = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
        {Icon && <Icon size={20} />}
        {/* <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlue}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        /> */}
        <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlue}
          defaultValue={defaultValue}
          value={value}
          ref={inputRef}
          onChange={e => {
            setValue(e.target.value);
          }}
          {...rest}
        >
          {children}
        </select>
      </Container>
      {error && (
        <Error>
          <FiAlertCircle color="#c53030" size={20} />
          {error}
        </Error>
      )}
    </>
  );
};

export default Select;
