import { useFormikContext } from 'formik';
import React, { ReactNode } from 'react';

// import { Container } from './styles';
import { Container, Select, Label, ErrorMessage } from '../styles';

interface Props {
  name: string;
  label: string;
}

const BooleanField: React.FC<Props> = ({ name, label }: Props) => {
  const { errors, values, handleChange, setFieldValue } = useFormikContext<
    any
  >();

  return (
    <Container>
      <Label>{label}</Label>
      <Select
        name={name}
        value={values[name] ? 'true' : 'false'}
        onChange={e => setFieldValue(name, e.target.value === 'true')}
      >
        <option value="true">SIM</option>
        <option value="false">NÃO</option>
      </Select>
      {errors[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </Container>
  );
};

export default BooleanField;
