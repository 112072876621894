import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;

  h1 {
    color: #838180;
    font-size: 40px;
    font-family: 'sofia_proregular', serif;
    border-bottom: 2px solid #18e29a;
    padding-bottom: 20px;
  }

  img {
    padding-top: 20px;
    max-width: 178px;
  }

  p {
    font-family: 'sofia_proregular', serif;
    font-size: 1em;
    padding-top: 20px;
  }
`;
