import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from '../../components/Header';

import { Container, Content } from './styles';
import Menu from '../../components/Menu';

import Home from './Home';
import Companies from './Companies';
import Classrooms from './Classrooms';
import Users from './Users';
import UserProfile from './UserProfile';
import Lives from './Lives';
import Classes from './Classes';
import ClassDetails from './ClassDetails';
import Notifications from './Notifications';
import Quizzes from './Quizzes';
import Reports from './Reports';
import NotFound from './NotFound';
import Activities from './Users/Activities';
import PlansList from './plans/PlansList';

const Admin: React.FC = () => {
  return (
    <>
      <Header bgColor="black" />
      <Container>
        <Menu bgColor="black" />
        <Content>
          <Switch>
            <Route path="/admin" exact component={Home} />
            <Route path="/admin/companies" component={Companies} />
            <Route path="/admin/classrooms" component={Classrooms} />
            <Route path="/admin/users/:id" exact component={UserProfile} />
            <Route path="/admin/users" exact component={Users} />
            <Route
              path="/admin/users/:id/activities"
              exact
              component={Activities}
            />
            <Route path="/admin/lives" component={Lives} />
            <Route path="/admin/classes" exact component={Classes} />
            <Route path="/admin/quiz" exact component={Quizzes} />
            <Route path="/admin/classes/:id" exact component={ClassDetails} />
            <Route path="/admin/notificacoes" exact component={Notifications} />
            <Route path="/admin/relatorios" component={Reports} />
            <Route path="/admin/plans" component={PlansList} />
            <Route path="/admin/not-found" component={NotFound} />
          </Switch>
        </Content>
      </Container>
    </>
  );
};

export default Admin;
