import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import {
  Form,
  Input,
  Button,
  Doc,
  FormRefInterface,
  getValidationErrors,
} from '../../../../components/Form';

interface FormData {
  name: string;
  password: string;
}

interface FormProps {
  onFormSuccess?: any;
  itemId?: string | undefined | null;
  classId?: string | undefined | null;
}

const FormAttachment: React.FC<FormProps> = ({
  onFormSuccess,
  itemId,
  classId,
}: FormProps) => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();

  const [initialData, setInitialData] = useState({});

  const loadData = useCallback(async () => {
    try {
      if (itemId === 'new') {
        return;
      }
      const { data } = await api.get(
        `/classes/${classId}/attachments/${itemId}`,
      );
      setInitialData(data);
    } catch (error) {
      console.log('error', error.response);
    }
  }, [classId, itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório!'),
          file: Yup.string().required('O arquivo é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const result =
          itemId === 'new'
            ? await api.post(`/classes/${classId}/attachments`, data)
            : await api.put(`/classes/${classId}/attachments${itemId}`, data);

        if (onFormSuccess) {
          onFormSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        console.log('error', error.response);

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer o login, cheque as credenciais!',
        });
      }
    },
    [addToast, classId, itemId, onFormSuccess],
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialData={initialData}
    >
      <Input type="text" name="name" label="Nome:" />

      <Doc type="text" name="file" label="Arquivo" />

      <Button type="submit">Salvar</Button>
    </Form>
  );
};

export default FormAttachment;
