import styled, { css } from 'styled-components';

interface ContainerProps {
  unread?: boolean;
}

export const Container = styled.button<ContainerProps>`
  display: flex;

  padding: 10px 20px;
  margin-bottom: 2px;
  width: 100%;
  border: 0;
  background: inherit;
  align-items: center;

  ${props =>
    props.unread &&
    css`
      background-color: #e9f5f1;
    `}

  &:hover {
    background: #ccc;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:first-child {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center center;
    }
    /* img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;

      object-fit: cover;
      object-position: center center;
    } */
  }

  & > div:nth-child(2) {
    flex: 1;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 5px;
    /* outline: 1px solid red; */
    /* max-width: 160px; */

    width: 50%;

    @media (min-width: 768px) {
      max-width: 160px;
    }

    p,
    h4,
    h5 {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
    }

    h4 {
      font-size: 1em;
      font-family: 'sofia_proregular';
      color: #838180;
    }

    h5 {
      font-size: 0.8em;
      font-family: 'sofia_proregular';
      color: #838180;
    }

    p {
      font-size: 0.8em;
      font-family: 'sofia_proregular';
      color: #8ff0d1;
      display: block;
      text-align: left;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  & > div:last-child {
    width: 20px;
    /* outline: 1px solid red; */

    span {
      display: block;
      width: 10px;
      height: 10px;
      background-color: #82c85a;
      border-radius: 50%;
      overflow: hidden;

      object-fit: cover;
      object-position: center center;

      &.offline {
        background-color: red;
        opacity: 0.4;
      }
    }
  }
`;
