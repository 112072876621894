import styled, { css } from 'styled-components';

interface ContainerProps {
  enabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  img {
    width: 100%;
  }

  ${({ enabled }) =>
    !enabled &&
    css`
      opacity: 0.6;
      /* &:after {
        position: absolute;
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      } */

      img {
        filter: grayscale(100%);
      }
    `}
`;
