import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { Container, Check } from './styles';

interface Props {
  onClickTerms(): void;
  onClickPrivacy(): void;
}

const Terms: React.FC<Props> = ({ onClickTerms, onClickPrivacy }: Props) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<any>();

  const checked = useMemo(() => {
    return values?.terms ? values?.terms : false;
  }, [values]);

  return (
    <Container>
      <Check
        type="button"
        checked={checked}
        onClick={() => setFieldValue('terms', !checked)}
      />

      {'Li e aceito os '}
      <button type="button" className="textButton" onClick={onClickTerms}>
        Termos de uso
      </button>
      {' e '}
      <button type="button" className="textButton" onClick={onClickPrivacy}>
        Política de privacidade
      </button>
    </Container>
  );
};

export default Terms;
