import styled, { css } from 'styled-components';

interface ContainerPropos {
  proportion?: '16:9' | '9:16' | '1:1';
}

export const Container = styled.div<ContainerPropos>`
  width: 100%;

  & > div {
    position: relative;
    padding-bottom: 56.2%;

    ${({ proportion }) =>
      proportion &&
      proportion === '9:16' &&
      css`
        padding-bottom: 156.2%;
      `}

    ${({ proportion }) =>
      proportion &&
      proportion === '1:1' &&
      css`
        /* background-color: #000000; */
        padding-bottom: 100%;
      `}

    img {
      display: block;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    video {
      display: block;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    video::-internal-media-controls-download-button {
      display: none;
    }

    video::-webkit-media-controls-enclosure {
      overflow: hidden;
    }

    video::-webkit-media-controls-panel {
      width: calc(100% + 30px); /* Adjust as needed */
    }
  }
`;
