import React from 'react';

import { Container } from './styles';

import Logo from '../../../assets/logo.svg';

const Informations: React.FC = () => {
  return (
    <Container>
      <header>
        <h1>Informações</h1>
      </header>
      <div>
        <img src={Logo} alt="Neoxs" />
        <p>
          Nós acreditamos que vender mais e vender melhor é a ambição de
          profissionais de alto desempenho, como você. É por isso que sua
          empresa está fazendo este investimento na sua carreira. E nossa missão
          na neoxs é ajudar você nesse caminho, trazendo todo mês o que
          realmente funciona para vender mais em um mundo em constante
          transformação.
          <br />
          <br />
          Conte com a gente e sempre que quiser fazer um comentário, uma crítica
          ou dar uma sugestão, somos todos ouvidos. Só mandar um e-mail para
          {` `}
          <a href="mailto:neoxs@neoxs.com.br" target="blank">
            neoxs@neoxs.com.br
          </a>
        </p>
      </div>
    </Container>
  );
};

export default Informations;
