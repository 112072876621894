import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import socketIOClient from 'socket.io-client';

import appConfig from '../../configs/app';
import { useAuth } from '../auth';
import { useToast } from '../toast';

import getStateValue from '../../utils/getStateValue';
// import api from '../services/api';
// import storage from '../services/storage';

import ChatBox from './ChatBox';

import {
  OnMessageInterface,
  ContactIntereface,
  MessageInterface,
} from './ChatInterfaces';

interface ChatContextData {
  openned: boolean;
  toggle(): void;
  close(): void;
  openContact(contact: any): void;
  closeContact(): void;
  openedContact: any;
  messagesContainerRef: any;
  contacts: ContactIntereface[];
}

export const ChatContext = createContext<ChatContextData>(
  {} as ChatContextData,
);

interface ChatProviderProps {
  children?: ReactNode;
}

let socket: SocketIOClient.Socket | null;

export const ChatProvider: React.FC = ({ children }: ChatProviderProps) => {
  const [openned, setOpenned] = useState(false);
  const { token, user } = useAuth();
  const { addToast } = useToast();

  const [online, setOnline] = useState(false);
  const [contacts, setContacts] = useState<ContactIntereface[]>([]);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [openedContact, setOpenedContact] = useState<ContactIntereface | null>(
    null,
  );

  const messagesScrollToBottom = useCallback(() => {
    const container = messagesContainerRef.current;

    if (container) {
      const { scrollHeight } = container;
      setTimeout(() => {
        container.scrollTo(0, scrollHeight);
      }, 100);
    }
  }, []);

  const openContact = useCallback(
    (contact: any) => {
      setOpenned(true);
      setOpenedContact(contact);
      setMessages([]);

      // mark message as read
      setContacts(c =>
        c.map(cc =>
          cc.id === contact.id ? { ...cc, last_message_unread: false } : cc,
        ),
      );

      if (!socket) {
        return;
      }

      socket.emit('request_messages', contact.id, (_messages: any) => {
        setMessages(_messages);
        messagesScrollToBottom();
      });

      socket.emit('messages_read', contact.id);
      socket.emit('open_contact', contact.id);
    },
    [messagesScrollToBottom],
  );

  const closeContact = useCallback(() => {
    setMessages([]);
    setOpenedContact(null);
    if (!socket) {
      return;
    }
    socket.emit('close_contact');
  }, []);

  // News

  // const emitAuth = useCallback((data: string) => {
  //   if (!socket) {
  //     return;
  //   }

  //   socket.emit('auth', data);
  // }, []);

  // const emitSendMessage = useCallback((data: any) => {
  //   if (!socket) {
  //     return;
  //   }

  //   socket.emit('send_message', data);
  // }, []);

  const onConnect = useCallback(() => {
    setOnline(true);
  }, []);

  const onContactsList = useCallback((result: ContactIntereface[]) => {
    setContacts(result);
  }, []);

  const onMessage = useCallback(
    async (result: OnMessageInterface): Promise<void> => {
      const openedContactExists = await getStateValue<ContactIntereface>(
        setOpenedContact,
      );

      // eslint-disable-next-line prefer-const
      let unread = false;

      // Recebi mensagem de outros e não estou com o chat aberto
      if (!result.from_me && !openedContactExists) {
        addToast({
          title: 'Nova Mensagem',
          description: result.text,
        });
        unread = true;
      }

      // Recebi mensagem de outros e estou com o chat aberto
      if (
        !result.from_me &&
        openedContactExists &&
        openedContactExists.id === result.from_id
      ) {
        setMessages(_messages => [..._messages, result]);
        if (socket) {
          socket.emit('messages_read', openedContactExists.id);
        }
      }

      // Recebi minha mensagem
      if (
        result.from_me &&
        openedContactExists &&
        openedContactExists.id === result.to_id
      ) {
        setMessages(_messages => [..._messages, result]);
      }

      // Add message to contact list
      setContacts(_contacts => {
        return _contacts.map(_contact => {
          return _contact.id === result.from_id || _contact.id === result.to_id
            ? {
                ..._contact,
                last_message: result.text,
                last_message_date: String(new Date()),
                last_message_unread: unread,
              }
            : _contact;
        });
      });

      messagesScrollToBottom();
    },
    [addToast, messagesScrollToBottom],
  );

  const sendMessage = useCallback(async (text: string) => {
    const openedContactExists = await getStateValue<ContactIntereface>(
      setOpenedContact,
    );

    if (openedContactExists && socket) {
      socket.emit('send_message', {
        to_id: openedContactExists.id,
        text,
      });
    }
  }, []);

  const onUserOnline = useCallback((user_id: string) => {
    setContacts(_contacts => {
      return _contacts.map(c =>
        c.id === user_id ? { ...c, online: true } : c,
      );
    });
  }, []);

  const onUserOffline = useCallback((user_id: string) => {
    setContacts(_contacts => {
      return _contacts.map(c =>
        c.id === user_id ? { ...c, online: false } : c,
      );
    });
  }, []);

  const onError = useCallback(
    error => {
      addToast({
        type: 'error',
        title: 'Erro no Chat',
        description: error,
      });
    },
    [addToast],
  );

  const onAppInfo = useCallback(
    info => {
      addToast({
        type: 'success',
        title: 'Info',
        description: info,
      });
    },
    [addToast],
  );

  const onAppError = useCallback(
    error => {
      addToast({
        type: 'error',
        title: 'Erro no Chat',
        description: error,
      });
    },
    [addToast],
  );

  useEffect(() => {
    if (!appConfig.MODULE_CHAT_ENABLED) {
      return;
    }

    if (!socket && !token) {
      return;
    }

    if (!user || (user && !user?.classroom?.full_access)) {
      return;
    }

    if (socket && !token) {
      socket.disconnect();
      socket = null;
      return;
    }

    if (!socket) {
      socket = socketIOClient(appConfig.IO_BASE_URL, {
        query: {
          token,
        },
      });
    }

    if (socket) {
      socket.on('connect', onConnect);
      socket.on('contacts_list', onContactsList);
      socket.on('message', onMessage);
      socket.on('user_online', onUserOnline);
      socket.on('user_offline', onUserOffline);
      socket.on('error', onError);
      socket.on('app_error', onAppError);
      socket.on('app_info', onAppInfo);
    }

    // return function clear() {
    //   socket.off('connect', onConnect);
    //   socket.off('contacts_list', onContactsList);
    //   socket.off('message', onMessage);
    //   socket.off('user_online', onUserOnline);
    //   socket.off('user_offline', onUserOffline);
    //   socket.off('error', onError);
    //   socket.off('app_error', onAppError);
    // };

    // return (): boolean => {
    //   if (!socket) {
    //     return;
    //   }

    //   socket.off('connect', onConnect);
    //   socket.off('contacts_list', onContactsList);
    //   socket.off('message', onMessage);
    //   socket.off('user_online', onUserOnline);
    //   socket.off('user_offline', onUserOffline);
    //   socket.off('error', onError);
    //   socket.off('app_error', onAppError);
    //   return true;
    // };
  }, [
    onAppError,
    onAppInfo,
    onConnect,
    onContactsList,
    onError,
    onMessage,
    onUserOffline,
    onUserOnline,
    token,
    user,
  ]);

  const toggle = useCallback(async () => {
    if (openned) {
      closeContact();
    }
    setOpenned(!openned);
  }, [closeContact, openned]);

  const close = useCallback(async () => {
    if (!openned) {
      return;
    }
    setOpenned(false);
  }, [openned]);

  return (
    <ChatContext.Provider
      value={{
        openned,
        toggle,
        close,
        openContact,
        closeContact,
        openedContact,
        messagesContainerRef,
        contacts,
      }}
    >
      {children}
      {online && openned && (
        <ChatBox
          contacts={contacts}
          closeChat={close}
          messages={messages}
          sendMessage={sendMessage}
        />
      )}
    </ChatContext.Provider>
  );
};

export function useChat(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be user with in an ChatProvider');
  }

  return context;
}
