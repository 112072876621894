import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../hooks/auth';
import configs from '../../../configs/app';

import api from '../../../services/api';

import Stamp from './Stamp';

import { Container, Title, StampList, CertList } from './styles';

import { Alert } from '../../../utils/alerts';

const Stamps: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [preInfo, setPreInfo] = useState({
    stamps: [],
    countStamps: 0,
  });

  const { user } = useAuth();

  useEffect(() => {
    const load = async () => {
      try {
        const { data: stamps } = await api.get(`/api/stamps/user/${user.id}`);
        setPreInfo(state => ({
          ...state,
          stamps,
          countStamps: stamps.filter((s: any) => s.enabled).length,
        }));

        console.log('stamps', stamps);
      } catch (error) {
        console.log('error', error);
      }
    };

    load();
  }, [user.id]);

  const handleOpenNoCert = useCallback(() => {
    Alert(
      'O certificado não está disponível. Favor concluir as avaliações para adquirir os selos!',
    );
  }, []);

  return (
    <Container>
      <Title>Selos e Cerficados</Title>
      <StampList>
        <ul>
          {preInfo.stamps.map((item: any) => (
            <li key={item.id}>
              <Stamp item={item} enabled={item.enabled} />
            </li>
          ))}
        </ul>
      </StampList>

      <CertList>
        <ul>
          <li>
            {preInfo.countStamps >= 6 ? (
              <a
                href={`${configs.API_BASE_URL}/api/certs/cert-6selos-${user.id}.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Download do Certificado de 6 Módulos
              </a>
            ) : (
              <button type="button" onClick={handleOpenNoCert}>
                Download do Certificado de 6 Módulos
              </button>
            )}
          </li>
          <li>
            {preInfo.countStamps >= 12 ? (
              <a
                href={`${configs.API_BASE_URL}/api/certs/cert-12selos-${user.id}.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Download do Certificado de 12 Módulos
              </a>
            ) : (
              <button type="button" onClick={handleOpenNoCert}>
                Download do Certificado de 12 Módulos
              </button>
            )}
          </li>
        </ul>
      </CertList>
    </Container>
  );
};

export default Stamps;
