import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';
import { Container, Line, Block } from './styles';
import Version from '../../../configs/version';

const Home: React.FC = () => {
  const [data, setData] = useState<any>({});

  const loadData = useCallback(() => {
    // users
    api.get('/users/count').then(response => {
      setData((d: any) => {
        return { ...d, users: response.data.total };
      });
    });

    api.get('/users/count_actives').then(response => {
      setData((d: any) => {
        return { ...d, users_actives: response.data.total };
      });
    });

    // companies
    api.get('/companies/count').then(response => {
      setData((d: any) => {
        return { ...d, companies: response.data.total };
      });
    });

    // classrooms
    api.get('/classrooms/count').then(response => {
      setData((d: any) => {
        return { ...d, classrooms: response.data.total };
      });
    });

    // live classes
    api.get('/live-classes/count').then(response => {
      setData((d: any) => {
        return { ...d, lives: response.data.total };
      });
    });

    // classes
    api.get('/classes/count').then(response => {
      setData((d: any) => {
        return { ...d, classes: response.data.total };
      });
    });

    // classes
    api.get('/avaliations/count').then(response => {
      setData((d: any) => {
        return { ...d, avaliations: response.data.total };
      });
    });

    setData((d: any) => {
      return { ...d, faqs: 0 };
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Container>
      <Line>
        <Block>
          <h1>Usuários</h1>
          <h2>{data.users !== undefined ? data?.users : '...'}</h2>
        </Block>
        <Block>
          <h1>Usuários Ativos</h1>
          <h2>
            {data.users_actives !== undefined ? data?.users_actives : '...'}
          </h2>
        </Block>
        <Block>
          <h1>Empresas</h1>
          <h2>{data.companies !== undefined ? data?.companies : '...'}</h2>
        </Block>
        <Block>
          <h1>Turmas</h1>
          <h2>{data.classrooms !== undefined ? data?.classrooms : '...'}</h2>
        </Block>
      </Line>

      <Line>
        <Block>
          <h1>Aulas</h1>
          <h2>{data.classes !== undefined ? data?.classes : '...'}</h2>
        </Block>
        <Block>
          <h1>Lives</h1>
          <h2>{data.lives !== undefined ? data?.lives : '...'}</h2>
        </Block>
        <Block>
          <h1>Avaliações</h1>
          <h2>{data.avaliations !== undefined ? data?.avaliations : '...'}</h2>
        </Block>
        <Block>
          <h1>Pesquisas</h1>
          <h2>{data.faqs !== undefined ? data?.faqs : '...'}</h2>
        </Block>
      </Line>
      <Line>
        <p>{Version.version}</p>
      </Line>
    </Container>
  );
};

export default Home;
