import React, { useState, useEffect, useCallback } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import FormCompany from './FormCompany';
import api from '../../../services/api';
import { Alert, Confirm } from '../../../utils/alerts';
import PageLoading from '../../../components/PageLoading';
import PageError from '../../../components/PageError';
import appConfig from '../../../configs/app';

import {
  Title,
  Table,
  ActionButton,
  AddButton,
  AButton,
} from '../../../components/AdminComponents';

import { Container } from './styles';
import Modal from '../../../components/Modal';

const initData: any[] = [];

const getExportLink = (): string => {
  const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);
  return `${appConfig.API_BASE_URL}/companies/export?token=${token}`;
};

const Companies: React.FC = () => {
  const [loading, setLoaading] = useState(true);
  const [errorMessage, setErrorMessaage] = useState('');

  const [modalId, setModalId] = useState(null);
  const [data, setData] = useState(initData);

  const loadData = useCallback(async () => {
    try {
      const result = await api.get('/companies');

      setData(d => [...d, ...result.data]);
      setLoaading(false);
    } catch (error) {
      setErrorMessaage(
        error.response?.data?.message || 'Houve um error na requisição!',
      );
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAddItem = useCallback(async id => {
    setModalId(id);
  }, []);

  const handleDeleteItem = useCallback(async id => {
    const ok = await Confirm('Tem certeza que deseja remover este item?');
    if (ok) {
      await api.delete(`/companies/${id}`);
      setData(d => d.filter((dd: any) => dd.id !== id));
      Alert('Item deletado com sucesso!');
    }
  }, []);

  const onFormSuccess = useCallback(item => {
    setModalId(null);

    setData(d => {
      const index = d.findIndex(i => i.id === item.id);

      if (index !== -1) {
        return d.map(i => (i.id === item.id ? item : i));
      }

      return [item, ...d];
    });
  }, []);

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <Title>Empresas</Title>
      <AddButton
        onClick={() => {
          handleAddItem('new');
        }}
      >
        Adicionar
      </AddButton>

      <AButton href={getExportLink()} target="blank">
        Exportar Tudo
      </AButton>

      {modalId && (
        <Modal
          setModalId={setModalId}
          title={modalId === 'new' ? 'Nova Empresa' : 'Editar Empreesa'}
        >
          <FormCompany onFormSuccess={onFormSuccess} itemId={modalId} />
        </Modal>
      )}

      <Table>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>_</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td className="actions">
                  <ActionButton
                    onClick={() => {
                      handleAddItem(item.id);
                    }}
                  >
                    <FiEdit color="#000" size={18} />
                  </ActionButton>

                  <ActionButton onClick={() => handleDeleteItem(item.id)}>
                    <FiXCircle color="#000" size={18} />
                  </ActionButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </Container>
  );
};

export default Companies;
