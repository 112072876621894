import styled from 'styled-components';

export const Container = styled.div``;

export const Activity = styled.div`
  border: 1px solid #ccc;
  padding: 20px;

  h2 {
    font-size: 1em;
  }

  i {
    font-size: 0.8em;
  }

  p {
    padding-top: 10px;
  }
`;
