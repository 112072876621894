import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signUpBackgroundImg from '../../../assets/sign-up-background.png';

export const Container = styled.div`
  display: flex;
  height: 100vh;

  align-items: stretch;
`;

export const Content = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: flex-start; */
  /* place-content: center; */
  width: 100%;
  max-width: 700px;
  height: 100vh;
  overflow-y: scroll;

  img {
    margin-top: 10vh;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signUpBackgroundImg});
  /* background-size: cover; */
  background-repeat: repeat-x;
  background-position: top center;
  background-color: #17e29a;
`;

const apperFormRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* place-content: center; */
  width: 100%;
  max-width: 700px;

  animation: ${apperFormRight} 1s;
  form {
    margin: 20px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 14px;
      font-size: 24px;
    }

    a {
      display: block;
      color: #f4ede8;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    display: flex;
    color: #f4ede8;
    margin-top: 24px;
    text-decoration: none;
    align-items: center;
    transition: color 0.2s;

    svg {
      margin-right: 15px;
    }

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }
  }
`;

export const PlanList = styled.div`
  display: block;
  width: 90%;
  margin: 20px;
  max-width: 400px;
`;

export const Plan = styled.button`
  display: block;
  background-color: #17e29a;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
`;

export const BackButton = styled.button`
  background-color: inherit;
  color: #000;
  font-size: 1.1em;
  border: 0;
`;
