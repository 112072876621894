import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Container, BoxList, Box, Frag, ButtonOpen } from '../result.styles';
import api from '../../../../services/api';
import DateString from '../../../../components/DateString';
import appConfig from '../../../../configs/app';

import PieChart from '../PIeChart';

const scrollToId = (id: string): void => {
  document.getElementById(id)?.scrollIntoView({
    behavior: 'smooth',
  });
};

const Class: React.FC = () => {
  const [res, setRes] = useState<any>();
  const [chartValues, setChartValues] = useState<any[]>([]);
  const [scrolled, setScrolled] = useState<any>(false);
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();

  const { id } = useParams<any>();

  const load = useCallback(async () => {
    const { data: cr } = await api.get('/companies');
    console.log('companies', cr);
    setCompanies(cr);
    /* const { data } = await api.get('/reports/class/users');
    setChartValues(data.items);
    setRes(data); */
  }, []);

  const load2 = useCallback(async () => {
    const { data } = await api.get(
      `/reports/class/users${
        selectedCompany ? `?company_id=${selectedCompany}` : ''
      }`,
    );
    setChartValues(data.items);
    setRes(data);
    console.log('load2', data);
  }, [selectedCompany]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    load2();
  }, [load2]);

  const handleDetails = useCallback(
    async (item_id: string, opened: boolean) => {
      const { data } = opened
        ? { data: [] }
        : await api.get(
            `/reports/class/users/${item_id}${
              selectedCompany ? `?company_id=${selectedCompany}` : ''
            }`,
          );

      setRes((r: any) => {
        return {
          ...r,
          items: r.items.map((item: any) => {
            if (item.id === item_id) {
              return { ...item, opened: !opened, data };
            }

            return item;
          }),
        };
      });
    },
    [selectedCompany],
  );

  useEffect(() => {
    if (id && res && !scrolled) {
      handleDetails(id, false);
      setScrolled(true);
      setTimeout(() => {
        scrollToId(id);
      }, 500);
    }
  }, [id, handleDetails, res, scrolled]);

  const handleExport = useCallback(
    (item_id: string) => {
      const token = localStorage.getItem(`${appConfig.storagePrefix}:token`);
      const url = `${
        appConfig.API_BASE_URL
      }/reports/class/users/${item_id}?token=${token}&export=true${
        selectedCompany ? `&company_id=${selectedCompany}` : ''
      }`;
      window.open(url, 'blank');
    },
    [selectedCompany],
  );

  if (!res) {
    return <div>Carregando</div>;
  }

  return (
    <Container>
      <h2>Avaliações</h2>

      <select
        onChange={e =>
          setSelectedCompany(e.target.value ? e.target.value : undefined)
        }
        value={selectedCompany}
      >
        <option value="">Todas Empresas</option>
        {companies &&
          companies.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </select>

      <PieChart
        title="Usuários por Empresa"
        values={chartValues}
        keys={{ value: 'user_avaliation_count', title: 'name' }}
      />
      <BoxList>
        <Box>
          <h2>Avaliações</h2>
          <p>{res.total_classes}</p>
        </Box>
        <Box>
          <h2>Respostas</h2>
          <p>{res.total_avaliations}</p>
        </Box>
      </BoxList>
      <table>
        <thead>
          <tr>
            <th>.</th>
            <th>Nome</th>
            <th>Turma</th>
            <th>Quant. de Questões</th>
            <th>Quant. de Respostas</th>
            <th>Aproveitamento</th>
            <th>Exportar</th>
          </tr>
        </thead>
        <tbody>
          {res.items.map((item: any) => (
            <Frag key={item.id}>
              <tr id={item.id}>
                <td>
                  <ButtonOpen
                    opened={item.opened}
                    onClick={() => handleDetails(item.id, !!item.opened)}
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.classroom_name}</td>
                <td>{item.questions_count}</td>
                <td>{item.user_avaliation_count}</td>
                <td>{item.utilization}</td>
                <td>
                  <button type="button" onClick={() => handleExport(item.id)}>
                    Exportar
                  </button>
                </td>
              </tr>
              {!!item.opened && (
                <tr>
                  <td colSpan={5}>
                    <table>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Data</th>
                          <th>Empresa</th>
                          <th>Resultado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data.map((subitem: any) => (
                          <tr key={subitem.id}>
                            <td>
                              {!!subitem.created_at && (
                                <DateString>{subitem.created_at}</DateString>
                              )}
                            </td>
                            <td>{subitem.name}</td>
                            <td>{subitem.company_name}</td>
                            <td>{subitem.result}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </Frag>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default Class;
