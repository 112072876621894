import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 2.5em;
`;

export const StampList = styled.div`
  margin-top: 20px;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    /* width: 16.6%; */
    flex: 1;
    flex-basis: 100%;
    padding: 10px;
  }

  @media (min-width: 768px) {
    li {
      flex-basis: 50%;
    }
  }

  @media (min-width: 990px) {
    li {
      flex-basis: 25%;
    }
  }
`;

export const CertList = styled.div`
  margin-top: 20px;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    /* width: 16.6%; */
    flex: 1;
    flex-basis: 100%;
    padding: 10px;
    text-align: center;
  }

  button,
  a {
    background-color: #17e29a;
    padding: 20px;
    color: #fff;
    border: 0;
  }

  button {
    background-color: #ccc;
  }

  @media (min-width: 768px) {
    li {
      flex-basis: 50%;
    }
  }
`;
