import React, { useState, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import api, { apiError, ApiError } from '../../../../services/api';
import { IPlan } from '../interfaces';

import { Title } from '../../../../components/AdminComponents';

import {
  Table,
  ActionButtons,
  Container,
  Header,
  Modal,
} from '../../../../components/admin-elements';

import PlanEdit from '../PlanEdit';
import PageLoading from '../../../../components/PageLoading';
import PageError from '../../../../components/PageError';

const resource = '/api/plans';

const PlansList: React.FC = () => {
  const [params, setParams] = useState<any>({});
  const [modal, setModal] = useState<string | undefined>();
  // 'ae2b2725-1246-43f1-95fa-852445f7c96d',

  const queryClient = useQueryClient();

  const plans = useQuery<any, ApiError, IPlan[], any>(
    [resource],
    async ({ queryKey }) => {
      const [url] = queryKey;
      const { data } = await api.get(url);

      return data;
    },
  );

  const deletePlans = useMutation(
    async (id: string) => {
      await new Promise(r => setTimeout(r, 5000));
      const { data } = await api.delete(`${resource}/${id}`);
      return data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([resource, params]);
      },
    },
  );

  const onCreate = useCallback(
    (data: any) => {
      setModal(undefined);
      queryClient.invalidateQueries([resource]);
    },
    [queryClient],
  );

  const onUpdate = useCallback(
    (data: any) => {
      setModal(undefined);
      queryClient.invalidateQueries([resource]);
    },
    [queryClient],
  );

  const onCancel = useCallback(() => {
    setModal(undefined);
  }, []);

  if (plans.isError) {
    return <PageError message={apiError(plans.error)} />;
  }

  if (plans.isLoading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <Title>Planos</Title>

      <Header
        onClickAdd={() => {
          setModal('new');
        }}
      />

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>_</th>
          </tr>
        </thead>
        <tbody>
          {plans.data?.map(item => (
            <tr key={item.id}>
              <td
                style={item.enabled ? {} : { textDecoration: 'line-through' }}
              >
                {item.name}
              </td>
              <td className="actions">
                <ActionButtons
                  onClickUpdate={() => {
                    setModal(item.id);
                  }}
                  onClickDelete={() => {
                    deletePlans.mutate(item.id);
                  }}
                  isDeleting={
                    deletePlans.isLoading && deletePlans.variables === item.id
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {modal && (
        <Modal>
          <PlanEdit
            resource={resource}
            id={modal}
            onCancel={onCancel}
            onCreate={onCreate}
            onUpdate={onUpdate}
          />
        </Modal>
      )}
    </Container>
  );
};

export default PlansList;
