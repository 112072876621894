import React, { useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import api from '../../../services/api';
import { mergeArrayNoRepeat } from '../../../utils/arrayFilters';

import PageError from '../../../components/PageError';
import InfinityLoading from '../../../components/InfinityLoading';
import ForumForm from './ForumForm';
import ForumComment from './ForumComment';
import { useAuth } from '../../../hooks/auth';
import Restricted from '../../../components/Restricted';

import { Container, Comments } from './styles';

interface DataType {
  id: string;
  comment: string;
  parent?: FormData;
  user: { id: string; name: string; avatar: string };
}

const Forum: React.FC = () => {
  const { selectedClassroom, user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);

  const load = useCallback(
    async (page: number) => {
      try {
        const {
          data: { items, pages },
        } = await api.get(
          `/forums?page=${page}${
            selectedClassroom ? `&classroom_id=${selectedClassroom}` : ``
          }`,
        );

        setHasMore(page < pages);
        setData((oldData: any) => mergeArrayNoRepeat(oldData, items));
        setLoading(false);
      } catch ({ response }) {
        setErrorMessage(
          response?.data?.message ||
            'Não foi possível carregar os comentários!',
        );
      }
    },
    [selectedClassroom],
  );

  // useEffect(() => {
  //   load(1);
  // }, [load]);

  const handleAddNewComment = useCallback(comment => {
    setData(oldData => [comment, ...oldData]);
  }, []);

  if (!user?.classroom?.full_access) {
    return <Restricted />;
  }

  return (
    <Container>
      <InfiniteScroll
        pageStart={0}
        loadMore={load}
        hasMore={hasMore}
        loader={<InfinityLoading key="loading" />}
        // endMessage={<b>Yay! You have seen it all</b>}
      >
        <ForumForm onCreateNew={handleAddNewComment} />

        <Comments>
          {!loading && data.length === 0 && (
            <p>Nenhum comentário. Seja o primeiro!</p>
          )}
          {data.map((item: any) => (
            <ForumComment
              key={item.id}
              item={item}
              onCreateNew={handleAddNewComment}
            />
          ))}
        </Comments>

        {errorMessage && <PageError message={errorMessage} />}
      </InfiniteScroll>
    </Container>
  );
};

export default Forum;
