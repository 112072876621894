import styled from 'styled-components';

export const Container = styled.div`
  padding: 4px;

  border: 1px solid #ccc;
  margin: 2px 2px 10px 2px;
`;

export const Columns = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  padding: 4px;
`;

export const AddButton = styled.button`
  border: 0;
  padding: 0 20px;
  background-color: #666;
  color: #fff;
  font-family: 'sofia_proregular', serif;
  font-size: 0.8em;

  display: flex;
  flex: 1;
  height: 30px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const SearchBar = styled.div`
  display: flex;
  select {
    border: 1px solid #ccc;
    padding: 4px 8px;
    font-family: 'sofia_proregular', serif;
    font-size: 0.8em;
    border-right: 0;
  }
  input {
    border: 0;
    border: 1px solid #ccc;
    padding: 4px 8px;
    font-family: 'sofia_proregular', serif;
    font-size: 0.8em;
    border-right: 0;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 4px 8px;
    border-left: 0;
    height: 30px;
  }
`;

export const SearchResults = styled.div`
  margin: 10px;
`;

export const SearchResult = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 6px;
  border-bottom: 1px solid #ccc;
  /* display: flex;
  padding: 4px 10px;
  background-color: #ccc;
  border-radius: 10px;
  margin: 5px;
  font-size: 0.8em;
  align-items: center;
  justify-content: center; */

  button {
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
`;
