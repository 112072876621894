import styled from 'styled-components';

export const Container = styled.div``;

export const Images = {
  List: styled.div`
    display: flex;
    /* flex-direction: row; */
    /* justify-content: flex-start; */
    border: 1px solid #ccc;
    /* overflow-x: scroll; */
    /* flex-wrap: wrap;
    flex-direction: row; */
    /* width: 100%; */
    /* outline: 1px solid red; */
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    /* overflow-x: scroll; */
    flex-wrap: wrap;
  `,
  Item: styled.div`
    padding: 4px;
    /* position: relative; */

    button {
      display: block;
      width: 100px;
      height: 80px;
      margin: 0 !important;
      padding: 0;
      overflow: hidden;
      border: 0;
      &:hover {
        opacity: 0.5;
      }
    }

    button.image-button {
      position: relative;
      width: 100px;
      height: 80px;
      /* position: relative; */
      top: 0;
      left: 0;

      /* margin: 4px; */
      img {
        width: 100px;
        height: 80px;
        object-fit: cover;
        margin: 0;
        padding: 0;
      }

      span.icon-close {
        position: absolute;
        top: 20px;
        left: 30px;
        opacity: 0;
      }

      &:hover span.icon-close {
        opacity: 1;
      }
    }

    button.add-new {
      display: flex;
      align-items: center;
      justify-content: center;
      /* position: absolute; */
      /* width: 100%;
      height: 100%; */
      /* top: 0;
      left: 0; */
      margin: 0;
      padding: 0;
      border: 1px solid #000;
    }
  `,
};

export const ButtonComment = styled.button`
  display: block;
  width: 100%;
  padding: 14px;
  color: #fefaf9;
  background-color: #17e29a;
  font-family: 'sofia_proregular', serif;
  font-size: 1em;
  border: 0;
  border-radius: 8px;
`;

export const FormArea = styled.div`
  h2 {
    font-family: 'sofia_proregular', serif;
    font-size: 1.5em;
    color: #959595;
  }
  textarea {
    width: 100%;
    border: 0;
    height: 75px;

    padding: 10px;

    margin-top: 20px;
    font-family: 'sofia_proregular', serif;

    -webkit-box-shadow: -1px 5px 6px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -1px 5px 6px 1px rgba(0, 0, 0, 0.16);
    box-shadow: -1px 5px 6px 1px rgba(0, 0, 0, 0.16);
  }

  button {
    margin-top: 20px;
  }

  span.error-message {
    color: #d63e3e;
    font-family: 'sofia_proregular', serif;
    font-size: 1em;
    margin-top: 10px;
  }

  div.over {
    color: #d63e3e;
  }
`;

export const ButtonSubmit = styled.button`
  display: block;
  width: 100%;

  padding: 10px;
  color: #fefaf9;
  background-color: #17e29a;
  font-family: 'sofia_proregular', serif;
  font-size: 1em;
  border: 0;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 288px;
  }
`;
