import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import SignUpPublic from '../pages/SignUpPublic';
import SignUpPublicTrial from '../pages/SignUpPublicTrial';
import RecoveryPasswordRequest from '../pages/RecoveryPasswordRequest';
import RecoveryPassword from '../pages/RecoveryPassword';
import Dashboard from '../pages/Dashboard';
import Admin from '../pages/Admin';
import { useAuth } from '../hooks/auth';
import PageLoading from '../components/PageLoading';

import { useAnalytics } from '../hooks/analytics';

const Routes: React.FC = () => {
  const { loaded } = useAuth();
  const { pathname, search } = useLocation();
  const { pageview } = useAnalytics();

  useEffect(() => {
    const track = `${pathname}${search}`;
    pageview(track);
  }, [pageview, pathname, search]);

  if (!loaded) {
    return <PageLoading />;
  }

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route
        path="/recuperar-senha"
        exact
        component={RecoveryPasswordRequest}
      />
      <Route path="/nova-senha/:token" exact component={RecoveryPassword} />
      <Route path="/signup" exact component={SignUpPublic} />
      <Route path="/signup/trial" exact component={SignUpPublicTrial} />
      <Route path="/signup/:register_id" exact component={SignUp} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/admin" component={Admin} isPrivate />
    </Switch>
  );
};

export default Routes;
