import styled from 'styled-components';

export const FormQuestion = styled.div`
  display: flex;
  align-items: center;

  input {
    flex: 1;
    padding: 10px;
  }

  button {
    padding: 10px;
    border: 0;
  }
`;

export const Questions = styled.div``;

export const Question = styled.div`
  display: flex;

  align-items: center;

  border: 1px solid #ccc;

  span {
    padding: 10px;
    width: 50px;
  }

  p {
    padding: 4px;
    flex: 1;
    height: 30px;
  }

  & > div {
    display: flex;
    height: 30px;
    button {
      display: flex;
      flex: 1;
      border: 0;
      padding: 0 10px;
    }
  }
`;
