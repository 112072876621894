import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .description {
    margin-top: 20px;
    text-align: center;
  }
`;

export const FormArea = styled.div`
  width: 288px;

  & > img {
    display: block;
    margin: 0 auto;
  }
`;

export const Form = styled.form`
  padding-top: 30px;

  & > div > span {
    display: block;
    color: #9596fb;
  }

  & > div > input {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #9596fb;
    height: 40px;
    padding: 10px;
  }
`;
