import React from 'react';
import PageError from '../../../components/PageError';

// import { Container } from './styles';

const Quizes: React.FC = () => {
  return <PageError message="Não foi possível carregar as informações!" />;
};

export default Quizes;
