import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import { Questions, Question, FormQuestion } from './styles';

import {
  Form,
  Input,
  Button,
  Select,
  FormRefInterface,
  getValidationErrors,
} from '../../../../components/Form';

interface SignFormData {
  name: string;
  classroom_id: string;
}

interface FormProps {
  onFormSuccess?: any;
  itemId?: string | undefined | null;
}

const FormUser: React.FC<FormProps> = ({
  onFormSuccess,
  itemId,
}: FormProps) => {
  const formRef = useRef<FormRefInterface>(null);
  const { addToast } = useToast();

  const [initialData, setInitialData] = useState({});
  const [classrooms, setClassrooms] = useState([]);

  const [questions, setQuestions] = useState<any[]>([]);

  const [newQuestion, setNewQuestion] = useState('');

  const loadData = useCallback(async () => {
    try {
      const classroomResult = await api.get(`/classrooms`);
      setClassrooms(classroomResult.data);

      if (itemId === 'new') {
        return;
      }

      const { data } = await api.get(`/users/${itemId}`);

      setInitialData(data);
    } catch ({ response }) {
      addToast({
        type: 'error',
        title: 'Erro',
        description:
          response.data.message || 'Não foi possível realizar a requisição!',
      });
    }
  }, [addToast, itemId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: SignFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Título é obrigatório!'),
          classroom_id: Yup.string().required('Turma é obrigatório!'),
        });

        // console.log('aaa');

        await schema.validate(data, {
          abortEarly: false,
        });

        if (questions.length < 2) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Você deve adicionar no mínimo duas alternativas!',
          });
          return;
        }

        const questionsData = questions.map((q: any) => q.value);

        const result =
          itemId === 'new'
            ? await api.post(`/adm/quizzes`, {
                ...data,
                questions: questionsData,
              })
            : await api.put(`/adm/quizzes/${itemId}`, {
                ...data,
                questions: questionsData,
              });

        if (onFormSuccess) {
          onFormSuccess(result.data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data?.message ||
            'Não foi possível enviar a requisição!',
        });
      }
    },
    [addToast, itemId, onFormSuccess, questions],
  );

  const handleAddQuestion = useCallback(() => {
    setQuestions(state => [
      ...state,
      {
        id: uuid(),
        value: newQuestion,
      },
    ]);
    setNewQuestion('');
  }, [newQuestion]);

  const handleDeleteQuestion = useCallback((id: string) => {
    setQuestions((state: any) => {
      return state.filter((q: any) => q.id !== id);
    });
  }, []);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialData={initialData}
    >
      {/* <Image type="text" name="avatar" label="Foto" /> */}

      <Input type="text" name="title" label="Título" />

      <Select name="classroom_id" label="Turma">
        <option value="">----</option>
        {classrooms.map((classroom: any) => (
          <option key={classroom.id} value={classroom.id}>
            {classroom.name}
          </option>
        ))}
      </Select>

      <Questions>
        <h2>Alternativas:</h2>
        {questions.map((question, index: number) => (
          <Question key={`${question.id}`}>
            <span>{index + 1}</span>
            <p>{question.value}</p>
            <div>
              <button
                type="button"
                onClick={() => handleDeleteQuestion(question.id)}
              >
                remover
              </button>
            </div>
          </Question>
        ))}
      </Questions>

      <FormQuestion>
        <input
          type="text"
          placeholder="Nova Alternativa!"
          value={newQuestion}
          onChange={e => setNewQuestion(e.target.value)}
        />
        <button type="button" onClick={handleAddQuestion}>
          Adicionar
        </button>
      </FormQuestion>

      <Button type="submit">Salvar</Button>
    </Form>
  );
};

export default FormUser;
