import React, { useState, useEffect, useCallback } from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import FormClassroom from './FormLive';
import api from '../../../services/api';
import { Alert, Confirm } from '../../../utils/alerts';

import ResponsiveImage from '../../../components/ResponsiveImage';
import DateString from '../../../components/DateString';

import {
  Title,
  Table,
  ActionButton,
  AddButton,
} from '../../../components/AdminComponents';

import { Container } from './styles';
import Modal from '../../../components/Modal';

const initData: any[] = [];

const Classrooms: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [data, setData] = useState(initData);

  const loadData = useCallback(async () => {
    const result = await api.get('/live-classes');

    setData(d => [...d, ...result.data]);
    setLoaded(true);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAddItem = useCallback(async id => {
    setModalId(id);
  }, []);

  const handleDeleteItem = useCallback(async id => {
    const ok = await Confirm('Tem certeza que deseja remover este item?');
    if (ok) {
      await api.delete(`/live-classes/${id}`);
      setData(d => d.filter((dd: any) => dd.id !== id));
      Alert('Item deletado com sucesso!');
    }
  }, []);

  const onFormSuccess = useCallback(item => {
    setModalId(null);

    setData(d => {
      const index = d.findIndex(i => i.id === item.id);

      if (index !== -1) {
        return d.map(i => (i.id === item.id ? item : i));
      }

      return [item, ...d];
    });
  }, []);

  return (
    <Container>
      <Title>Lives</Title>
      <AddButton
        onClick={() => {
          handleAddItem('new');
        }}
      >
        Adicionar
      </AddButton>

      {modalId && (
        <Modal
          setModalId={setModalId}
          title={modalId === 'new' ? 'Nova Live' : 'Editar Live'}
        >
          <FormClassroom onFormSuccess={onFormSuccess} itemId={modalId} />
        </Modal>
      )}

      {!loaded && <div>Carregando!</div>}

      {loaded && (
        <Table>
          <table>
            <thead>
              <tr>
                <th>Capa</th>
                <th>Data</th>
                <th>Turma</th>
                <th>Nome</th>
                <th>_</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id}>
                  <td className="images">
                    <ResponsiveImage src={item.thumbnail} proportion="16:9" />
                  </td>
                  <td>
                    <DateString>{item.date}</DateString>
                  </td>
                  <td>{item.classroom?.name}</td>
                  <td>{item.name}</td>

                  <td className="actions">
                    <ActionButton
                      onClick={() => {
                        handleAddItem(item.id);
                      }}
                    >
                      <FiEdit color="#000" size={18} />
                    </ActionButton>

                    <ActionButton onClick={() => handleDeleteItem(item.id)}>
                      <FiXCircle color="#000" size={18} />
                    </ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      )}
    </Container>
  );
};

export default Classrooms;
