import { useFormikContext } from 'formik';
import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

interface Props {
  label: string;
  onClick?: () => void;
}

const SubmitButton: React.FC<Props> = ({ label, onClick }: Props) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Container type="button" disabled={isSubmitting} onClick={onClick}>
      {label}
    </Container>
  );
};

export default SubmitButton;
