import React, { useCallback } from 'react';

import { Container } from './styles';

import defaultUserImage from '../../assets/default-user-image.png';
import defaultImage from '../../assets/default-image.png';
import appConfig from '../../configs/app';

interface Props {
  src: string;
  local?: boolean;
  proportion?: '16:9' | '9:16' | '1:1';
  defaultType?: 'user' | 'image';
}

const ResponsiveImage: React.FC<Props> = ({
  src,
  local = true,
  proportion = '16:9',
  defaultType = 'image',
}: Props) => {
  const getSrc = useCallback(
    currentSrc => {
      const dImage = defaultType === 'image' ? defaultImage : defaultUserImage;

      if (!currentSrc) {
        return dImage;
      }
      if (
        !local ||
        currentSrc.substr(0, 7) === 'http://' ||
        currentSrc.substr(0, 8) === 'https://'
      ) {
        return currentSrc;
      }

      return currentSrc
        ? `${appConfig.imageUrl}/${encodeURI(currentSrc)}`
        : dImage;
    },
    [defaultType, local],
  );

  const handleOnErrorImage = useCallback(
    (e: any) => {
      e.currentTarget.error = null;
      e.currentTarget.src =
        defaultType === 'image' ? defaultImage : defaultUserImage;
    },
    [defaultType],
  );

  return (
    <Container proportion={proportion}>
      <div
        className="responsive-image-wrap"
        style={{ backgroundImage: `url(${getSrc(src)})` }}
      >
        {/* <img src={getSrc(src)} alt={src} onError={handleOnErrorImage} /> */}
      </div>
    </Container>
  );
};

export default ResponsiveImage;
