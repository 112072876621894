import React, { useState, useCallback, useEffect } from 'react';
import ReactLoading from 'react-loading';
import api from '../../../services/api';
import { Container, Button } from './styles';
import PageLoading from '../../../components/PageLoading';
import PageError from '../../../components/PageError';
import { Alert } from '../../../utils/alerts';

const Contact: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [loadingForm, setLoadingForm] = useState(false);
  const [errorForm, setErrorForm] = useState('');

  const [data, setData] = useState<any>({
    message: '',
    classroom_id: '',
  });

  const [classrooms, setClassrooms] = useState([]);

  const load = useCallback(async () => {
    try {
      const res = await api.get('/classrooms');
      setClassrooms(res.data);
      if (res.data.length > 0) {
        setData({
          message: '',
          classroom_id: res.data[0].id,
        });
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'Houve um erro ao carregar o conteúdo!',
      );
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleChange = useCallback((event: any) => {
    const { value, name } = event.target;
    setData((oldData: any) => {
      const newData: any = { ...oldData };
      newData[name] = value;
      return newData;
    });
  }, []);

  const handleForm = useCallback(
    async event => {
      if (!data.message || data.message.length < 6) {
        Alert('Favor preencher a mensagem corretamente!');
        return;
      }

      setLoadingForm(true);

      try {
        await api.post('/notifications', {
          message: data.message,
          classroom_id: data.classroom_id,
        });

        setData((d: any) => {
          return { ...d, message: '' };
        });
        setLoadingForm(false);

        Alert('A Notificação foi enviada com sucesso!');
      } catch (error) {
        setErrorForm(
          error.response?.data?.message ||
            'Houve um erro ao carregar o conteúdo! Tente novamente mais tarde!',
        );
        setLoadingForm(false);
      }
    },
    [data],
  );

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      <header>
        <h1>Enviar Notificação</h1>
      </header>
      <div>
        <form action="">
          <div>
            <span>Turma</span>

            <select
              name="classroom_id"
              value={data.classroom_id}
              onChange={handleChange}
            >
              {classrooms.map((classroom: any) => (
                <option key={classroom.id} value={classroom.id}>
                  {classroom.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <span>Mensagem</span>
            <textarea
              name="message"
              value={data.message}
              onChange={handleChange}
            />
          </div>

          <div>
            {loadingForm ? (
              <Button type="button">
                <ReactLoading
                  type="spin"
                  color="#16B77E"
                  height={25}
                  width={25}
                />
              </Button>
            ) : (
              <Button type="button" onClick={handleForm}>
                Enviar
              </Button>
            )}

            {errorForm && <p className="error-message">{errorForm}</p>}
          </div>
        </form>
      </div>
    </Container>
  );
};

export default Contact;
